import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormPasswordFieldOutlined } from 'framework/forms/FormPasswordFieldOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IUserModel, usersCommand_new } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const schema = yup.object<IUserModel>({
    name: yup.string().required(),
    password: yup.string().required(),
});

const EmptyValues: IUserModel = {
    name: '',
    password: '',
}

interface IProps extends DialogProps {
    cancel: () => void;
    confirm: (id: string) => void;
}

export const UserForm = ({ cancel, confirm, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(usersCommand_new);
    const notify = useSnackbarNotify();

    const handleSubmit = async (values: IUserModel, helpers: FormikHelpers<IUserModel>) => {
        const r = await submit(values);
        if (handleFormResponse(r, helpers)) {
            notify('Gebruiker aangemaakt');
            confirm(r.result.objectId);
        }
    }

    return (
        <Formik<IUserModel>
            validateOnMount
            initialValues={EmptyValues}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            <Form className="stretch-ver df-col jc-sb" style={{ minWidth: '400px' }}>
                <Dialog
                    {...rest}>
                    <DialogTitle>Voeg gebruiker toe</DialogTitle>
                    <DialogContent
                        dividers
                        className="df-col"
                        style={{ minWidth: 400 }}>
                        <FormTextFieldOutlined<IUserModel>
                            label="Gebruiker"
                            name="name" />
                        <FormPasswordFieldOutlined<IUserModel>
                            label="Wachtwoord"
                            name="password" />
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        submitText={`Voeg toe`}
                        cancel={cancel}
                        isSubmitting={isSubmitting} />
                </Dialog>
            </Form>
        </Formik>
    );
}