import { Button, Typography } from '@material-ui/core';
import { clearCacheAndReloadApplication } from 'framework/utils/clearCacheAndReloadApplication';
import React from 'react';
import { Logo } from './logo/Logo';

export const MustUpdateWall = () => {
    return (
        <div className="df-col-ac stretch-ver jc-c">
            <Logo width={400} style={{ marginBottom: '20px' }} />
            <Typography variant="h5" style={{ marginBottom: '30px' }}>{`Nieuw versie beschikbaar`}</Typography>
            <Button variant="contained" color="primary" onClick={clearCacheAndReloadApplication}>{`Update`}</Button>
        </div>
    );
}

