import { OutlinedInput } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useStateBoolean } from '../hooks/useStateBool';
import { nameOf } from '../utils/nameOf';
import { ClearEndAdornment } from './common/ClearEndAdornment';
import { FormControlX } from './common/FormControlX';
import { IFormProps } from './common/IFormProps';

export const FormIntegerFieldOutlined = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
  const [field, meta, helpers] = useField<string>(nameOf(props.name));
  const [isHovered, enter, leave] = useStateBoolean(false);

  return (
    <FormControlX
      meta={meta}
      enter={enter}
      leave={leave}
      {...props}>
      <OutlinedInput
        {...field}
        type="text"
        inputProps={{ pattern: '[0-9]*' }}
        endAdornment={<ClearEndAdornment isHovered={isHovered} field={field} helpers={helpers} disabled={props.disabled ?? false} defaultValue={''} />}
      />
    </FormControlX>
  );
}