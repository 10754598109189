import React from 'react';
import { IUserInfo } from 'gen/ApiClient';

interface AuthenticationProps {
    isAuth: boolean,
    user: IUserInfo | undefined,
    login: (user: IUserInfo) => void,
    logout: () => void,
    onRedirect: () => void,
}

export const AuthContext = React.createContext<AuthenticationProps>(undefined as any);
