
import { Grid, GridProps } from '@material-ui/core';
import React from 'react';

interface IProps extends GridProps {
    withoutTopBottomMargin?: boolean;
}

export const FormGridContainer = ({ withoutTopBottomMargin, style, ...props}: IProps) => {
    return (
        <Grid
            container
            direction="row"
            justify='flex-start'
            alignItems='flex-start'
            spacing={5}
            style={{ marginTop: withoutTopBottomMargin ? 0 : -20, marginBottom: withoutTopBottomMargin ? 0 : -20, ...style }}
            // container
            // direction="row"
            // justifyContent="flex-start"
            // alignItems="flex-start"
            // columnSpacing={5}
            // style={{ margin: 0 }}
            {...props} />
    );
}