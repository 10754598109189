import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useField } from 'formik';
import React, { useState } from 'react';
import { nameOf } from '../utils/nameOf';
import { FormControlX } from './common/FormControlX';
import { IFormProps } from './common/IFormProps';

export const FormPasswordFieldOutlined = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
    const [field, meta] = useField<string>(nameOf(props.name));
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    return (
        <FormControlX
            meta={meta}
            {...props}>
            <OutlinedInput
                {...field}
                type={isShowPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setIsShowPassword(!isShowPassword)}
                            edge="end">
                            {isShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControlX>
    );
}