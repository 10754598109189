import { Button, IconButton } from '@material-ui/core';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { theme } from '../../theme';
import { FilterIcon } from '../components/icons/FilterIcon';
import { FilterOffIcon } from '../components/icons/FilterOffIcon';

interface IProps {
    onClear: VoidFunction;
    isFiltered: boolean;
    paddingLeft?: number;
    label?: string;
    hideLabel?: boolean;
    children: any;
    onMore?: VoidFunction;
    disableWrap?: boolean;
    style?: React.CSSProperties;
}

export const FilterBar = ({ children, onClear, isFiltered, paddingLeft = theme.spacing(2), label, hideLabel = false, onMore, disableWrap = false, style }: IProps) => {
    const strings = useLocalization();

    return (
        <div className="df-row-ac" style={{ paddingLeft: paddingLeft, paddingRight: paddingLeft, ...style }}>
            {hideLabel === false && <div style={{ fontWeight: 500, textTransform: 'uppercase', fontSize: '13px', marginRight: isFiltered ? 0 : theme.spacing(2) }}>{label ? label : strings.filter}</div>}
            {isFiltered &&
                <TooltipWithSpan title={strings.clearFilter}>
                    <IconButton color="secondary" onClick={() => onClear()}>
                        <FilterOffIcon />
                    </IconButton>
                </TooltipWithSpan>
            }
            <div className={`df-row fg1${disableWrap ? '' : ' fw'}`} style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
                {children}
            </div>
            {onMore && <Button startIcon={<FilterIcon />} size="small" color="primary" onClick={onMore}>Meer</Button>}
        </div>
    );
}