import { CacheProvider as FrameworkCacheProvider } from "framework/context/CacheProvider";
import { dashboard_concurrencyToken, dashboard_stats, IDashboardStats } from "gen/ApiClient";
import React from "react";
import { CacheContext } from "./CacheContext";

export const CacheProvider = ({ children }: any) => {
    return (
        <FrameworkCacheProvider<IDashboardStats>
            concurrencyF={dashboard_concurrencyToken}
            loadF={dashboard_stats}
            context={CacheContext}>
            {children}
        </FrameworkCacheProvider>

    );
}
