import { Button, Dialog, DialogContent, DialogProps, DialogTitle, Divider, Paper, Typography } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { Searchbar } from 'framework/components/Searchbar';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IAvailableInventoryItemsQueryParams, IInventoryItemForReservationDto, InventoryItemReservationStatus, inventoryItemsQuery_queryAvailable, InventoryType, IReservation, IReservationLine, reservationsCommand_linkInventoryItem } from 'gen/ApiClient';
import React, { useState } from 'react';
import { calcInventoryItemDetailRoute } from 'routes';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { InventoryItemReservationStatusRecord } from 'shared/records/InventoryItemReservationStatusRecord';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { DefaultAvailableInventoryItemsQueryParams } from './DefaultAvailableInventoryItemsQueryParams';
import { Filter } from './Filter';

interface IProps extends DialogProps {
    reservation: IReservation,
    line: IReservationLine,
    onShowDetailsInventoryItem: (id: string) => void;
    confirm: () => void;
    cancel: () => void;
}

export const LinkInventoryItemForm = ({ reservation, line, confirm, cancel, ...rest }: IProps) => {
    const [params, setParams] = useState<IAvailableInventoryItemsQueryParams>(DefaultAvailableInventoryItemsQueryParams);
    const [queryResult] = useApiEffect(inventoryItemsQuery_queryAvailable, reservation.id, line.id, params);
    const [selectedId, setSelectedId] = useState<string>();
    const [submit, isSubmitting] = useFormSubmit(reservationsCommand_linkInventoryItem);
    const notify = useSnackbarNotify();
    const openInNewTab = useOpenInNewTab();

    const onLink = async (id: string) => {
        setSelectedId(id);
        const r = await submit(reservation.id, line.id, id);
        handleValidateResponseBase(r, e => notify(e, 'error'), () => confirm());
    }

    const onViewDetails = (id: string) => {
        openInNewTab(calcInventoryItemDetailRoute(id), false);
    }

    const onFilter = (params: IAvailableInventoryItemsQueryParams) => {
        setParams(onResetToPage1(params));
    }

    const headers = [
        createSortableHeader<IInventoryItemForReservationDto>('Naam', 'brandCommaModel', t => t.brandCommaModel),
        createSortableHeader<IInventoryItemForReservationDto>('Stocklocatie', 'stockLocation', t => t.stockLocation),
        createSortableHeader<IInventoryItemForReservationDto>('Maat', 'size', t => t.size.toString()),
        createSortableHeader<IInventoryItemForReservationDto>('Lengte (in cm)', 'lengthInCm', t => t.lengthInCm.toString()),
        createHeader<IInventoryItemForReservationDto>('Status', t => InventoryItemReservationStatusRecord[t.reservationStatus as InventoryItemReservationStatus]),
        createEmptyHeader<IInventoryItemForReservationDto>('actions', t =>
            <div className="df-row-ac jc-e">
                <PrimaryContainedButton size="small" isLoading={isSubmitting && selectedId === t.id} disabled={t.canSelect === false} onClick={() => onLink(t.id)}>{`Selecteer`}</PrimaryContainedButton>
                <Button size="small" variant="outlined" onClick={() => onViewDetails(t.id)} style={{ marginLeft: 8 }}>{`Details`}</Button>
            </div>),
    ]


    return (
        <Dialog
            {...rest}
            maxWidth="xl">
            <CloseButtonOnAbsolutePosition onClick={cancel} />
            <DialogTitle>{` Selecteer ${InventoryTypeRecord[line.type as InventoryType]} voor ${reservation.firstNameLastName}`}</DialogTitle>
            <DialogContent
                dividers
                className="df-col">
                <Typography variant="caption" style={{ marginBottom: 8 }}>{`Extra info: Lengte van ski = lengte van de persoon - 15cm`}</Typography>
                <Paper variant='outlined'>
                    <Searchbar
                        variant='embedded'
                        value={params.searchString}
                        onSearch={val => onFilter({ ...params, searchString: val})}
                        placeholder='Zoeken...' />
                    <Divider />
                    <div className='df-row-ac jc-sb'>
                        <Filter
                            params={params}
                            onFilter={pars => onFilter(pars)}
                            type={line.type!} />
                        <FormControlLabelSwitch
                            isChecked={params.isShowAll}
                            toggle={() => onFilter({ ...params, isShowAll: !params.isShowAll })}
                            label={`Bekijk alles`} />
                    </div>
                    <Divider />
                    {queryResult &&
                        <PageableTable<IInventoryItemForReservationDto>
                            queryResult={queryResult}
                            headers={headers}
                            getKey={t => t.id}
                            pageParams={params.pageParams}
                            sortParams={params.sortParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })} />}
                </Paper>
            </DialogContent>
        </Dialog>
    );
}