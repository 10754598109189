import React from 'react';
import { QueryProvider } from './QueryProvider';
import { HolidayPeriodsCacheProvider } from './HolidayPeriodsCacheProvider';

export const HolidayPeriodsProviders = ({ children }: any) => {
    return (
        <QueryProvider>
            <HolidayPeriodsCacheProvider>
                {children}
            </HolidayPeriodsCacheProvider>
        </QueryProvider>
    );
}