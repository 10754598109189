import React from 'react';
import { QueryProvider } from './QueryProvider';
import { CacheProvider } from './CacheProvider';

export const InventoryItemsProviders = ({ children }: any) => {
    return (
        <QueryProvider>
            <CacheProvider>
                {children}
            </CacheProvider>
        </QueryProvider>
    );
}