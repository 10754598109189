import { Card, CardHeader, Divider } from "@material-ui/core";
import { useQueryContext } from "framework/hooks/useQueryContext";
import { createEmptyHeader } from "framework/table/createEmptyHeader";
import { createSortableHeader } from "framework/table/createSortableHeader";
import { PageableTable } from "framework/table/PageableTable";
import { onResetToPage1 } from "framework/types/onResetToPage1";
import { ICustomer } from "gen/ApiClient";
import React from "react";
import { useHistory } from "react-router-dom";
import { calcCustomerDetailRoute } from "routes";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";
import { PageWithSearch } from "../PageWithSearch";
import { QueryContext } from "./context/QueryContext";

export const CustomersList = () => {
  const { params, setParams, queryResult, reload } =
    useQueryContext(QueryContext);
  const { push } = useHistory();

  const headers = [
    createSortableHeader<ICustomer>(
      "Voornaam",
      "firstName",
      (t) => t.firstName
    ),
    createSortableHeader<ICustomer>(
      "Achternaam",
      "lastName",
      (t) => t.lastName
    ),
    createSortableHeader<ICustomer>("Email", "email", (t) => t.email),
    createSortableHeader<ICustomer>("Adres", "address", (t) => t.address),
    createSortableHeader<ICustomer>("Aantal res.", "countReservations", (t) => (
      <div>{t.countReservations}</div>
    )),
    createEmptyHeader<ICustomer>('action', t =>
      <PrimaryContainedButton size="small" onClick={() => push(calcCustomerDetailRoute(t.id!))}>Details</PrimaryContainedButton>, 'right'
    ),
  ];

  if (queryResult === undefined) {
    return <div></div>;
  }

  return (
    <PageWithSearch
      onRefresh={reload}
      searchString={params.searchString}
      onSearch={(val) =>
        setParams(onResetToPage1({ ...params, searchString: val }))
      }
      placeholder={`Zoek op voornaam/achternaam/email/adres/telefoon...`}
    >
      <Card>
        <CardHeader title="Klanten/Personen" />
        <Divider />
        <PageableTable<ICustomer>
          queryResult={queryResult}
          headers={headers}
          pageParams={params.pageParams}
          sortParams={params.sortParams}
          getKey={(t) => t.id!}
          setParams={(sp, pp) =>
            setParams({ ...params, sortParams: sp, pageParams: pp })
          }
        />
      </Card>
    </PageWithSearch>
  );
};
