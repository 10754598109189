import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import { CancelSubmitFormDialogActions } from "framework/forms/CancelSubmitFormDialogActions";
import { FormDatePickerOutlined } from "framework/forms/FormDatePickerOutlined";
import { handleFormResponse } from "framework/forms/utils/handleFormResponse";
import { useFormSubmit } from "framework/hooks/useFormSubmit";
import {
  IReservation,
  IReservationPeriodModel,
  reservationsCommand_updatePeriod,
} from "gen/ApiClient";
import React from "react";
import * as yup from "yup";

const schema = yup.object<IReservationPeriodModel>({
  fromDate: yup.date().required("Selecteer VAN datum"),
  toDate: yup
    .date()
    .when("from", (from: any, schema: any) =>
      from !== undefined
        ? yup.date().min(from, "TOT datum kan niet vóór VAN datum")
        : undefined
    )
    .required("Selecteer TOT datum"),
  collectionDate: yup.date().required(),
  returnBeforeDate: yup.date().required(),
});

const toModel = (item: IReservation): IReservationPeriodModel => {
  return {
    fromDate: item.fromDate,
    toDate: item.toDate,
    collectionDate: item.collectionDate,
    returnBeforeDate: item.returnBeforeDate,
  };
};

interface IProps extends DialogProps {
  item: IReservation;
  cancel: () => void;
  confirm: () => void;
}

export const ReservationPeriodForm = ({
  item,
  cancel,
  confirm,
  ...rest
}: IProps) => {
  const [submit, isSubmitting] = useFormSubmit(
    reservationsCommand_updatePeriod
  );

  const handleSubmit = async (
    values: IReservationPeriodModel,
    helpers: FormikHelpers<IReservationPeriodModel>
  ) => {
    const r = await submit(item.id, values);
    if (handleFormResponse(r, helpers)) {
      confirm();
    }
  };

  return (
    <Formik<IReservationPeriodModel>
      initialValues={toModel(item)}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <Form className="stretch-ver df-col jc-sb">
        <Dialog {...rest}>
          <DialogTitle>Huurperiode aanpassen</DialogTitle>
          <DialogContent dividers className="df-col" style={{ minWidth: 450 }}>
            <FormDatePickerOutlined<IReservationPeriodModel>
              label="OPHALEN"
              name="collectionDate"
              required
            />
            <FormDatePickerOutlined<IReservationPeriodModel>
              label="VAN"
              disablePast={true}
              name="fromDate"
              required
            />
            <FormDatePickerOutlined<IReservationPeriodModel>
              label="TOT"
              disablePast={true}
              name="toDate"
              required
            />
            <FormDatePickerOutlined<IReservationPeriodModel>
              label="TERUGBRENGEN VOOR"
              name="returnBeforeDate"
              required
            />
          </DialogContent>
          <CancelSubmitFormDialogActions
            cancel={cancel}
            submitText="Pas aan"
            isSubmitting={isSubmitting}
          />
        </Dialog>
      </Form>
    </Formik>
  );
};
