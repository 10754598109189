import React from 'react';
import { ProgressLine } from './ProgressLine';
import { ProgressStep } from './ProgressStep';

interface IProps {
    step: number,
}

export const ProgressBar = ({ step }: IProps) => {
    return (
        <div className="df-col pos-rel" style={{ marginBottom: 16 }}>
            <div className="df-row jc-sb pos-rel">
                <ProgressStep text="Periode" isFirst actualStep={step} forStep={1} />
                <ProgressStep text="Persoongegevens" actualStep={step} forStep={2} />
                <ProgressStep text="Materiaal" actualStep={step} forStep={3} />
                <ProgressStep text="Overzicht" isLast actualStep={step} forStep={4} />
            </div>
            <div className="df-row jc-sb pos-abs" style={{left: '15px', width: 'calc(100% - 30px)', bottom: '14px'}}>
                <ProgressLine isDone={step > 1}/>
                <ProgressLine isDone={step > 2}/>
                <ProgressLine isDone={step > 3}/>
            </div>
        </div>
    );
};
