import { Card, CardContent, CardHeader, Divider, IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatDate } from 'framework/utils/date/formatDate';
import { inventoryItemsCommand_updateComment, inventoryItemsQuery_single, IReservationDto, IReservationsQueryParams, reservationsQuery_queryForInventoryItem } from 'gen/ApiClient';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TypographyX } from 'shared/TypographyX';
import { formatReservationPeriod } from 'shared/utils/formatReservationPeriod';
import * as routes from '../../../../routes';
import { PageWithoutSearch } from '../../PageWithoutSearch';
import { DefaultReservationsQueryParams } from '../../reservations/context/ReservationsQueryProvider';
import { Filter } from '../../reservations/Filter';
import { MaterialLines } from '../../reservations/MaterialLines';
import { ReservationStatusChip } from '../../reservations/ReservationStatusChip';
import { InventoryItemDetailHeader } from './InventoryItemDetailHeader';

export const InventoryItemDetail = () => {
    const { id } = useParams<IRouteParamsWithId>();
    const [detail, reloadDetail] = useApiEffect(inventoryItemsQuery_single, id);
    const [trigger, hitTrigger] = useTrigger();
    const [params, setParams, queryResult] = useApiEffectWithParams(pars => reservationsQuery_queryForInventoryItem(id, pars), DefaultReservationsQueryParams, trigger);
    const { open, confirm, cancel } = useDialogsContext(hitTrigger);
    const { push } = useHistory();

    useLazyEffect(() => {
        reloadDetail();
    }, [trigger]);

    const headers = [
        createSortableHeader<IReservationDto>('Datum aanmaak', 'created', t => formatDate(t.created)),
        createHeader<IReservationDto>('Status', t => <ReservationStatusChip item={t.item} />),
        createSortableHeader<IReservationDto>('Reservatienummer', 'number', t => t.number),
        createHeader<IReservationDto>('Naam', t => t.item.lastNameCommaFirstName),
        createHeader<IReservationDto>('Periode', t => formatReservationPeriod(t.item.fromDate, t.item.toDate)),
        createHeader<IReservationDto>('Materiaal', t => <MaterialLines lines={t.lines ?? []} />),
        createEmptyHeader<IReservationDto>('actions', t =>
            <div className="df-row-ac jc-e">
                <PrimaryContainedButton size="small" onClick={() => push(routes.calcReservationDetailRoute(t.id))}>Details</PrimaryContainedButton>
            </div>
        )
    ]

    if (detail === undefined || queryResult === undefined) {
        return <div></div>
    }

    const onUpdateComment = () => {
        open(<StringModelForm
            open
            initialValue={detail.comment}
            confirm={confirm}
            cancel={cancel}
            submitFunction={(val) => inventoryItemsCommand_updateComment(detail.id, val)}
            formTitle={`Wijzig opmerking`}
            submitText={'Wijzig'}
            label={'Opmerking'}
            multiline />);
    }

    const onFilter = (pars: IReservationsQueryParams) => {
        setParams(onResetToPage1(pars));
    }

    return (
        <PageWithoutSearch onGoBack={() => push(routes.InventoryItemsRoute)} backText='Terug naar inventaris' onRefresh={hitTrigger}>
            <div className="df-col stretch-ver">
                <InventoryItemDetailHeader
                    detail={detail}
                    reload={hitTrigger}
                    style={{ marginBottom: 30 }} />
                <Card style={{ marginBottom: 30 }}>
                    <CardHeader title="Reservaties" />
                    <Divider />
                    <div className='df-row-ac jc-sb'>
                        <Filter
                            params={params}
                            onFilter={onFilter} />
                        <FormControlLabelSwitch
                            isChecked={params.isAlsoShowCancelled}
                            toggle={() => onFilter({ ...params, isAlsoShowCancelled: !params.isAlsoShowCancelled })}
                            label={`Bekijk ook geannuleerde`} />
                    </div>
                    <Divider />
                    {queryResult.unfilteredCount !== 0 &&
                        <PageableTable<IReservationDto>
                            queryResult={queryResult}
                            headers={headers}
                            getKey={t => t.id!}
                            pageParams={params.pageParams}
                            sortParams={params.sortParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })} />
                    }
                    {queryResult.unfilteredCount === 0 &&
                        <NoRecordsFound withPadding style={{ color: 'red' }}>Geen reservaties gevonden</NoRecordsFound>
                    }
                </Card>
                <div className="df-row jc-sb">
                    <Card className="df-col" style={{ minWidth: 300, }}>
                        <CardHeader
                            title="Opmerkingen"
                            action={
                                <IconButton onClick={onUpdateComment}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            } />
                        <CardContent>
                            {detail.comment ?
                                <TypographyX fontSize={18} multiLine lineHeight={22}>{detail.comment}</TypographyX>
                                :
                                <TypographyX fontSize={16} multiLine lineHeight={22} style={{ fontStyle: 'italic' }}>Geen opmerkingen</TypographyX>}
                        </CardContent>
                    </Card>
                </div>
            </div>
        </PageWithoutSearch>
    );
}

