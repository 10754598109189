import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerFieldOutlined } from 'framework/forms/FormIntegerFieldOutlined';
import { FormNumberFieldOutlined } from 'framework/forms/FormNumberFieldOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IInventoryItem, IInventoryItemUpdateModel, inventoryItemsCommand_update } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';
import { hasLength } from './utils/hasLength';
import { hasSize } from './utils/hasSize';

const schema = yup.object<IInventoryItemUpdateModel>({
    brand: yup.string().required(),
    model: yup.string().required(),
    lengthInCm: yup.number().required(),
    // .when('type', (type: string, schema: any) => type !== undefined && hasLength(type) ? yup.number().required() : undefined),
    size: yup.number().required(),
    // .when('type', (type: string, schema: any) => type !== undefined && hasSize(type) ? yup.number().required() : undefined),
});

interface IProps extends DialogProps {
    item: IInventoryItem;
    cancel: () => void;
    confirm: (id: string) => void;
}

export const InventoryItemUpdateForm = ({ item, cancel, confirm, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_update);

    const handleSubmit = async (values: IInventoryItemUpdateModel, helpers: FormikHelpers<IInventoryItemUpdateModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            confirm(item.id);
        }
    }

    return (
        <Formik<IInventoryItemUpdateModel>
            validateOnMount
            initialValues={{ 
                brand: item.brand,
                model: item.model,
                lengthInCm: item.lengthInCm,    
                size: item.size
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    {...rest}
                    scroll="paper"
                    maxWidth="sm"
                    fullWidth>
                    <DialogTitle>Wijzig artikel</DialogTitle>
                    <DialogContent
                        dividers
                        className="df-col">
                        <FormTextFieldOutlined<IInventoryItemUpdateModel>
                            name="brand"
                            label="Merk"
                            required />
                        <FormTextFieldOutlined<IInventoryItemUpdateModel>
                            name="model"
                            label="Model"
                            required />
                        {hasLength(item.type as any) &&
                            <FormNumberFieldOutlined<IInventoryItemUpdateModel>
                                name="lengthInCm"
                                label="Lengte (in cm)"
                                suffix=' cm'
                                required />}
                        {hasSize(item.type as any) &&
                            <FormIntegerFieldOutlined<IInventoryItemUpdateModel>
                                name="size"
                                label="Maat"
                                required />}
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        cancel={cancel}
                        isSubmitting={isSubmitting}
                        submitText={"Wijzig"} />
                </Dialog>
            </Form>
        </Formik>
    );

}