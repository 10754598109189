import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { formatDate } from 'framework/utils/date/formatDate';
import { Gender, IReservation, ReservationStatus } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TypographyX } from 'shared/TypographyX';
import { HeaderItem } from '../../../../shared/HeaderItem';
import { LengthWeightLevelForm } from '../forms/LengthWeightLevelForm';
import { ReservationPeriodForm } from '../forms/ReservationPeriodForm';
import { ReservationPersonalInformationForm } from '../forms/ReservationPersonalInformationForm';
import { formatReservationPeriod } from 'shared/utils/formatReservationPeriod';
import { ReservationStatusRecord } from 'shared/records/ReservationStatusRecord';
import { GenderRecord } from 'shared/records/GenderRecord';

interface IProps {
	item: IReservation;
	reload: () => void;
	style?: React.CSSProperties;
}

export const ReservationDetailHeader = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const notify = useSnackbarNotify();

	const onEditPI = () => {
		if (item.canEditPersonalInformation === false) {
			notify('Je kan dit enkel aanpassen bij een niet verhuurde reservatie', 'error');
		} else {
			open(
				<ReservationPersonalInformationForm
					open
					confirm={confirm}
					cancel={cancel}
					item={item}
				/>
			);
		}
	};

	const onEditLengthAndWeight = () => {
		if (item.canEditLengthAndWeight === false) {
			notify('Je kan dit enkel aanpassen bij een niet verhuurde reservatie', 'error');
		} else {
			open(
				<LengthWeightLevelForm
					open
					cancel={cancel}
					confirm={confirm}
					item={item}
				/>
			);
		}
	};

	const onEditPeriod = () => {
		if (item.canEditPeriod === false) {
			notify('Je kan dit enkel aanpassen bij een niet verhuurde reservatie.', 'error');
		} else {
			open(
				<ReservationPeriodForm
					open
					confirm={confirm}
					cancel={cancel}
					item={item}
				/>
			);
		}
	};

	return (
		<div
			className='df-row jc-sb'
			style={style}>
			<HeaderItem
				label='status'
				value={ReservationStatusRecord[item.status as ReservationStatus]}
			/>
			<HeaderItem
				label='reservatienummer/datum'
				value={item.number}>
				<TypographyX
					fontSize={18}
					bold
					color='color-grey-dark'>
					{formatDate(item.created)}
				</TypographyX>
			</HeaderItem>
			<HeaderItem
				label='persoon'
				value={item.firstNameLastName}
				onEdit={() => onEditPI()}>
				<div className='df-col'>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'
						toUpper>{`GESLACHT ${GenderRecord[item.gender as Gender]}`}</TypographyX>
					{item.age === item.ageAtCreation ? (
						<TypographyX
							fontSize={18}
							bold
							color='color-grey-dark'
							toUpper>
							{item.age} jaar
						</TypographyX>
					) : (
						<>
							<TypographyX
								fontSize={18}
								bold
								color='color-grey-dark'
								toUpper>
								{item.age} jaar (huidige leeftijd)
							</TypographyX>
							<TypographyX
								fontSize={18}
								bold
								color='color-grey-dark'
								toUpper>
								{item.ageAtCreation} jaar (ten tijde van reservatie)
							</TypographyX>
						</>
					)}
				</div>
			</HeaderItem>
			<HeaderItem
				label='gewicht/lengte/niveau'
				value={`${item.weightInKg}kg - ${item.lengthInCm}cm`}
				onEdit={() => onEditLengthAndWeight()}>
				<>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						{item.level}
					</TypographyX>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						{item.hasSnowboardGroupType && (
							<>
								{item.isSnowboardRightFootInFront === true && <div>{`Rechts vooraan`}</div>}
								{item.isSnowboardRightFootInFront === false && <div>{`Links vooraan`}</div>}
							</>
						)}
					</TypographyX>
				</>
			</HeaderItem>
			<HeaderItem
				label='periode'
				value={formatReservationPeriod(item.fromDate, item.toDate)}
				onEdit={() => onEditPeriod()}>
				<div className='df-col'>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						OPHALEN NA: {formatDate(item.collectionDate)}
					</TypographyX>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						TERUG VOOR: {formatDate(item.returnBeforeDate)}
					</TypographyX>
				</div>
			</HeaderItem>
			<HeaderItem
				label='DIN +/++'
				value={`${item.dinOnePlus}/${item.dinTwoPlus}`}>
				<div className='df-col'>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						Lengte stokken
					</TypographyX>
					<TypographyX
						fontSize={18}
						bold
						color='color-grey-dark'>
						{item.lengthSticksInCm} cm
					</TypographyX>
				</div>
			</HeaderItem>
		</div>
	);
};
