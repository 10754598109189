import { Color } from "@material-ui/lab";
import { RectangularChip } from "framework/components/chips/RectangularChip";
import { IReservation, ReservationStatus } from "gen/ApiClient";
import React from "react";
import { ReservationStatusRecord } from "shared/records/ReservationStatusRecord";

const ColorRecord: Record<ReservationStatus, Color> = {
  CanBePrepared: "info",
  Cancelled: "error",
  InWaitingLine: "info",
  Rented: "info",
  Returned: "info",
  WaitingCollection: "info",
  WaitingOtherReservation: "info",
  InProgress: "info",
};

interface IProps {
  item: IReservation;
  style?: React.CSSProperties;
}

export const ReservationStatusChip = ({ item, style }: IProps) => {
  return (
    <RectangularChip
      style={style}
      label={ReservationStatusRecord[item.status as ReservationStatus]}
      color={ColorRecord[item.status as ReservationStatus]}
    />
  );
};
