import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { PageWithoutSearch } from "app/admin/PageWithoutSearch";
import { AlertDialog } from "framework/dialogs/AlertDialog";
import { StringModelForm } from "framework/forms/StringModelForm";
import { useApiEffect } from "framework/hooks/useApiEffect";
import { useSnackbarNotify } from "framework/hooks/useSnackbarNotify";
import { IRouteParamsWithId } from "framework/router/IRouteParamsWithId";
import { createEmptyHeader } from "framework/table/createEmptyHeader";
import { createHeader } from "framework/table/createHeader";
import { TableWithHeadersAndValues } from "framework/table/TableWithHeadersAndValues";
import { formatDate } from "framework/utils/date/formatDate";
import { formatCurrency } from "framework/utils/formatCurrency";
import {
  Gender,
  IReservationLineWithInventoryItem,
  requestsCommand_resendEmailConfirmation,
  reservationsCommand_addOwnBoot,
  reservationsCommand_cancel,
  reservationsCommand_collect,
  reservationsCommand_return,
  reservationsCommand_setReadyForRental,
  reservationsCommand_updateComment,
  reservationsQuery_single,
  ReservationStatus,
} from "gen/ApiClient";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReservationsRoute } from "routes";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";
import { useDialogsContext } from "shared/dialogs/useDialogsContext";
import { GenderRecord } from "shared/records/GenderRecord";
import { TypographyX } from "shared/TypographyX";
import { AddReservationLineForm } from "../forms/AddReservationLineForm";
import { PriceForm } from "../forms/PriceForm";
import { ReservationPersonalInformationForm } from "../forms/ReservationPersonalInformationForm";
import { ReservationLineWithInventoryItemMaterialIconWithColor } from "../ReservationLineWithInventoryItemMaterialIconWithColor";
import { BrandModelOrOwnBootComponent } from "./BrandModelOrOwnBootComponent";
import { PrintReservationTicketButton } from "./PrintReservationTicketButton";
import { ReservationDetailHeader } from "./ReservationDetailHeader";
import { ReservationLineActions } from "./ReservationLineActions";

export const ReservationDetail = () => {
  const { id } = useParams<IRouteParamsWithId>();
  const [dto, reload] = useApiEffect(reservationsQuery_single, id);
  const detail = useMemo(() => dto?.item, [dto]);
  const lines = useMemo(() => dto?.lines, [dto]);
  const { open, confirm, cancel } = useDialogsContext(reload);
  const status = useMemo(() => detail?.status as ReservationStatus, [detail]);
  const notify = useSnackbarNotify();
  const { push } = useHistory();

  if (
    dto === undefined ||
    detail === undefined ||
    lines === undefined ||
    status === undefined
  ) {
    return <div></div>;
  }

  const headers = [
    createHeader<IReservationLineWithInventoryItem>("Type", (t) => (
      <ReservationLineWithInventoryItemMaterialIconWithColor line={t} />
    )),
    createHeader<IReservationLineWithInventoryItem>("MERK/MODEL", (t) => (
      <BrandModelOrOwnBootComponent item={t} />
    )),
    createHeader<IReservationLineWithInventoryItem>("Locatie", (t) =>
      t.inventoryItem ? t.inventoryItem.stockLocation : ""
    ),
    createHeader<IReservationLineWithInventoryItem>("Maat", (t) =>
      t.inventoryItem ? t.inventoryItem.formattedDimensions : ""
    ),
    createHeader<IReservationLineWithInventoryItem>("Prijs", (t) =>
      t.reservationLine.isOwnBoot ? "" : formatCurrency(t.reservationLine.price)
    ),
    createEmptyHeader<IReservationLineWithInventoryItem>("actions", (t) => (
      <ReservationLineActions
        reservation={detail}
        line={t.reservationLine}
        reload={reload}
      />
    )),
  ];

  const onEditPI = () => {
    if (detail.canEditPersonalInformation === false) {
      notify(
        "Je kan dit enkel aanpassen bij een niet verhuurde reservatie",
        "error"
      );
    } else {
      open(
        <ReservationPersonalInformationForm
          open
          confirm={confirm}
          cancel={cancel}
          item={detail}
        />
      );
    }
  };

  const onAddLine = () => {
    open(
      <AddReservationLineForm
        open
        reservation={detail}
        confirm={confirm}
        cancel={cancel}
      />
    );
  };

  const onEditPrice = () => {
    open(<PriceForm open item={detail} cancel={cancel} confirm={confirm} />);
  };

  const onResendConfirmation = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, verzenden!"
        rejectText="Annuleer"
        title="Bevestig"
        content="Emailbevesting opnieuw versturen"
        reject={cancel}
        acceptF={() => requestsCommand_resendEmailConfirmation(detail.id)}
        accept={confirm}
      />
    );
  };

  const onEditComment = () => {
    open(
      <StringModelForm
        open
        initialValue={detail.comments}
        confirm={confirm}
        cancel={cancel}
        submitFunction={(val) =>
          reservationsCommand_updateComment(detail.id, val)
        }
        formTitle={`Wijzig opmerking`}
        submitText={"Wijzig"}
        label={"Opmerking"}
        multiline
      />
    );
  };

  const onSetReadyForCollection = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, klaar!"
        rejectText="Annuleer"
        title="Bevestig"
        content="Is deze reservatie volledig klaar voor af te halen?"
        reject={cancel}
        acceptF={() => reservationsCommand_setReadyForRental(detail.id)}
        accept={confirm}
      />
    );
  };

  const onSetCollected = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, afgehaald!"
        rejectText="Annuleer"
        title="Bevestig"
        content="Is deze reservatie volledig afgehaald?"
        reject={cancel}
        acceptF={() => reservationsCommand_collect(detail.id)}
        accept={confirm}
      />
    );
  };

  const onSetReturned = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, teruggebracht!"
        rejectText="Annuleer"
        title="Bevestig"
        content="Is deze reservatie volledig teruggebracht?"
        reject={cancel}
        acceptF={() => reservationsCommand_return(detail.id)}
        accept={confirm}
      />
    );
  };

  const onAddOwnBoot = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, voeg toe!"
        rejectText="Annuleer"
        title="Bevestig"
        content="Eigen botten aan deze reservatie toevoegen?"
        reject={cancel}
        acceptF={() => reservationsCommand_addOwnBoot(detail.id)}
        accept={confirm}
      />
    );
  };

  const onCancel = () => {
    if (dto.item.canCancel === false) {
      notify(
        "Je kan een reservatie enkel als deze nog niet is uitgeleend.",
        "warning"
      );
    } else {
      open(
        <AlertDialog
          open
          acceptText="Ja, verwijder!"
          rejectText="Annuleer"
          title="Ben je zeker?"
          content={`Wil je deze reservatie annuleren?\nGelinkte items worden terug vrijgemaakt.`}
          reject={cancel}
          acceptF={() => reservationsCommand_cancel(dto.id)}
          accept={confirm}
        />
      );
    }
  };

  return (
    <PageWithoutSearch
      onGoBack={() => push(ReservationsRoute)}
      backText="TERUG NAAR RESERVATIES"
      onRefresh={reload}
    >
      <ReservationDetailHeader
        item={detail}
        reload={reload}
        style={{ marginBottom: 30 }}
      />
      <Card className="df-col" style={{ marginBottom: 30 }}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TypographyX fontSize={20} bold style={{ padding: "20px" }}>
                Materiaal
              </TypographyX>
              {detail.canAddLine && (
                <>
                  <PrimaryContainedButton
                    size="small"
                    onClick={onAddLine}
                    style={{ marginRight: 10 }}
                  >
                    + voeg toe
                  </PrimaryContainedButton>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={onAddOwnBoot}
                    style={{ marginRight: 10 }}
                  >
                    + voeg eigen bot toe
                  </Button>
                </>
              )}

              {status === "CanBePrepared" && (
                <PrimaryContainedButton
                  size="small"
                  style={{ marginRight: "10px" }}
                  onClick={onSetReadyForCollection}
                >
                  {`Klaar voor op te halen!`}
                </PrimaryContainedButton>
              )}
              {status === "WaitingCollection" && (
                <PrimaryContainedButton
                  size="small"
                  style={{ marginRight: "10px" }}
                  onClick={onSetCollected}
                >
                  {`Afgehaald!`}
                </PrimaryContainedButton>
              )}
              {status === "Rented" && (
                <PrimaryContainedButton
                  size="small"
                  style={{ marginRight: "10px" }}
                  onClick={onSetReturned}
                >
                  {`Teruggebracht!`}
                </PrimaryContainedButton>
              )}
            </div>
            <div className="fg1"></div>
            <PrimaryContainedButton
              size="small"
              variant="outlined"
              color="default"
              onClick={onResendConfirmation}
              style={{ marginRight: 8 }}
            >
              {" "}
              Stuur Email
            </PrimaryContainedButton>
            <PrintReservationTicketButton reservation={detail} />
            <PrimaryContainedButton
              size="small"
              disabled={dto.item.canCancel === false}
              onClick={onCancel}
              style={{ marginRight: 8 }}
              color="secondary"
              variant="outlined"
            >
              Verwijder
            </PrimaryContainedButton>
          </div>
        </div>
        {lines && lines.length > 0 && (
          <>
            <Divider />
            <TableWithHeadersAndValues<IReservationLineWithInventoryItem>
              headers={headers}
              getKey={(t) => t.reservationLine.id}
              values={lines}
            />
          </>
        )}
      </Card>
      <div className="df-row jc-sb">
        <Card className="fg1" style={{ marginRight: 20 }}>
          <CardHeader
            title="Contactgegevens"
            withPadding
            action={
              <IconButton onClick={onEditPI}>
                <EditOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent className="df-col" style={{ paddingTop: 0 }}>
            <TypographyX fontSize={18} lineHeight={22}>{`Geslacht: ${
              GenderRecord[detail.gender as Gender]
            }`}</TypographyX>
            <TypographyX fontSize={18} lineHeight={22}>
              {detail.email}
            </TypographyX>
            <TypographyX fontSize={18} lineHeight={22}>
              {detail.phoneNumber}
            </TypographyX>
            <TypographyX fontSize={18} lineHeight={22}>
              {formatDate(detail.dateOfBirth)}
            </TypographyX>
            <TypographyX fontSize={18} lineHeight={22}>
              {detail.address}
            </TypographyX>
          </CardContent>
        </Card>
        <Card className="fg1" style={{ marginRight: 20 }}>
          <CardHeader
            title="Opmerkingen"
            action={
              <IconButton onClick={onEditComment}>
                <EditOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent className="df-col" style={{ paddingTop: 0 }}>
            <TypographyX fontSize={18} multiLine lineHeight={22}>
              {detail.comments}
            </TypographyX>
          </CardContent>
        </Card>
        <Card className="fg1">
          <CardHeader
            title="Prijs"
            action={
              <IconButton onClick={onEditPrice}>
                <EditOutlinedIcon />
              </IconButton>
            }
          />
          <CardContent className="df-col" style={{ paddingTop: 0 }}>
            <TypographyX fontSize={18}>
              Berekende prijs: {formatCurrency(detail.calculatedPrice)}
            </TypographyX>
            {detail.isPriceOverride ? (
              <TypographyX fontSize={22} bold>
                Prijs na korting: {formatCurrency(detail.overridePrice ?? 0)}
              </TypographyX>
            ) : null}
          </CardContent>
        </Card>
      </div>
    </PageWithoutSearch>
  );
};
