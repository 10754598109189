export const LoginRoute = "/login";
export const WelcomeRoute = "/welcome";
export const RequestRoute = "/request/:id";
export const calcRequestRoute = (id: string) => RequestRoute.replace(":id", id);

export const DashboardRoute = "/dashboard";
export const ReservationsRoute = "/reservations";
export const ReservationDetailRoute = `${ReservationsRoute}/:id`;
export const calcReservationDetailRoute = (id: string): string =>
  ReservationDetailRoute.replace(":id", id);
export const InventoryItemsRoute = "/inventory-items";
export const InventoryItemDetailRoute = `${InventoryItemsRoute}/:id`;
export const calcInventoryItemDetailRoute = (id: string): string =>
  InventoryItemDetailRoute.replace(":id", id);
export const HolidayPeriodsRoute = "/holidays";
export const CustomersRoute = "/customers";
export const CustomerDetailRoute = `${CustomersRoute}/:id`;
export const calcCustomerDetailRoute = (id: string): string =>
  CustomerDetailRoute.replace(":id", id);
export const UsersRoute = "/users";
export const ReservationStatusDashBoard = "/reservation-status/:id";
export const calcReservationStatusDashBoardDetail = (id: string): string =>
  "/reservation-status/" + id;
export const analysis = "/analysis";
