import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IHolidayPeriodsQueryParams } from 'gen/ApiClient';
import React from 'react';

const exclusions: (keyof IHolidayPeriodsQueryParams)[] = ['isUpcoming'];

interface IProps {
    params: IHolidayPeriodsQueryParams;
    onFilter: (params: IHolidayPeriodsQueryParams) => void;
    style?: React.CSSProperties;
}

export const Filter = ({ params, onFilter, style }: IProps) => {   
    return (
        <FilterBar
            onClear={() => onFilter(clearParams(params, exclusions))}
            isFiltered={isFiltered(params, exclusions)}
            style={style}>
            <div className="df-row-ac df-col-if-mobile">
                <DateFilterItem
                    label="Van datum"
                    after={params.fromAfter}
                    before={params.fromBefore}
                    setSelected={(after, before) => onFilter({ ...params, fromAfter: after, fromBefore: before })} />
            </div>
        </FilterBar>
    );
}