import {
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { DatePicker } from "@material-ui/pickers";
import { useField } from "formik";
import { useLocalization } from "localization/useLocalization";
import React from "react";
import { useAnchorElement } from "../hooks/useAnchorElement";
import { useStateBoolean } from "../hooks/useStateBool";
import { DefaultDateFormat } from "../utils/date/DefaultDateFormat";
import { mapToIsoOnlyDate } from "../utils/date/mapToIsoOnlyDate";
import { nameOf } from "../utils/nameOf";
import { DatePickerViewType } from "./common/DatePickerViewType";
import { FormErrorHelper } from "./common/FormErrorHelper";
import { IFormProps } from "./common/IFormProps";
import { StaticDatePickerPopover } from "./common/StaticDatePickerPopover";

interface IProps<TModel> extends IFormProps<TModel> {
  disableFuture?: boolean;
  disablePast?: boolean;
  openTo?: DatePickerViewType;
  views?: DatePickerViewType[];
  format?: string;
}

export const FormDatePickerOutlined = <TModel extends unknown>({
  style,
  disableFuture = false,
  disablePast = false,
  disabled = false,
  required = false,
  helperText,
  openTo = "date",
  views = ["year", "month", "date"],
  format = DefaultDateFormat,
  ...props
}: IProps<TModel>) => {
  const strings = useLocalization();
  const [field, meta, helpers] = useField<Date | null>(nameOf(props.name));
  const [anchor, openSelectDate, closeSelectDate] = useAnchorElement();
  const [isHovered, enter, leave] = useStateBoolean(false);

  return (
    <div className="df-col" style={style}>
      {Boolean(anchor) && (
        <StaticDatePickerPopover
          disablePast={disablePast}
          value={field.value}
          setValue={helpers.setValue}
          anchor={anchor}
          close={closeSelectDate}
          openTo={openTo}
          views={views}
        />
      )}
      <DatePicker
        // label={props.label}
        disabled={disabled}
        {...field}
        disableFuture={disableFuture}
        disablePast={disablePast}
        clearable
        value={field.value === undefined ? null : field.value} // workaround for not handling undefined values BUT handling null values
        onChange={(date) => helpers.setValue(mapToIsoOnlyDate(date))}
        inputFormat={format}
        openTo={openTo}
        views={views}
        renderInput={(rProps) => (
          <div className="df-col" style={{ marginTop: 8 }}>
            <div className="df-row-ac jc-sb" style={{ marginBottom: 4 }}>
              <Typography variant="body1">{props.label}</Typography>
              {required === false && (
                <Typography variant="body1" color="textSecondary">
                  {strings.optional}
                </Typography>
              )}
            </div>
            <TextField
              {...rProps}
              onMouseEnter={enter}
              onMouseLeave={leave}
              className="fg1"
              variant="outlined"
              helperText=""
              required={required}
              error={Boolean(meta.error) && meta.touched}
              onBlur={() => helpers.setTouched(true, true)}
              inputProps={{
                ...rProps.inputProps,
                style: {
                  ...rProps.inputProps!.style,
                  width: "calc(100% - 80px)",
                  paddingLeft: "10px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <IconButton onClick={openSelectDate} edge="start">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Fade
                      in={
                        isHovered &&
                        field.value !== undefined &&
                        field.value !== null &&
                        disabled === false
                      }
                    >
                      <CancelIcon
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => helpers.setValue(null)}
                      />
                    </Fade>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      />
      <FormErrorHelper meta={meta} withDate helperText={helperText} />
    </div>
  );
};
