import { IQueryContext } from "framework/context/IQueryContext";
import { useContext, useEffect } from "react";
import { useLazyEffect } from "./useLazyEffect";
import { Trigger } from "./useTrigger";

interface IXQueryContext<TResult, TParams> {
    params: TParams;
    setParams: React.Dispatch<React.SetStateAction<TParams>>;
    queryResult: TResult | undefined;
    reload: VoidFunction;
}

export function useQueryContext<TResult, TParams>(context: React.Context<IQueryContext<TResult, TParams>>, reloadTrigger?: Trigger): IXQueryContext<TResult, TParams> {
    const { init, ...rest } = useContext(context);

    useEffect(() => {
        console.log('init');
        init();
    }, [init]);

    useLazyEffect(() => {
        if (reloadTrigger !== undefined) {
            init();
        }
    }, [reloadTrigger]);

    return ({ ...rest });
}