import { Button } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { AlertDialog } from "framework/dialogs/AlertDialog";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LoginRoute, WelcomeRoute } from "routes";
import { useDialogsContext } from "shared/dialogs/useDialogsContext";
import { Logo } from "../logo/Logo";

interface IProps {
  step: number;
}

export const Header = ({ step }: IProps) => {
  const { open, confirm, cancel } = useDialogsContext();
  const { push } = useHistory();
  const [counter, setCounter] = useState<number>(10);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let cancelled = false;
    if (step === 5 && counter > 0 && cancelled === false) {
      timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 2000);
    } else if (step === 5 && counter === 0) {
      push(WelcomeRoute);
    }
    return () => {
      clearTimeout(timer);
      cancelled = true;
    };
    // eslint-disable-next-line
  }, [step, counter]);

  const onRestart = () => {
    if (step === 5) {
      push(WelcomeRoute);
    } else {
      open(
        <AlertDialog
          open
          title="Herbegin"
          content="Ben je zeker dat je wilt herbeginnen met deze reservatie?"
          acceptText="Ja, herbegin!"
          rejectText="Nee, sluit dit venster"
          accept={() => {
            confirm();
            push(WelcomeRoute);
          }}
          reject={cancel}
        />
      );
    }
  };

  const onLogin = () => {
    open(
      <AlertDialog
        open
        title="Aanmelden"
        content="Ben je zeker dat je deze reservatie wil afsluiten?"
        acceptText="Ja, ga naar login!"
        rejectText="Nee, sluit dit venster"
        accept={() => {
          confirm();
          push(LoginRoute);
        }}
        reject={cancel}
      />
    );
  };

  return (
    <div
      style={{
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: "white",
        boxShadow: "0 1px 0 0 lightGray",
      }}
      className="df-row-ac sticky-at-top"
    >
      <Logo
        width={60}
        style={{ cursor: "pointer", marginTop: 10, marginBottom: 10 }}
        onClick={onLogin}
      />
      <div className="fg1"></div>
      <Button
        startIcon={<CachedIcon />}
        onClick={onRestart}
        variant="outlined"
        size="small"
      >
        <div className="df-row-ac">
          <div>Herbegin</div>
          {step === 5 && (
            <div
              style={{
                border: "1px solid black",
                marginLeft: "10px",
                borderRadius: "50px",
                width: "18px",
                height: "18px",
                lineHeight: "18px",
              }}
            >
              {counter}
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};
