import { useLoggedInUser } from 'app/auth/useLoggedInUser';
import React from 'react';
import { TypographyX } from 'shared/TypographyX';

interface IProps {
    style?: React.CSSProperties;
}

export const UserButtonAndMenu = ({ style }: IProps) => {
    const user = useLoggedInUser();

    return (
        <div className="df-col-ac jc-c stretch-ver" style={style}>
            <TypographyX fontSize={12} bold toUpper color="color-grey-medium" style={{ whiteSpace: 'nowrap'}}>aangemeld als</TypographyX>
            <TypographyX fontSize={18} bold toUpper>{user.name}</TypographyX>
        </div>
    );
}