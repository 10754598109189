import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import React from 'react';

export interface ILoaderButtonProps extends ButtonProps {
    isLoading: boolean;
}

export const LoaderButton = ({ isLoading, children, size, disabled = false, ...rest }: ILoaderButtonProps) => {
    const progressSize = size === 'small' ? 12 : size === 'large' ? 18 : 15;
    return (
        <Button
            {...rest}
            disabled={disabled || isLoading}
            size={size}
            startIcon={isLoading ? <CircularProgress style={{ color: 'inherit' }}
                size={progressSize} /> : rest.startIcon}>
            {children}
        </Button>
    );
}