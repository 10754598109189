import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import React from 'react';
import { TypographyX } from 'shared/TypographyX';

interface IProps extends DialogProps {
    close: () => void;
}

export const JuniorInformationDialog = ({ close, ...rest }: IProps) => {
    return (
        <Dialog
            {...rest}
            onClose={close}>
            <CloseButtonOnAbsolutePosition onClick={close} />
            <DialogTitle>{`Ski/Snowboard voor junioren`}</DialogTitle>
            <DialogContent
                dividers
                style={{ paddingBottom: 40 }}>
                <div className="df-col">
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>Ski</TypographyX>
                    <TypographyX fontSize={20}>
                        Ons skipakket “Ski-Junior” is steeds inclusief ski’s, boots en stokken.
                        De prijzen zijn geldig tot en met 15 jaar.
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>Snowboard</TypographyX>
                    <TypographyX fontSize={20}>
                        Ons snowboardpakket “Junior-Snowboard” is steeds inclusief snowboard, boots en bindings.
                        De prijzen zijn geldig tot en met 15 jaar.
                    </TypographyX>
                </div>
            </DialogContent>
        </Dialog>
    );
}