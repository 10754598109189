import { IAvailableInventoryItemsQueryParams } from "gen/ApiClient";

export const DefaultAvailableInventoryItemsQueryParams : IAvailableInventoryItemsQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
    stockLocations: [],
    brands: [],
    models: [],
    sizes: [],
    lengths: [],
    reservationStatuses: [],
    isShowAll: false,
}
