import { Checkbox } from '@material-ui/core';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { InventoryType, IRequest, requestsCommand_confirm } from 'gen/ApiClient';
import React from 'react';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { TypographyX } from 'shared/TypographyX';
import { BackButton } from '../BackButton';
import { Page } from '../Page';

interface IProps {
    request: IRequest;
    onNext: VoidFunction;
    onGotoStep: (step: number) => void;
    onPrevious: VoidFunction;
}

export const Overview = ({ request, onNext, onGotoStep, onPrevious }: IProps) => {
    const [isConsent, toggleConsent] = useStateToggleBoolean(false);
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_confirm);
    const notify = useSnackbarNotify();

    const onSubmit = async () => {
        const r = await submit(request.id, isConsent);
        handleValidateResponseBase(r, e => notify(e, 'error'), () => onNext());
    }

    return (
        <Page step={4} onGotoStep={onGotoStep} item={request}>
            <TypographyX fontSize={28} bold toUpper>overzicht</TypographyX>
            <div className="df-col" style={{ marginTop: '30px' }}>
                <div className="df-row-ab">
                    <TypographyX fontSize={20} bold toUpper style={{ marginRight: '10px', marginBottom: '10px' }}>prijzen</TypographyX>
                </div>
                {request.lines.map(t =>
                    <div className="df-row-ac" key={t.inventoryType!}>
                        <TypographyX fontSize={18} style={{ width: '200px' }}>{t.isOwnBoot ? 'Eigen bot' : InventoryTypeRecord[t.inventoryType as InventoryType]}</TypographyX>
                        <TypographyX fontSize={18}>{formatCurrency(t.price)}</TypographyX>
                    </div>
                )}
                <div className="df-row-ac">
                    <TypographyX fontSize={26} bold style={{ marginTop: '10px', width: '200px' }}>Totaal</TypographyX>
                    <TypographyX fontSize={26} bold style={{ marginTop: '10px' }}>{formatCurrency(request.totalPrice)}</TypographyX>
                </div>
            </div>
            <div className='fg1'></div>
            <div className='df-row-ac jc-e' onClick={toggleConsent}>
                <Checkbox checked={isConsent} />
                <div style={{ fontSize: 20, whiteSpace: 'pre-line', textAlign: 'right', cursor: 'pointer' }}>{`Mogen we je e-mailadres gebruiken om je op de\nhoogte te houden van onze commerciële acties?`}</div>
            </div>
            <div className="df-row-ac jc-sb" style={{ marginTop: 30 }}>
                <BackButton onGoBack={onPrevious} text="Terug" />
                <PrimaryContainedButton isLoading={isSubmitting} onClick={() => onSubmit()}>BEVESTIG</PrimaryContainedButton>
            </div>
        </Page>
    );
}