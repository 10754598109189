import { addDays, addMonths, addQuarters, addWeeks, addYears, endOfDay, endOfMonth, endOfQuarter, endOfYear, startOfDay, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { mapToIso } from '../../utils/date/mapToIso';
import { nlBELocaleEndOfWeek } from '../../utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from '../../utils/date/nlBELocaleStartOfWeek';
import { IDateRange } from "./IDateRange";
import { QuickFilterType } from "./QuickFilterType";

export const createQuickFilterTypeRecords = (now: Date): Record<QuickFilterType, IDateRange> => {
    // const now = new Date();
    return ({
        today: { after: mapToIso(startOfDay(now)), before: mapToIso(endOfDay(now)) },
        yesterday: { after: mapToIso(startOfDay(addDays(now, -1))), before: mapToIso(endOfDay(addDays(now, -1))) },
        this_week: { after: mapToIso(nlBELocaleStartOfWeek(now)), before: mapToIso(nlBELocaleEndOfWeek(now)) },
        last_week: { after: mapToIso(nlBELocaleStartOfWeek(addWeeks(now, -1))), before: mapToIso(nlBELocaleEndOfWeek(addWeeks(now, -1))) },
        this_month: { after: mapToIso(startOfMonth(now)), before: mapToIso(endOfMonth(now)) },
        last_month: { after: mapToIso(startOfMonth(addMonths(now, -1))), before: mapToIso(endOfMonth(addMonths(now, -1))) },
        this_quarter: { after: mapToIso(startOfQuarter(now)), before: mapToIso(endOfQuarter(now)) },
        last_quarter: { after: mapToIso(startOfQuarter(addQuarters(now, -1))), before: mapToIso(endOfQuarter(addQuarters(now, -1))) },
        this_year: { after: mapToIso(startOfYear(now)), before: mapToIso(endOfYear(now)) },
        last_year: { after: mapToIso(startOfYear(addYears(now, -1))), before: mapToIso(endOfYear(addYears(now, -1))) },
    });
}