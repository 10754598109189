import { InventoryType } from 'gen/ApiClient';
import React from 'react';
import { SkiBootIcon } from './SkiBootIcon';
import { SkisIcon } from './SkisIcon';
import { SnowboardIcon } from './SnowboardIcon';

interface IProps {
    type: InventoryType;
    style?: React.CSSProperties;
}

export const InventoryTypeIcon = ({ type, style }: IProps) => {
    if (type === 'SkiBoot' || type === 'SnowboardBoot' || type === 'SkiBootTour') {
        return <SkiBootIcon style={style} />
    } else if (type === 'SkiComfort' || type === 'SkiJunior' || type === 'SkiVip' || type === 'SkiTour') {
        return <SkisIcon style={style} />
    } else if (type === 'Snowboard' || type === 'SnowboardJunior') {
        return <SnowboardIcon style={style} />
    } else {
        return <div></div>
    }
}