import { Card, CardHeader, Divider } from "@material-ui/core";
import { useLazyEffect } from "framework/hooks/useLazyEffect";
import { useQueryContext } from "framework/hooks/useQueryContext";
import { useTrigger } from "framework/hooks/useTrigger";
import { createEmptyHeader } from "framework/table/createEmptyHeader";
import { createHeader } from "framework/table/createHeader";
import { createSortableHeader } from "framework/table/createSortableHeader";
import { PageableTable } from "framework/table/PageableTable";
import { onResetToPage1 } from "framework/types/onResetToPage1";
import { IInventoryItem, InventoryItemReservationStatus } from "gen/ApiClient";
import React from "react";
import { useHistory } from "react-router-dom";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";
import { useDialogsContext } from "shared/dialogs/useDialogsContext";
import { InventoryItemReservationStatusRecord } from "shared/records/InventoryItemReservationStatusRecord";
import * as routes from "../../../routes";
import { PageWithSearch } from "../PageWithSearch";
import { QueryContext } from "./context/QueryContext";
import { Filter } from "./Filter";
import { InventoryIcon } from "./InventoryIcon";
import { InventoryItemForm } from "./InventoryItemForm";

export const InventoryItemsList = () => {
  const [trigger, hitTrigger] = useTrigger();
  const { params, setParams, queryResult, reload } =
    useQueryContext(QueryContext);

  const { push } = useHistory();
  const { open, confirm, cancel } = useDialogsContext(hitTrigger);

  useLazyEffect(() => {
    reload();
  }, [trigger]);

  const headers = [
    createHeader<IInventoryItem>("Type", (t) => (
      <InventoryIcon item={t} withDescription />
    )),
    createSortableHeader<IInventoryItem>(
      "Merk, Model",
      "brandCommaModel",
      (t) => t.brandCommaModel
    ),
    createSortableHeader<IInventoryItem>(
      "Stocklocatie",
      "stockLocation",
      (t) => t.stockLocation
    ),
    createSortableHeader<IInventoryItem>("Maat", "size", (t) =>
      t.size.toString()
    ),
    createSortableHeader<IInventoryItem>("Lengte (in cm)", "lengthInCm", (t) =>
      t.lengthInCm.toString()
    ),
    createHeader<IInventoryItem>(
      "Reservatiestatus",
      (t) =>
        InventoryItemReservationStatusRecord[
          t.reservationStatus as InventoryItemReservationStatus
        ]
    ),
    createSortableHeader<IInventoryItem>(
      `Opkomende\nReservaties`,
      "countUpcomingReservations",
      (t) => t.countUpcomingReservations.toString()
    ),
    createEmptyHeader<IInventoryItem>(
      "action",
      (t) => (
        <PrimaryContainedButton
          size="small"
          onClick={() => push(routes.calcInventoryItemDetailRoute(t.id))}
        >
          Details
        </PrimaryContainedButton>
      ),
      "right"
    ),
  ];

  // const onEdit = (item: IInventoryItem) => {
  //     open(<InventoryItemUpdateForm
  //         open
  //         item={item}
  //         confirm={confirm}
  //         cancel={cancel} />)
  // }

  const onNew = () => {
    open(<InventoryItemForm open cancel={cancel} confirm={confirm} />);
  };

  return (
    <PageWithSearch
      onRefresh={reload}
      placeholder={`Zoek op merk/model/status...`}
      searchString={params.searchString}
      onSearch={(val) =>
        setParams(onResetToPage1({ ...params, searchString: val }))
      }
    >
      <div className="df-col">
        <PrimaryContainedButton
          size="small"
          className="w-fc"
          onClick={onNew}
          style={{ marginBottom: 20 }}
        >
          + Nieuw item
        </PrimaryContainedButton>
        {queryResult && (
          <Card>
            <CardHeader title="Inventaris" />
            <Divider />
            <Filter
              params={params}
              onFilter={(pars) =>
                setParams({
                  ...pars,
                  pageParams: { ...params.pageParams, index: 1 },
                })
              }
              reloadTrigger={trigger}
            />
            <Divider />
            <PageableTable<IInventoryItem>
              queryResult={queryResult}
              headers={headers}
              getKey={(t) => t.id!}
              pageParams={params.pageParams}
              sortParams={params.sortParams}
              setParams={(sp, pp) =>
                setParams({ ...params, sortParams: sp, pageParams: pp })
              }
            />
          </Card>
        )}
      </div>
    </PageWithSearch>
  );
};
