import { Dialog, DialogProps, DialogTitle, Paper, PaperProps } from '@material-ui/core';
import React from 'react';
import Draggable from 'react-draggable';

interface IProps extends DialogProps {
    title: string;
}

export const DraggableDialog = ({ title, children, ...props }: IProps) => {
    return (
        <Dialog
            {...props}
            PaperComponent={DraggablePaperComponent}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>
            {children}
        </Dialog>
    );
}

const DraggablePaperComponent = (props: PaperProps) => {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }
