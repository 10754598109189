import { endOfDay, endOfMonth, endOfQuarter, endOfYear, startOfDay, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';
import { mapToIso } from '../../utils/date/mapToIso';
import { nlBELocaleEndOfWeek } from '../../utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from '../../utils/date/nlBELocaleStartOfWeek';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { isNotNullNorUndefined } from '../../utils/nullNorUndefinedCheck';
import { DateRangeType } from './DateRangeType';
import { IDateRange } from './IDateRange';

export const calculateDateRangeType = (val: IDateRange): DateRangeType => {
    if (isNullOrUndefined(val.after) && isNullOrUndefined(val.before)) {
        return 'unset';
    } else if (isNotNullNorUndefined(val.after) && isNullOrUndefined(val.before)) {
        return 'all-after';
    } else if (isNullOrUndefined(val.after) && isNotNullNorUndefined(val.before)) {
        return 'all-before';
    } else {
        const after = new Date(val.after!);
        if (val.after === mapToIso(startOfDay(after)) && val.before === mapToIso(endOfDay(after))) {
            return 'day';
        } else if (val.after === mapToIso(nlBELocaleStartOfWeek(after)) && val.before === mapToIso(nlBELocaleEndOfWeek(after))) {
            return 'week';
        } else if (val.after === mapToIso(startOfMonth(after)) && val.before === mapToIso(endOfMonth(after))) {
            return 'month';
        } else if (val.after === mapToIso(startOfQuarter(after)) && val.before === mapToIso(endOfQuarter(after))) {
            return 'quarter';
        } else if (val.after === mapToIso(startOfYear(after)) && val.before === mapToIso(endOfYear(after))) {
            return 'year';
        }
    }
    return 'custom';
}