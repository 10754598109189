import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';

interface IProps {
    isChecked: boolean;
    toggle: VoidFunction;
    label: string;
    style?: React.CSSProperties;
}

export const FormControlLabelSwitch = ({ isChecked, toggle, label, style }: IProps) => {
    return (
        <FormControlLabel
            control={<Switch checked={isChecked} onChange={toggle} size="small" />}
            label={<div style={{ fontSize: '14px' }}>{label}</div>}
            style={style} />
    );
}



