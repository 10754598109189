import { formatDate } from 'framework/utils/date/formatDate';
import { IRequest } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { SkisIcon } from 'shared/icons/SkisIcon';
import { TextLinkButton } from 'shared/TextLinkButton';
import { TypographyX } from 'shared/TypographyX';

interface IProps {
    request: IRequest;
    onGotoStep: (step: number) => void;
}

export const SideBar = ({ request, onGotoStep }: IProps) => {
    const isShowEmpty = useMemo(() => request.isMaterialSet === false && request.isPeriodSet === false && request.isPersonalInformationSet === false, [request]);

    return (
        <div className="df-col" style={{ padding: 40, minWidth: 500, boxShadow: '-1px 0 0 0 lightGray', backgroundColor: 'white' }}>
            <TypographyX fontSize={28} bold toUpper>je reservatie</TypographyX>
            {isShowEmpty &&
                <div className="df-col-ac stretch-ver jc-c" style={{ paddingBottom: '50px' }}>
                    <SkisIcon style={{ height: '98px' }} />
                    <TypographyX fontSize={18} center style={{ marginTop: '20px' }}>Er zit voorlopig nog niets in je reservatie</TypographyX>
                </div>}
            {request.isPeriodSet &&
                <div className="df-col" style={{ marginTop: '30px' }}>
                    <div className="df-row-ac jc-sb">
                        <TypographyX fontSize={20} bold toUpper>periode</TypographyX>
                        <TextLinkButton onClick={() => onGotoStep(1)}><TypographyX toUpper fontSize={18} bold color="color-green-dark">aanpassen</TypographyX></TextLinkButton>
                    </div>
                    <TypographyX fontSize={18} style={{ marginTop: '10px' }}>{request.periodFromToFormatted}</TypographyX>
                    <TypographyX fontSize={18}>{`(aantal dagen: ${request.days})`}</TypographyX>
                    <TypographyX fontSize={18} color="color-grey-dark">Afhaaldatum: {formatDate(request.collectionDate!)}</TypographyX>
                    <TypographyX fontSize={18} color="color-grey-dark">Terugbrengen: {formatDate(request.returnBeforeDate!)}</TypographyX>
                </div>
            }
            {request.isPersonalInformationSet &&
                <div className="df-col" style={{ marginTop: '30px' }}>
                    <div className="df-row-ac jc-sb">
                        <TypographyX fontSize={20} bold toUpper>persoonsgegevens</TypographyX>
                        <TextLinkButton onClick={() => onGotoStep(2)}><TypographyX toUpper fontSize={18} bold color="color-green-dark">aanpassen</TypographyX></TextLinkButton>
                    </div>
                    <TypographyX fontSize={18} style={{ marginTop: '10px' }} multiLine>{request.personalInfoFormatted}</TypographyX>
                </div>}
            {request.isMaterialSet &&
                <div className="df-col" style={{ marginTop: '30px' }}>
                    <div className="df-row-ac jc-sb">
                        <TypographyX fontSize={20} bold toUpper>materiaal</TypographyX>
                        <TextLinkButton onClick={() => onGotoStep(3)}><TypographyX toUpper fontSize={18} bold color="color-green-dark">aanpassen</TypographyX></TextLinkButton>
                    </div>
                    <TypographyX fontSize={18} style={{ marginTop: '10px' }} multiLine>{request.materialInfoFormatted}</TypographyX>
                </div>}
        </div>
    );
}