import { Loader } from 'app/loader/Loader';
import { RectangularChip } from 'framework/components/chips/RectangularChip';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { InventoryType, reservationsQuery_singleClientStatus, ReservationStatus } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { ReservationStatusRecord } from 'shared/records/ReservationStatusRecord';
import { TypographyX } from 'shared/TypographyX';

export const ReservationStatusScreen = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [dto] = useApiEffect(reservationsQuery_singleClientStatus, id);

	return dto ? (
		<div className='df-col apply-background  h100'>
			<div
				className='df-col'
				style={{
					padding: 40,
					minWidth: 500,
					boxShadow: '-1px 0 0 0 lightGray',
				}}>
				<TypographyX
					fontSize={28}
					bold
					toUpper>
					Jouw reservatie bij Zwerver⛷
				</TypographyX>
				<TypographyX
					fontSize={22}
					bold
					style={{ marginTop: 10 }}>
					Je reservatienummer is <strong> {dto.number}</strong>
				</TypographyX>

				<div
					className='df-col'
					style={{ marginTop: '30px' }}>
					<TypographyX
						fontSize={20}
						bold
						toUpper>
						Status
					</TypographyX>
					<RectangularChip
						style={{ marginTop: 10 }}
						label={ReservationStatusRecord[dto.status as ReservationStatus]}
						color={'info'}
					/>

					<TypographyX
						style={{ marginTop: '30px' }}
						fontSize={20}
						bold
						toUpper>
						periode
					</TypographyX>

					<TypographyX
						fontSize={18}
						style={{ marginTop: '10px' }}>
						{' '}
						Van {formatDate(dto.fromDate, 'only-date')} Tot: {formatDate(dto.toDate, 'only-date')}
					</TypographyX>

					<TypographyX
						fontSize={18}
						color='color-grey-dark'>
						Afhaaldatum VANAF:{formatDate(dto.collectionDate, 'only-date')}
					</TypographyX>
					<TypographyX
						fontSize={18}
						color='color-grey-dark'>
						Terugbrengen TEM: {formatDate(dto.returnBeforeDate, 'only-date')}
					</TypographyX>
				</div>

				<div
					className='df-col'
					style={{ marginTop: '30px' }}>
					<TypographyX
						fontSize={20}
						bold
						toUpper>
						persoonsgegevens
					</TypographyX>

					<TypographyX
						fontSize={18}
						style={{ marginTop: '10px' }}>
						{dto.firstNameLastName}
					</TypographyX>
					<TypographyX fontSize={18}>{dto.email}</TypographyX>
					<TypographyX fontSize={18}>{dto.address}</TypographyX>
					<TypographyX fontSize={18}>{dto.length} Cm</TypographyX>
					<TypographyX fontSize={18}>{dto.weight} Kg</TypographyX>
				</div>

				<div
					className='df-col'
					style={{ marginTop: '30px' }}>
					<TypographyX
						fontSize={20}
						bold
						toUpper>
						materiaal
					</TypographyX>

					<TypographyX
						fontSize={18}
						style={{ marginTop: '10px' }}
						multiLine>
						{dto.lines.map(l => {
							return (
								<div
									key={l.id}
									style={{
										display: 'flex',
										alignItems: 'start',
									}}>
									<TypographyX fontSize={18}>{l.hasInventoryItem ? l.displayName + '  : ' : InventoryTypeRecord[l.type as InventoryType]}</TypographyX>
									<TypographyX fontSize={18}>{`: ${formatCurrency(l.price)}`}</TypographyX>
								</div>
							);
						})}
						<TypographyX fontSize={18}>Totaal: {formatCurrency(dto.discountedPrice)}</TypographyX>
					</TypographyX>
				</div>
				<div style={{ marginTop: 30 }}>
					<a
						href={`mailto:info@zwerver.be?subject=Reservatienummer: ${dto.number} aanpassing`}
						style={{ textDecoration: 'none' }}>
						<TypographyX
							toUpper
							fontSize={18}
							bold
							color='color-green-dark'>
							aanpassen
						</TypographyX>
					</a>
				</div>
			</div>
		</div>
	) : (
		<div>
			{' '}
			<Loader></Loader>
		</div>
	);
};
