import { ButtonProps } from '@material-ui/core';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import React from 'react';

interface IProps extends ButtonProps {
    isLoading?: boolean;
}

export const PrimaryContainedButton = ({ isLoading, ...rest }: IProps) => {
    return (
        <LoaderButton isLoading={isLoading ?? false} color='primary' variant='contained' {...rest}  />
    );
}