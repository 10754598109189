import { HolidayPeriodsCacheProvider } from 'app/admin/holidays/context/HolidayPeriodsCacheProvider';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { requestsQuery_single } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { Period } from './1_Period/Period';
import { PersonalInformation } from './2_PersonalInformation/PersonalInformation';
import { Material } from './3_Material/Material';
import { Overview } from './4_Overview/Overview';
import { Confirmation } from './5_Confirmation/Confirmation';
import { pathToStep } from './utils/pathToStep';

export const Request = () => {
    const { id } = useParams<IRouteParamsWithId>();
    const [request, reload] = useApiEffect(requestsQuery_single, id);
    const { pathname } = useLocation();
    const step = useMemo(() => pathToStep(pathname), [pathname]);
    const { url, path } = useRouteMatch();
    const { push } = useHistory();

    if (request === undefined) {
        return <div></div>
    }

    const onGotoStep = (step: number) => {
        reload();
        push(`${url}/${step}`);
    }

    const onNext = () => {
        onGotoStep(step + 1);
    }

    const onPrevious = () => {
        onGotoStep(step - 1);
    }

    return (
        <HolidayPeriodsCacheProvider>
            <Switch>
                <Route exact path={`${path}/1`} render={() => <Period request={request} onNext={onNext} onGotoStep={onGotoStep} />} />
                <Route exact path={`${path}/2`} render={() => <PersonalInformation request={request} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
                <Route exact path={`${path}/3`} render={() => <Material request={request} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
                <Route exact path={`${path}/4`} render={() => <Overview request={request} onNext={onNext} onPrevious={onPrevious} onGotoStep={onGotoStep} />} />
                <Route exact path={`${path}/5`} render={() => <Confirmation request={request} />} />
                <Redirect to={`${path}/1`} />
            </Switch>
            {/* <div className="df-col h100">
                <Header step={step} />
                <div className="df-row h100" style={{ overflow: 'hidden' }}>
                    <div className="fg1 h100" style={{ padding: 40, paddingBottom: 40, paddingTop: 30, overflowY: 'auto' }}>
                        {step !== 5 && <ProgressBar step={step} />}
                        <Switch>
                            <Route exact path={`${path}/1`} render={() => <Period request={request} onNext={onNext} onGotoStep={onGotoStep} />} />
                            <Route exact path={`${path}/2`} render={() => <PersonalInformation request={request} onNext={onNext} onPrevious={onPrevious} />} />
                            <Route exact path={`${path}/3`} render={() => <Material request={request} onNext={onNext} onPrevious={onPrevious} />} />
                            <Route exact path={`${path}/4`} render={() => <Overview request={request} onNext={onNext} />} />
                            <Route exact path={`${path}/5`} render={() => <Confirmation request={request} />} />
                            <Redirect to={`${path}/1`} />
                        </Switch>
                    </div>
                    {step < 4 && <SideBar request={request} onGotoStep={onGotoStep} />}
                </div>
            </div> */}
        </HolidayPeriodsCacheProvider>
    );
}

