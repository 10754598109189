import React from 'react';
import { ReactComponent } from './logo.svg';

interface IProps  {
    className?: string,
    style?: React.CSSProperties,
    onClick?: () => void,
    width?: number;
}

export const Logo = ({ width = 240, className, style, onClick }: IProps) => {
    // const calcStyle: React.CSSProperties = {
    //     ...style,
    //     width: size === 'large' ? '240px' : size === 'small' ? '60px' : '120px', 
    //     height: size === 'large' ? '240px' : size === 'small' ? '60px' : '120px', 
    //     width: `${width}px`
    // }
    return (
        <ReactComponent className={className} style={{...style, width: `${width}px`, height: `${width}px` }} onClick={onClick} />
    );
}