import { Dialog, DialogContent, DialogProps, Divider } from "@material-ui/core";
import { Loader } from "app/loader/Loader";
import { Form, Formik, FormikHelpers } from "formik";
import { DialogTitleWithFormStepper } from "framework/dialogs/DialogTitleWithFormStepper";
import { FormAutocompleteFreeSoloTextField } from "framework/forms/FormAutocompleteFreeSoloTextField";
import { FormIntegerFieldOutlined } from "framework/forms/FormIntegerFieldOutlined";
import { FormNumberFieldOutlined } from "framework/forms/FormNumberFieldOutlined";
import { PageableFormDialogActions } from "framework/forms/PageableFormDialogActions";
import { handleFormResponse } from "framework/forms/utils/handleFormResponse";
import { useCacheContext } from "framework/hooks/useCacheContext";
import { useFormSubmit } from "framework/hooks/useFormSubmit";
import {
  IInventoryItemModel,
  inventoryItemsCommand_new, InventoryType
} from "gen/ApiClient";
import React, { useState } from "react";
import { FormOptionsField } from "shared/forms/FormOptionsField";
import { InventoryTypeRecord } from "shared/records/InventoryTypeRecord";
import * as yup from "yup";
import { TaxonomiesCacheContext } from "./context/TaxonomiesCacheContext";
import { hasLength } from "./utils/hasLength";
import { hasSize } from "./utils/hasSize";

const schema = yup.object<IInventoryItemModel>({
  type: yup.string().required(),
  brand: yup.string().required(),
  model: yup.string().required(),
  stockLocation: yup.string().required(),
  lengthInCm: yup.number().required(),

  // .when('type', (type: string, schema: any) => type !== undefined && hasLength(type) ? yup.number().required() : undefined),
  size: yup.number().required(),
  // .when('type', (type: string, schema: any) => type !== undefined && hasSize(type) ? yup.number().required() : undefined),
});

const EmptyValues: IInventoryItemModel = {
  type: "",
  brand: "",
  model: "",
  stockLocation: "",
  lengthInCm: 0,
  size: 0,
};

const stepsRecord: Record<number, (keyof IInventoryItemModel)[]> = {
  0: ["type"],
  1: ["brand", "model", "stockLocation", "lengthInCm", "size"],
};

interface IProps extends DialogProps {
  cancel: () => void;
  confirm: (id: string) => void;
}

export const InventoryItemForm = ({ cancel, confirm, ...rest }: IProps) => {
  const [step, setStep] = useState<number>(0);
  const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_new);
  const [data] = useCacheContext(TaxonomiesCacheContext);

  const handleSubmit = async (
    values: IInventoryItemModel,
    helpers: FormikHelpers<IInventoryItemModel>
  ) => {
    const r = await submit(values);
    if (handleFormResponse(r, helpers)) {
      confirm(r.result.objectId);
    }
  };
  if (data === undefined) {
    return <Loader></Loader>;
  }
  console.table(data);
  console.log("brands", data.brands);

  return (
    <Formik<IInventoryItemModel>
      validateOnMount
      initialValues={EmptyValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <Dialog {...rest} scroll="paper" maxWidth="xl">
            <DialogTitleWithFormStepper
              title={"Nieuw artikel"}
              step={step}
              labels={["Type", "Eigenschappen"]}
            />
            <DialogContent dividers className="df-col">
              {step === 0 && (
                <>
                  <FormOptionsField<IInventoryItemModel, InventoryType>
                    name="type"
                    label="Type"
                    record={InventoryTypeRecord}
                    options={["SkiJunior", "SnowboardJunior"]}
                    hideLabel
                  />
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <FormOptionsField<IInventoryItemModel, InventoryType>
                    name="type"
                    label=""
                    record={InventoryTypeRecord}
                    options={["SkiComfort", "SkiVip", "SkiTour", "Snowboard"]}
                    hideLabel
                  />
                  <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                  <FormOptionsField<IInventoryItemModel, InventoryType>
                    name="type"
                    label=""
                    record={InventoryTypeRecord}
                    options={["SkiBoot", "SkiBootTour", "SnowboardBoot"]}
                    hideLabel
                  />
                </>
              )}
              {step === 1 && (
                <>
                  <FormAutocompleteFreeSoloTextField<IInventoryItemModel>
                    name="brand"
                    label="Merk"
                    required
                    options={data.brands}
                  />
                  <FormAutocompleteFreeSoloTextField<IInventoryItemModel>
                    name="model"
                    label="Model"
                    required
                    options={data.models}
                  />
                  <FormAutocompleteFreeSoloTextField<IInventoryItemModel>
                    name="stockLocation"
                    label="Stock-locatie"
                    required
                    options={[]}
                  />
                  {hasLength(props.values.type as InventoryType) && (
                    <FormNumberFieldOutlined<IInventoryItemModel>
                      name="lengthInCm"
                      suffix=" cm"
                      label="Lengte (in cm)"
                      required
                    />
                  )}
                  {hasSize(props.values.type as InventoryType) && (
                    <FormIntegerFieldOutlined<IInventoryItemModel>
                      name="size"
                      label="Maat"
                      required
                    />
                  )}
                </>
              )}
            </DialogContent>
            <PageableFormDialogActions
              step={step}
              setStep={setStep}
              cancel={cancel}
              isSubmitting={isSubmitting}
              submitText={"Voeg toe"}
              schema={schema}
              stepsRecord={stepsRecord}
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};
