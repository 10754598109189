import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider } from 'notistack';
import React from 'react';

//https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
export const SnackbarWrapper = ({ children }: any) => {
    const notistackRef = React.createRef<any>();

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            action={(key) => (
                <IconButton onClick={() => notistackRef.current.closeSnackbar(key)}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            )}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            {children}
        </SnackbarProvider>
    )
}