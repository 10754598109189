import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TaxonomiesCacheContext } from 'app/admin/inventory/context/TaxonomiesCacheContext';
import { FilterBar } from 'framework/filter/FilterBar';
import { NumberListFilterItemWithMinMax } from 'framework/filter/NumberListFilterItemWithMinMax';
import { RecordListFilterItem } from 'framework/filter/RecordListFilterItem';
import { StringListFilterItem } from 'framework/filter/StringListFilterItem';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IAvailableInventoryItemsQueryParams, InventoryItemReservationStatus, InventoryItemReservationStatuses, InventoryType } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { InventoryItemReservationStatusRecord } from 'shared/records/InventoryItemReservationStatusRecord';

const exclusions: (keyof IAvailableInventoryItemsQueryParams)[] = ['isShowAll'];

interface IProps {
    params: IAvailableInventoryItemsQueryParams;
    onFilter: (params: IAvailableInventoryItemsQueryParams) => void;
    style?: React.CSSProperties;
    type: string;
}

export const Filter = ({ params, onFilter, type, style }: IProps) => {
    const [data] = useCacheContext(TaxonomiesCacheContext);
    const xType = useMemo(() => type as InventoryType, [type]);

    if (data === undefined) {
        return <div></div>
    }

    return (
        <FilterBar
            onClear={() => onFilter(clearParams(params, exclusions))}
            isFiltered={isFiltered(params, exclusions)}
            style={style}>
            <div className="df-row-ac df-col-if-mobile">
                <StringListFilterItem
                    icon={<HelpOutlineIcon />}
                    label="Merk"
                    options={data.brands}
                    selected={params.brands}
                    setSelected={(val) => onFilter({ ...params, brands: val })} />
                <StringListFilterItem
                    icon={<HelpOutlineIcon />}
                    label="Model"
                    options={data.models}
                    selected={params.models}
                    setSelected={(val) => onFilter({ ...params, models: val })} />
                <RecordListFilterItem<InventoryItemReservationStatus>
                    icon={<HelpOutlineIcon />}
                    label="Reservatie status"
                    record={InventoryItemReservationStatusRecord}
                    options={InventoryItemReservationStatuses}
                    selected={params.reservationStatuses as any}
                    setSelected={(val) => onFilter({ ...params, reservationStatuses: val })} />
                {xType === 'SkiBoot' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Maat bot"
                        options={data.skiBootSizes}
                        selected={params.sizes}
                        setSelected={(val) => onFilter({ ...params, sizes: val })} />}
                {xType === 'SnowboardBoot' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Maat snowboard-bot"
                        options={data.snowboardBootSizes}
                        selected={params.sizes}
                        setSelected={(val) => onFilter({ ...params, sizes: val })} />}
                {xType === 'SkiBootTour' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Maat bot"
                        options={data.skiTourBootSizes}
                        selected={params.sizes}
                        setSelected={(val) => onFilter({ ...params, sizes: val })} />}
                {xType === 'SkiTour' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiTourLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {xType === 'SkiComfort' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiComfortLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {xType === 'SkiVip' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiVipLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {xType === 'SkiJunior' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiJuniorLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {xType === 'Snowboard' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte snowboard"
                        options={data.snowboardLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {xType === 'SnowboardJunior' &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte snowboard"
                        options={data.snowboardJuniorLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
            </div>
        </FilterBar>
    );
}