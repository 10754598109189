import { createStyles, Drawer, makeStyles } from "@material-ui/core";
import { ReserVationAnalysis } from "app/analysis/ReservationAnalysis";

import { AppAuthenticatedWall } from "app/AppAuthenticatedWall";
import { LoggedInUserProvider } from "app/auth/LoggedInUserProvider";
import { DrawerWidth } from "keys";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as routes from "routes";
import { CustomersList } from "./customers/CustomersList";
import { CustomerDetail } from "./customers/detail/CustomerDetail";
import { Dashboard } from "./dashboard/Dashboard";
import { HolidayPeriodsList } from "./holidays/HolidayPeriodsList";
import { InventoryItemDetail } from "./inventory/detail/InventoryItemDetail";
import { InventoryItemsList } from "./inventory/InventoryItemsList";
import { ReservationDetail } from "./reservations/detail/ReservationDetail";
import { ReservationsList } from "./reservations/ReservationsList";
import { SideNav } from "./sideNav/SideNav";
import { UsersList } from "./users/UsersList";

const contentStyle: React.CSSProperties = {
  height: "calc(100% - 64px)",
  marginLeft: DrawerWidth,
};

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      width: DrawerWidth,
    },
  })
);

export const AdminWrapper = () => {
  const classes = useStyles();

  return (
    <AppAuthenticatedWall>
      <LoggedInUserProvider>
        {/* <AdminProviders> */}
        <div className="fg1 df-col stretch-ver">
          <Drawer
            anchor="left"
            variant="persistent"
            open
            style={{ width: DrawerWidth }}
            classes={{ paper: classes.drawerPaper }}
          >
            <SideNav />
          </Drawer>
          <main style={contentStyle} className="df-col">
            <Switch>
              <Route exact path={routes.DashboardRoute} component={Dashboard} />
              <Route
                exact
                path={routes.ReservationsRoute}
                component={ReservationsList}
              />
              <Route
                exact
                path={routes.ReservationDetailRoute}
                component={ReservationDetail}
              />
              <Route
                exact
                path={routes.HolidayPeriodsRoute}
                component={HolidayPeriodsList}
              />
              <Route
                exact
                path={routes.InventoryItemsRoute}
                component={InventoryItemsList}
              />
              <Route
                exact
                path={routes.InventoryItemDetailRoute}
                component={InventoryItemDetail}
              />
              <Route
                exact
                path={routes.CustomersRoute}
                component={CustomersList}
              />
              <Route
                exact
                path={routes.analysis}
                component={ReserVationAnalysis}
              />
              <Route
                exact
                path={routes.CustomerDetailRoute}
                component={CustomerDetail}
              />
              <Route exact path={routes.UsersRoute} component={UsersList} />
              <Redirect path="/" to={routes.DashboardRoute} />
            </Switch>
          </main>
        </div>
        {/* </AdminProviders> */}
      </LoggedInUserProvider>
    </AppAuthenticatedWall>
  );
};
