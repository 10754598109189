import { ICacheContext } from "framework/context/ICacheContext";
import { Try } from "framework/Try";
import { useContext, useEffect } from "react";

export function useCacheContext<T>(context: React.Context<ICacheContext<T>>): [T | undefined, () => Promise<Try<T>>] {
    const { data, init, load } = useContext(context);

    useEffect(() => {
        init();
    }, [init]);

    return [data, load]
}