import { ApiContext } from "framework/context/ApiContext";
import { useApiErrorHandling } from "framework/hooks/useApiErrorHandling";
import { Try } from "framework/Try";
import { openPdfInNewTabFileResponseInterceptor } from "framework/xhr/openPdfInNewTabFileResponseInterceptor";
import { useCallback, useContext, useState } from "react";
import { IFileResponse } from "./ApiClient";
import { DefaultAxiosInstance } from "./DefaultAxiosInstance";

export function useDownloadOpenPdfInNewTab<TArgs extends any[]>(callback: (...args: TArgs) => Promise<Try<IFileResponse>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>):
    [(...args: TArgs) => Promise<Try<IFileResponse>>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {

    const [isLoading, setInternalLoading] = useState<boolean>(false);
    const apiContext = useContext(ApiContext);
    const handleError = useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.notify);
    const cbSetIsLoading = useCallback(setIsLoading ?? setInternalLoading, [setIsLoading]);

    return [useCallback(async (...args: TArgs) => {
        cbSetIsLoading(true);
        const instance = DefaultAxiosInstance();
        instance.interceptors.response.use(openPdfInNewTabFileResponseInterceptor);
        const newArgs = [...args, instance];
        const r = await callback(...newArgs as any);
        if (r.isFailure) {
            handleError(r.error);
        }
        cbSetIsLoading(false);
        return r;
        // missing deps handleError -> is a function
        // eslint-disable-next-line
    }, [callback, cbSetIsLoading]), isLoading, cbSetIsLoading];
}
