import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { FormDatePickerOutlined } from "framework/forms/FormDatePickerOutlined";
import { handleFormResponse } from "framework/forms/utils/handleFormResponse";
import { setFormValue } from "framework/forms/utils/setFormValue";
import { useCacheContext } from "framework/hooks/useCacheContext";
import { useFormSubmit } from "framework/hooks/useFormSubmit";
import {
  IHolidayPeriod,
  IPeriodModel,
  IRequest,
  requestsCommand_updatePeriod,
} from "gen/ApiClient";
import React from "react";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";
import { TypographyX } from "shared/TypographyX";
import * as yup from "yup";
import { HolidayPeriodsCacheContext } from "../../admin/holidays/context/HolidayPeriodsCacheContext";
import { Page } from "../Page";

const schema = yup.object<IPeriodModel>({
  fromDate: yup.date().required("Selecteer VAN datum"),
  toDate: yup
    .date()
    .when("from", (from: any, schema: any) =>
      from !== undefined
        ? yup.date().min(from, "TOT datum kan niet vóór VAN datum")
        : undefined
    )
    .required("Selecteer TOT datum"),
});

const EmptyValues: IPeriodModel = {
  fromDate: undefined as any,
  toDate: undefined as any,
};

interface IProps {
  request: IRequest;
  onNext: VoidFunction;
  onGotoStep: (step: number) => void;
}

export const Period = ({ request, onNext, onGotoStep }: IProps) => {
  const [holidayPeriods] = useCacheContext(HolidayPeriodsCacheContext);
  const [update, isSubmitting] = useFormSubmit(requestsCommand_updatePeriod);

  if (holidayPeriods === undefined) {
    return <div></div>;
  }

  const handleSubmit = async (
    values: IPeriodModel,
    helpers: FormikHelpers<IPeriodModel>
  ) => {
    const r = await update(request.id, values);
    if (handleFormResponse(r, helpers)) {
      onNext();
    }
  };

  const onSelectHolidayPeriod = (
    hp: IHolidayPeriod,
    props: FormikProps<IPeriodModel>
  ) => {
    setFormValue<IPeriodModel>("fromDate", hp.fromDate, props);
    setFormValue<IPeriodModel>("toDate", hp.toDate, props);
  };

  return (
    <Formik<IPeriodModel>
      validateOnMount
      initialValues={request.period ?? EmptyValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form className="v100 h100">
          <Page onGotoStep={onGotoStep} step={1} item={request}>
            <TypographyX
              fontSize={28}
              bold
              toUpper
              style={{ marginBottom: 30 }}
            >
              welke periode wil je materiaal huren?
            </TypographyX>
            <div className="df-row">
              <FormDatePickerOutlined<IPeriodModel>
                name="fromDate"
                label="VAN"
                disablePast={true}
                required
                style={{ marginRight: 8, width: 230 }}
              />
              <FormDatePickerOutlined<IPeriodModel>
                name="toDate"
                label="TOT"
                required
                disablePast={true}
                style={{ marginLeft: 8, width: 230 }}
              />
            </div>
            <TypographyX
              fontSize={24}
              bold
              toUpper
              style={{ marginTop: "40px" }}
            >
              vakantieperiodes
            </TypographyX>
            {holidayPeriods.map((t) => (
              <TypographyX
                fontSize={20}
                key={t.id}
                style={{ marginTop: 20, cursor: "pointer" }}
                onClick={() => onSelectHolidayPeriod(t, props)}
              >
                {t.formatted}
              </TypographyX>
            ))}
            <div className="fg1" style={{ marginTop: 30 }}></div>
            <div className="df-row-ac jc-sb">
              <div></div>
              <PrimaryContainedButton
                isLoading={isSubmitting}
                onClick={() => props.submitForm()}
              >
                Volgende stap
              </PrimaryContainedButton>
            </div>
          </Page>
        </Form>
      )}
    </Formik>
  );
};
