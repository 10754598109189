import React from 'react';
import { OvalDoneIcon } from 'shared/icons/OvalDoneIcon';
import { OvalEmptyIcon } from 'shared/icons/OvalEmptyIcon';
import { OvalSelectedIcon } from 'shared/icons/OvalSelectedIcon';

interface IProps {
    text: string;
    isFirst?: boolean;
    isLast?: boolean;
    actualStep: number;
    forStep: number;
}
export const ProgressStep = ({ text, actualStep, forStep, isFirst = false, isLast = false }: IProps) => {
    const textStyle: React.CSSProperties = {
        textTransform: 'uppercase',
        fontSize: '18px',
        fontFamily: 'var(--bold-font-family)',
        letterSpacing: '0.64px',
        color: actualStep === forStep ? 'var(--color-black)' : 'var(--color-grey-dark)',
        marginBottom: '10px',
    }

    return (
        <div style={{ width: '30px' }} className={isFirst ? 'df-col' : isLast ? 'df-col-ae' : 'df-col-ac'}>
            <div style={textStyle}>{text}</div>
            <div style={{ marginLeft: isLast ? 'auto' : '', height: '30px' }}>
                {actualStep > forStep && <OvalDoneIcon />}
                {actualStep === forStep && <OvalSelectedIcon />}
                {actualStep < forStep && <OvalEmptyIcon />}
            </div>
        </div>
    );
}
