import { Popover, TextField } from "@material-ui/core";
import { StaticDatePicker } from "@material-ui/pickers";
import React from "react";
import { mapToIsoOnlyDate } from "../../utils/date/mapToIsoOnlyDate";
import { DefaultDateFormat } from "../../utils/date/DefaultDateFormat";
import { DatePickerViewType } from "./DatePickerViewType";

interface IProps {
  anchor: HTMLElement | null;
  close: VoidFunction;
  value: Date | undefined | null;
  setValue: (date: Date | null) => void;
  openTo?: DatePickerViewType;
  views?: DatePickerViewType[];
  disablePast?: boolean;
}

export const StaticDatePickerPopover = ({
  anchor,
  close,
  value,
  setValue,
  openTo = "date",
  views = ["year", "month", "date"],
  disablePast,
}: IProps) => {
  return (
    <Popover
      anchorEl={anchor}
      open
      onClose={close}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
    >
      <StaticDatePicker
        disablePast={disablePast}
        displayStaticWrapperAs="desktop"
        openTo={openTo}
        views={views}
        value={value === undefined ? null : value}
        onChange={(date) => {
          setValue(mapToIsoOnlyDate(date));
          close();
        }}
        inputFormat={DefaultDateFormat}
        renderInput={(props) => <TextField {...props} />}
      />
    </Popover>
  );
};
