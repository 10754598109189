import { Theme } from "@material-ui/core";
import { Color } from "@material-ui/lab";

export const toColor = (color: Color, theme: Theme): string => {
    if (color === 'success') {
        return theme.palette.success.main;
    } else if (color === 'warning') {
        return theme.palette.warning.main;
    } else if (color === 'error') {
        return theme.palette.error.main;
    } else {
        return theme.palette.grey[600];
    }
}