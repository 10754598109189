// import { ReactComponent } from './skis.svg';
// import React from 'react';
// import { IIconProps } from './IIconProps';

// export const SkisIcon = ({ ...props }: IIconProps) => {
//     return <ReactComponent {...props} />
// }


import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SkisIcon = ({ viewBox = "0 0 126 189", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <polygon id="Path" points="52.34 59.56 57.27 47.25 82.72 39.71 77.78 52.01"></polygon>
            <path d="M91.51,17.8 L85.51,32.67 L60.1,40.21 L72.2,10 C78.57,-5.78 97.87,2 91.51,17.8 Z" id="Path"></path>
            <polygon id="Path" points="74.96 59.05 23.12 188.31 3.82 180.53 49.52 66.59"></polygon>
            <polygon id="Path" points="22.46 68.12 17.25 55.93 29.91 32.6 35.12 44.79"></polygon>
            <path d="M20.63,10.9 L26.92,25.63 L14.27,49 L1.48,19.05 C-5.2,3.37 14,-4.78 20.63,10.9 Z" id="Path"></path>
            <polygon id="Path" points="38.1 51.76 92.84 179.82 73.7 187.97 25.44 75.09"></polygon>
            <path d="M118,9.5 C118.359531,13.2085926 118.509769,16.9345002 118.45,20.66 L118.45,31.81 L118.45,54.12 L118.35,98.75 L118.25,143.38 L117.92,188 L116.08,188 L115.83,143.38 L115.73,98.75 L115.63,54.12 L115.63,31.81 L115.63,20.66 C115.543641,16.9360034 115.66717,13.2100988 116,9.5 L118,9.5 Z" id="Path"></path>
            <path d="M121,28 L113,28 C113.85,28 113.85,0 113,0 L121,0 C120.07,0 120.07,28 121,28 Z" id="Path"></path>
            <polygon id="Path" points="116.14 176 108.86 180 125.14 180 117.86 176"></polygon>
            <path d="M115,2 C115.24,1.36 114.73,1 114.05,1 C109.15,0.9 96.2,12.38 100.61,19.39 C105.09,26.6 112.67,8 115,2 Z" id="Path"></path>
        </SvgIcon>
    );
}