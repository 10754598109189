import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { LocalizationProvider as PickerLocalizationProvider } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import nlBE from "date-fns/locale/nl-BE";
import { ApiProvider } from "framework/context/ApiProvider";
import { LoaderProvider } from "framework/context/LoaderProvider";
import { LocalizationProvider } from "framework/context/LocalizationProvider";
import { nlApiErrorStrings } from "framework/localization/apiErrors/nlApiErrorStrings";
import { IFrameworkStrings } from "framework/localization/IFrameworkStrings";
import { PdfViewerDialogProvider } from "framework/pdf/context/PdfViewerDialogProvider";
import { ProtectedRoute } from "framework/ProtectedRoute";
import { languageKey } from "keys";
import { LanguageOption } from "localization/LanguageOption";
import { localizationRecord } from "localization/localizationRecord";
import { SpecificLocalizationContext } from "localization/SpecificLocalizationContext";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  LoginRoute,
  RequestRoute, ReservationStatusDashBoard, WelcomeRoute
} from "routes";
import { DialogsProvider } from "shared/dialogs/DialogsProvider";
import { RecordProvider } from "shared/records/RecordProvider";
import { theme } from "theme";
import { AdminProviders } from "./admin/AdminProviders";
import { AdminWrapper } from "./admin/AdminWrapper";
import { AppUpdateWall } from "./AppUpdateWall";
import { AuthContext } from "./auth/AuthContext";
import { AuthProvider } from "./auth/AuthProvider";
import { Loader } from "./loader/Loader";
import { Login } from "./Login";
import { ReservationStatusScreen } from "./publicReservationStatus/ReservationStatusScreen";
import { Request } from "./requests/Request";
import { SnackbarWrapper } from "./SnackbarWrapper";
import { Test } from "./Test";
import { Welcome } from "./Welcome";

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PickerLocalizationProvider dateAdapter={DateFnsAdapter} locale={nlBE}>
          <SnackbarWrapper>
            <BrowserRouter>
              <LoaderProvider loader={<Loader />}>
                <PdfViewerDialogProvider>
                  <LocalizationProvider<IFrameworkStrings, LanguageOption>
                    map={localizationRecord}
                    context={SpecificLocalizationContext}
                    localStorageKey={languageKey}
                    fallbackValue="nl"
                  >
                    <RecordProvider>
                      <AuthProvider>
                        <AuthContext.Consumer>
                          {({ isAuth, onRedirect }) => (
                            <ApiProvider
                              strings={nlApiErrorStrings}
                              onRedirect={onRedirect}
                            >
                              <AppUpdateWall>
                                <AdminProviders>
                                  <DialogsProvider>
                                    <Switch>
                                      <Route
                                        exact
                                        path={`/test`}
                                        component={Test}
                                      />
                                      <Route
                                        exact
                                        path={WelcomeRoute}
                                        component={Welcome}
                                      />
                                      <Route
                                        exact
                                        path={LoginRoute}
                                        component={Login}
                                      />
                                      <Route
                                        exact
                                        path={ReservationStatusDashBoard}
                                        component={ReservationStatusScreen}
                                      />
                                      <Route
                                        path={RequestRoute}
                                        component={Request}
                                      />
                                      <ProtectedRoute
                                        redirectRoute={LoginRoute}
                                        isAuth={isAuth}
                                        component={AdminWrapper}
                                      />
                                      <Redirect to={WelcomeRoute} />
                                    </Switch>
                                  </DialogsProvider>
                                </AdminProviders>
                                {/* <Switch>
                                  <Route exact path={LoginRoute} component={Login} />
                                  <ProtectedRoute isAuth={isAuth} redirectRoute={LoginRoute} component={AppWrapper} />
                                  <Redirect path="/" to={LoginRoute} />
                                </Switch> */}
                              </AppUpdateWall>
                            </ApiProvider>
                          )}
                        </AuthContext.Consumer>
                      </AuthProvider>
                    </RecordProvider>
                  </LocalizationProvider>
                </PdfViewerDialogProvider>
              </LoaderProvider>
            </BrowserRouter>
          </SnackbarWrapper>
        </PickerLocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
