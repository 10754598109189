import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { holidayPeriodsQuery_concurrencyToken, holidayPeriodsQuery_query, IHolidayPeriod, IHolidayPeriodsQueryParams, IQueryResult } from "gen/ApiClient";
import { holidayPeriodsQueryParamsLsKey } from "localStorageKeys";
import React from "react";
import { QueryContext } from "./QueryContext";

const DefaultParams: IHolidayPeriodsQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
    fromBefore: undefined,
    fromAfter: undefined,
    isUpcoming: undefined
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<IHolidayPeriod>, IHolidayPeriodsQueryParams>
            defaultParams={DefaultParams}
            queryF={holidayPeriodsQuery_query}
            concurrencyF={holidayPeriodsQuery_concurrencyToken}
            localStorageKey={holidayPeriodsQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
