import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { ReturnUrlPrefix } from 'keys';

interface IProps extends RouteProps {
  isAuth: boolean;
  redirectRoute: string;
}

export const ProtectedRoute = ({ component, isAuth, redirectRoute, ...rest }: IProps) => {
  const location = useLocation();

  if (isAuth) {
    return <Route {...rest} component={component} />
  } else {
    return <Redirect to={redirectRoute + `${ReturnUrlPrefix}${location.pathname.substring(1)}`} />
  }
}