import { Checkbox, Divider, ListItemText, ListSubheader, MenuItem, Typography } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { toggleSelect } from 'framework/utils/array/toggleSelect';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';

interface IProps {
    options: number[];
    selected: number[];
    setSelected: (selected: number[]) => void;
    label: string;
    icon: React.ReactNode;
    title?: string;
}

export const NumberListFilterItemWithMinMax = ({ options, selected, setSelected, label, icon, title }: IProps) => {
    const orderedList = useMemo(() => _.orderBy(options, t => t), [options]);
    const strings = useLocalization();
    const [values, setValues] = useState<number[]>(selected); //selection
    // const [filtered, setFiltered] = useState<number[]>(orderedList);
    const [anchor, open, close] = useAnchorElement();
    // const [minValue, setMinValue] = useState<number>();
    // const [maxValue, setMaxValue] = useState<number>();

    useEffect(() => {
        setValues(selected);
        // setMinValue(undefined);
        // setMaxValue(undefined);
    }, [selected]);

    // useEffect(() => {
    //     console.log('use effect');
    //     let x = orderedList;
    //     if (minValue) {
    //         x = x.filter(t => t >= minValue);
    //     }
    //     if (maxValue) {
    //         x = x.filter(t => t <= maxValue);
    //     }
    //     setFiltered(x);
    //     if (minValue || maxValue) {
    //         setValues(x);
    //     }
    // }, [minValue, maxValue, orderedList]);

    const handleClose = () => {
        if (values !== selected) {
            setSelected(values);
        }
        close();
    };

    const handleClickItem = (val: number) => {
        setValues(toggleSelect(val, values));
    }

    return (
        <>
            <FilterButton
                handleOpen={open}
                badgeContent={selected.length}
                label={label}
                icon={icon} />
            <FilterMenu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={handleClose}>
                <ListSubheader style={{ background: common.white }}>
                    {title && <>
                        <Typography align="center" style={{ padding: '8px 0px' }}>{title}</Typography>
                        <Divider />
                    </>}
                    {/* <div className="df-row-ac jc-sb" style={{ marginTop: 16 }}>
                        <NumberTextField
                            value={minValue}
                            onChange={val => setMinValue(val)}
                            label="Min"
                            style={{ marginRight: 12 }} />
                        <NumberTextField
                            value={maxValue}
                            onChange={val => setMaxValue(val)}
                            label="Max" />
                    </div> */}
                    {/* <Divider /> */}
                    <Checkbox
                        indeterminate={values.length > 0 && values.length < options.length}
                        checked={values.length === options.length}
                        disabled={orderedList.length === 0}
                        onChange={() => {
                            values.length > 0 ? setValues([]) : setValues(orderedList)
                        }}
                    />
                    {strings.selectAll}
                    <Divider />
                </ListSubheader>
                {orderedList.map(t =>
                    <MenuItem key={t.toString()} value={t} onClick={() => handleClickItem(t)}>
                        <Checkbox checked={values.indexOf(t) > -1} />
                        <ListItemText primary={t} />
                    </MenuItem>
                )}
            </FilterMenu>
        </>
    );
}

