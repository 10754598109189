export const pathToStep = (path: string): number => {
    if (path.endsWith('/1')) {
        return 1;
    } else if (path.endsWith('/2')) {
        return 2;
    } else if (path.endsWith('/3')) {
        return 3;
    } else if (path.endsWith('/4')) {
        return 4;
    } else if (path.endsWith('/5')) {
        return 5;
    } else {
        return -1;
    }
}