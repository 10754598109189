import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { DimensionType, IInventoryItem, InventoryItemReservationStatus, inventoryItemsCommand_markAsUsable, inventoryItemsCommand_updateStockLocation, InventoryType, UnusableReason } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { InventoryItemReservationStatusRecord } from 'shared/records/InventoryItemReservationStatusRecord';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { UnusableReasonRecord } from 'shared/records/UnusableReasonRecord';
import { HeaderItem } from '../../../../shared/HeaderItem';
import { InventoryItemUpdateForm } from '../InventoryItemUpdateForm';
import { SetUnusableForm } from '../SetUnusableForm';

interface IProps {
    detail: IInventoryItem;
    reload: () => void;
    style?: React.CSSProperties;
}

export const InventoryItemDetailHeader = ({ detail, reload, style }: IProps) => {
    const { open, confirm, cancel } = useDialogsContext(reload);

    const onSetUnusable = () => {
        open(<SetUnusableForm
            open
            item={detail}
            confirm={confirm}
            cancel={cancel} />);
    }

    const onUndoUnusable = () => {
        open(<AlertDialog
            open
            acceptText="Ja, ongedaan maken!"
            rejectText="Annuleer"
            title="Bevestig"
            content={`Is dit artikel toch beschikbaar voor verhuur?`}
            reject={cancel}
            acceptF={() => inventoryItemsCommand_markAsUsable(detail.id)}
            accept={confirm} />);
    }

    const onEdit = () => {
        open(<InventoryItemUpdateForm
            open
            item={detail}
            confirm={confirm}
            cancel={cancel} />);
    }

    const onLocation = () => {
        open(<StringModelForm
            open
            label='Stock locatie'
            formTitle='Wijzig stock locatie'
            submitFunction={val => inventoryItemsCommand_updateStockLocation(detail.id, val)}
            submitText='Wijzig'
            initialValue={detail.stockLocation}
            confirm={confirm}
            cancel={cancel} />)
    }

    return (
        <div className="df-row jc-sb" style={style}>
            <HeaderItem label="type" value={InventoryTypeRecord[detail.type as InventoryType]} />
            {detail.canSelect && <HeaderItem label="status" value={InventoryItemReservationStatusRecord[detail.reservationStatus as InventoryItemReservationStatus]} onEdit={onSetUnusable} />}
            {detail.canSelect === false && <HeaderItem label="onbruikbaar" value={UnusableReasonRecord[detail.unusableReason as UnusableReason]} onEdit={onUndoUnusable} />}
            <HeaderItem label="merk" value={detail.brand} onEdit={onEdit} />
            <HeaderItem label="model" value={detail.model} onEdit={onEdit} />
            <HeaderItem label="stock-locatie" value={detail.stockLocation} onEdit={onLocation} />
            <HeaderItem label="Opkomende res." value={detail.countUpcomingReservations.toString()} />
            {(detail.dimensionType as DimensionType) === 'Size' &&
                <HeaderItem label="maat" value={detail.size} onEdit={onEdit} />}
            {(detail.dimensionType as DimensionType) === 'Length' &&
                <HeaderItem label="lengte" value={detail.lengthInCm + 'cm'} onEdit={onEdit} />}
        </div>
    );
}