// import { ReactComponent } from './snowboard.svg';
// import React from 'react';
// import { IIconProps } from './IIconProps';

// export const SnowboardIcon = ({ ...props }: IIconProps) => {
//     return <ReactComponent {...props} />
// }


import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SnowboardIcon = ({ viewBox = "0 0 66 190", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M1.85,39.25 C6.85,53.18 6.85,135.49 1.85,149.05 C0.796191387,151.925562 0.453114942,155.01325 0.85,158.05 C6.23,200.55 59.98,200.66 65.61,158.38 C65.9883968,155.120072 65.5484765,151.817233 64.33,148.77 C58.9,134.45 58.89,53.99 64.33,39.5 C65.5989205,36.2347393 65.9986185,32.6960343 65.49,29.23 C59.21,-9.77 7.12,-9.77 1.01,29.46 C0.468125719,32.7422789 0.756911111,36.1080039 1.85,39.25 Z M40.47,139.46 L24.23,139.520006 C19.4042473,139.52565 15.4784708,135.635603 15.4399939,130.81 L15.4399939,127.93 C15.4343865,123.100376 19.3304853,119.173001 24.16,119.14 L40.4,119.07992 C46.0277816,119.057909 50.6079086,123.602218 50.6300802,129.23 C50.6520914,134.857782 46.1077816,139.437909 40.48,139.46 L40.47,139.46 Z M40.37,76.2001804 L24.13,76.11 C19.2981832,76.0714834 15.4078079,72.1319193 15.4299055,67.3 L15.4299055,64.43 C15.4793848,59.6011782 19.410929,55.7142599 24.24,55.7199937 L40.48,55.82 C46.1077815,55.8531373 50.6431368,60.4422185 50.6101801,66.07 C50.5768626,71.6977815 45.9877815,76.2331369 40.36,76.2001804 L40.37,76.2001804 Z" id="Shape"></path>
        </SvgIcon>
    );
}