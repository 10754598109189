import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SkiBootIcon = ({ viewBox = "0 0 144 157", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M37.49,5 C37.49,3.1 36.26,1.66 34.44,0.69 L22.19,0 C19.44,0.9 17.42,2.59 17.42,5.05 L8.81,94.85 L8.81,142.16 L23.15,142.16 C19.03,103.81 26.88,61.83 35.15,17.43 L37.49,5 Z" id="Path"></path>
            <path d="M143.52,140.44 C144.18,127.08 136,114.76 114.37,109 L109.72,124.65 C107.72,131.3 98.85,128.65 100.83,122.01 L105.3,107.01 C103.76,106.71 102.17,106.42 100.51,106.15 L91.19,104.93 C89.9314923,104.608153 88.6954958,104.203949 87.49,103.72 L83.22,118 C81.22,124.65 72.36,122 74.33,115.35 L79.33,98.67 C73.07,93.22 69.58,85.37 70.45,77.88 L71.69,72.58 L52.53,72.58 C50.8227122,72.659323 49.210046,71.7936554 48.3326777,70.3269078 C47.4553094,68.8601602 47.4553094,67.0298398 48.3326777,65.5630922 C49.210046,64.0963446 50.8227122,63.230677 52.53,63.31 L73.83,63.31 L79.11,40.79 L52.53,40.79 C50.8227122,40.869323 49.210046,40.0036554 48.3326777,38.5369078 C47.4553094,37.0701602 47.4553094,35.2398398 48.3326777,33.7730922 C49.210046,32.3063446 50.8227122,31.440677 52.53,31.52 L81.28,31.52 L85.14,15.07 C85.4256946,12.5151164 84.6051743,9.96001098 82.8853947,8.04914476 C81.1656151,6.13827854 78.7107583,5.05407602 76.14,5.06982703 L41.53,5.06982703 C40.7166667,9.47 39.9033333,13.84 39.09,18.18 C30.69,63.18 22.75,105.58 27.35,143.93 L27.61,146.15 L4.84,146.15 L4.84,145.49 L0.2,145.49 L0.2,156.09 L36.53,156.09 C48.14,137.56 70.01,145.09 88.07,156.09 L132.4,156.09 C140.49,156.07 143.12,148.52 143.52,140.44 Z" id="Path"></path>
        </SvgIcon>
    );
}