import { Typography } from '@material-ui/core';
import { useField } from 'formik';
import { FormErrorHelper } from 'framework/forms/common/FormErrorHelper';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import React from 'react';
import { SelectOption } from './SelectOption';

interface IProps<TModel, TProp extends string> extends IFormProps<TModel> {
    options: TProp[];
    record: Record<TProp, string>;
    orientation?: 'horizontal' | 'vertical';
    hideLabel?: boolean;
    renderValue?: (option: TProp) => string;
}

export const FormOptionsField = <TModel extends unknown, TProp extends string>({ name, options, record, orientation = 'horizontal', style, hideLabel, renderValue, ...props }: IProps<TModel, TProp>) => {
    const [field, meta, helpers] = useField<string>(nameOf(name));

    return (
        <div className="df-col" style={style}>
            {props.label && hideLabel !== true && <Typography variant="body1">{props.label}</Typography>}
            <div className={orientation === 'horizontal' ? 'df-row-ac' : 'df-col-ac'}>
                {options.map(t =>
                    <SelectOption
                        key={t}
                        text={renderValue ? renderValue(t) : record[t]}
                        isSelected={field.value === t}
                        onSelect={() => {
                            helpers.setTouched(true);
                            helpers.setValue(t);
                        }}
                        style={{ marginRight: 40 }} />
                )}
            </div>
            <FormErrorHelper meta={meta} />
        </div>
    );
};

