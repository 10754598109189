import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IInventoryItem, inventoryItemsCommand_markAsUnusable, UnusableReason, UnusableReasons } from 'gen/ApiClient';
import React from 'react';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { UnusableReasonRecord } from 'shared/records/UnusableReasonRecord';
import { TypographyX } from 'shared/TypographyX';
import * as yup from 'yup';

interface IModel {
    status: string;
}

const schema = yup.object<IModel>().shape<IModel>({
    status: yup.string().required(),
});

const initValues: IModel = {
    status: undefined as any,
}

interface IProps extends DialogProps {
    item: IInventoryItem;
    cancel: () => void;
    confirm: () => void;
}

export const SetUnusableForm = ({ item, cancel, confirm, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(inventoryItemsCommand_markAsUnusable);
    const notify = useSnackbarNotify();

    const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
        const r = await submit(item.id, values.status);
        handleValidateResponseBase(r, e => notify(e, 'error'), () => confirm());
    }

    return (
        <Formik<IModel>
            validateOnMount
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    {...rest}
                    scroll="paper"
                    maxWidth="sm"
                    fullWidth>
                    <DialogTitle>Stel onbruikbaar in</DialogTitle>
                    <DialogContent
                        dividers
                        className="df-col">
                        {item.countUpcomingReservations > 0 &&
                            <TypographyX fontSize={20} color="color-error" bold>Opgelet: Voor {item.countUpcomingReservations} reservatie(s) moet je een ander item zoeken.</TypographyX>}
                        <FormOptionsField<IModel, UnusableReason>
                            name="status"
                            label="REDEN"
                            options={UnusableReasons}
                            record={UnusableReasonRecord}
                            style={{ marginTop: 30 }} />
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        cancel={cancel}
                        isSubmitting={isSubmitting}
                        submitText={"Wijzig"} />
                </Dialog>
            </Form>
        </Formik>
    );
}