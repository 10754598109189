import { IconButton } from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { PrintPlugin, RenderPrintProps } from '@react-pdf-viewer/print';
import React from 'react';

interface IProps {
    plugin: PrintPlugin;
}

export const PrintComponent = ({ plugin }: IProps) => {
    const { Print } = plugin;

    return (
        <Print>
            {(props: RenderPrintProps) => (
                <div className="df-row-ac">
                    <IconButton color="inherit" onClick={props.onClick}>
                        <PrintOutlinedIcon />
                    </IconButton>
                </div>
            )}
        </Print>
    );
}
