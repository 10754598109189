import { IconButton, InputBase, Paper } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { useEnterKeyDown } from '../hooks/useEnterKeyDown';
import { combineClassNames } from '../utils/combineClassNames';
import { ISearchbarProps } from './ISearchbarProps';

interface IProps extends ISearchbarProps {
    value: string;
    onSearch: (value: string) => void;
    children?: any;
}

export const Searchbar = ({ value, onSearch, placeholder, style, className, variant, showClearButton = true, paperElevation, children }: IProps) => {
    const [text, setText] = useState<string>(value);
    const handleKeyDown = useEnterKeyDown(() => onSearch(text));
    const isFiltered = Boolean(text !== undefined && text !== null && text !== '');
    const isTextSet = Boolean(text === value);
    const initializeRef = React.useRef<boolean>(false);

    useEffect(() => {
        if (text !== value) {
            setText(value);
        }
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (initializeRef.current) {
            const timeoutId = setTimeout(() => onSearch(text), text === '' ? 0 : 500);
            return () => clearTimeout(timeoutId);
        } else {
            initializeRef.current = true;
        }
        // eslint-disable-next-line
    }, [text]);

    const renderInner = () => {
        return (
            <>
                <IconButton
                    type="submit"
                    onClick={() => onSearch(text)}>
                    <SearchIcon color={isTextSet ? 'action' : 'error'} />
                </IconButton>
                <InputBase
                    placeholder={placeholder}
                    style={{ width: variant === 'paper' ? '100%' : 'inherit' }}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    onKeyDown={handleKeyDown} />
                {children && children}
                {isFiltered && showClearButton &&
                    <IconButton onClick={() => setText('')} edge="end">
                        <ClearIcon />
                    </IconButton>
                }
            </>
        );
    }


    return (
        <>
            {variant === 'paper' &&
                <Paper className={combineClassNames("df-row-ac", className)} style={{ paddingRight: 12, ...style }} elevation={paperElevation}>
                    {renderInner()}
                </Paper>
            }
            {variant === 'embedded' &&
                <div className={combineClassNames("df-row-ac", className)} style={{ paddingRight: 12, ...style }}>
                    {renderInner()}
                </div>
            }
        </>

    );
}