import React from 'react';
import { IOption } from '../IOption';
import { ListFilterItem } from './ListFilterItem';

interface IProps<T extends string | number | symbol> {
    options: T[];
    record: Record<T, string>
    selected: T[];
    setSelected: (selected: T[]) => void;
    label: string;
    icon: React.ReactNode;
    title?: string;
    orderRecord?: Record<T, number>;
    style?: React.CSSProperties;
}

export const RecordListFilterItem = <T extends string | number | symbol>({ options, record, ...rest }: IProps<T>) => {
    return <ListFilterItem<T>
        {...rest}
        options={options.map<IOption<T>>(t => ({ id: t, identifier: record[t]}))} />
}

