import React from 'react';
import { ReservationsQueryProvider } from './ReservationsQueryProvider';
import { ReservationsViewProvider } from './ReservationsViewProvider';

export const ReservationsProviders = ({ children }: any) => {
    return (
        <ReservationsQueryProvider>
            <ReservationsViewProvider>
                {children}
            </ReservationsViewProvider>
        </ReservationsQueryProvider>
    );
}