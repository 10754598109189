import { AuthContext } from "app/auth/AuthContext";
import { useSnackbarNotify } from "framework/hooks/useSnackbarNotify";
import React, { useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import * as routes from "../../../routes";
import { Logo } from "../../logo/Logo";

const style: React.CSSProperties = {
  backgroundColor: "var(--color-white)",
  padding: "20px 20px 20px 0px",
};

export const SideNav = () => {
  const authContext = useContext(AuthContext);
  const { push } = useHistory();
  const notify = useSnackbarNotify();

  const logout = async () => {
    authContext.logout();
    notify("Afgemeld!", "success");
    push(routes.WelcomeRoute);
  };

  return (
    <div style={style} className="df-col side-nav stretch-ver jc-sb">
      <div className="df-col">
        <Logo width={120} style={{ paddingLeft: "20px" }} />
        <NavLink
          className="nav-link"
          to={routes.DashboardRoute}
          style={{ marginTop: "30px" }}
        >
          Dashboard
        </NavLink>
        <NavLink className="nav-link" to={routes.ReservationsRoute}>
          Reservaties
        </NavLink>
        <NavLink className="nav-link" to={routes.HolidayPeriodsRoute}>
          Vakantieperiodes
        </NavLink>
        <NavLink className="nav-link" to={routes.InventoryItemsRoute}>
          Inventaris
        </NavLink>
        <NavLink className="nav-link" to={routes.CustomersRoute}>
          Personen
        </NavLink>
      </div>
      <div className="df-col">
        <NavLink className="nav-link" to={routes.analysis}>
          Analyse
        </NavLink>
        <NavLink className="nav-link" to={routes.UsersRoute}>
          Gebruikers
        </NavLink>
        <div className="nav-link" onClick={() => logout()}>
          Afmelden
        </div>
      </div>
    </div>
  );
};
