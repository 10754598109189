import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { FormNumberFieldOutlined } from 'framework/forms/FormNumberFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { IMaterialModel, InventoryType, IRequest, IVipComfortTourSkiPrice, LevelType, LevelTypes, requestsCommand_updateMaterial, requestsQuery_prices } from 'gen/ApiClient';
import React from 'react';
import { InfoIconButtonWithoutPadding } from 'shared/buttons/InfoIconButtonWithoutPadding';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { FormBooleanOptionsField } from 'shared/forms/FormBooleanOptionsField';
import { FormGridContainer } from 'shared/forms/FormGridContainer';
import { FormGridItem } from 'shared/forms/FormGridItem';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { LevelTypeRecord } from 'shared/records/LevelRecord';
import { TypographyX } from 'shared/TypographyX';
import * as yup from 'yup';
import { BackButton } from '../BackButton';
import { Page } from '../Page';
import { JuniorInformationDialog } from './JuniorInformationDialog';
import { LevelInformationDialog } from './LevelInformationDialog';
import { VipComfortOrTourInformationDialog } from './VipComfortOrTourInformationDialog';

const Schema = yup.object<IMaterialModel>({
    lengthInCm: yup.number().required(`Vul je lengte in`),
    weightInKg: yup.number().required(`Vul je gewicht in`),
    isSki: yup.bool().required(),
    isSnowboardRightFootInFront: yup.bool(),
    hasOwnBoots: yup.bool().required(),
    level: yup.string().required(),
    skiInventoryType: yup.string(),
});

const EmptyValues: IMaterialModel = {
    lengthInCm: undefined as any,
    weightInKg: undefined as any,
    isSki: undefined as any,
    isSnowboardRightFootInFront: undefined as any,
    hasOwnBoots: undefined as any,
    level: undefined as any,
    skiInventoryType: '',
}

const skiInventoryTypeToPrice = (inventoryType: InventoryType, prices: IVipComfortTourSkiPrice) => {
    if (inventoryType === 'SkiVip') {
        return prices.vipValue;
    } else if (inventoryType === 'SkiTour') {
        return prices.tourValue;
    } else {
        return prices.comfortValue;
    }
}

export const weightInfo = "Geef je standaard gewicht.\nEr moet geen rekening gehouden worden\nmet skikledij -en schoenen";

interface IProps {
    request: IRequest;
    onNext: VoidFunction;
    onPrevious: VoidFunction;
    onGotoStep: (step: number) => void;
}

export const Material = ({ request, onNext, ...rest }: IProps) => {
    const [prices] = useApiEffect(requestsQuery_prices, request.id);
    const [submit, isSubmitting] = useFormSubmit(requestsCommand_updateMaterial);

    if (prices === undefined) {
        return <div></div>
    }

    const handleSubmit = async (values: IMaterialModel, helpers: FormikHelpers<IMaterialModel>) => {
        if (values.isSki && request.isJunior === false && isNullOrUndefined(values.skiInventoryType)) {
            setFieldError<IMaterialModel>('skiInventoryType', 'Selecteer VIP, Comfort of Toer SKI', helpers);
        } else if (values.isSki === false && values.isSnowboardRightFootInFront === undefined) {
            setFieldError<IMaterialModel>('isSnowboardRightFootInFront', 'Selecteer Links of Rechts', helpers);
        } else if (values.weightInKg > 200) {
            setFieldError<IMaterialModel>('weightInKg', "Controleer gewicht (max. 200kg)", helpers);
        } else if (values.lengthInCm > 230) {
            setFieldError<IMaterialModel>('lengthInCm', 'Controleer lengte (max. 230cm)', helpers)
        } else {
            const r = await submit(request.id, values);
            if (handleFormResponse(r, helpers)) {
                onNext();
            }
        }
    }

    return (
        <Formik<IMaterialModel>
            validateOnMount
            initialValues={request.material ?? EmptyValues}
            validationSchema={Schema}
            onSubmit={handleSubmit}>
            <InnerForm
                {...rest}
                isSubmitting={isSubmitting}
                prices={prices}
                request={request} />
        </Formik>
    );
}

interface IInnerFormProps {
    isSubmitting: boolean;
    prices: IVipComfortTourSkiPrice;
    onPrevious: VoidFunction;
    onGotoStep: (step: number) => void;
    request: IRequest;
}

const InnerForm = ({ isSubmitting, prices, onPrevious, onGotoStep, request }: IInnerFormProps) => {
    const { open, confirm } = useDialogsContext();
    const props = useFormikContext<IMaterialModel>();

    const onLevelInfo = () => {
        open(<LevelInformationDialog
            open
            close={confirm} />)
    }

    const onWeightInfo = () => {
        open(<NotificationDialog
            open
            close={confirm}
            title="Gewicht"
            content={`Geef je standaard gewicht.\nEr moet geen rekening gehouden worden\nmet skikledij -en schoenen`} />);
    }

    const onVipComfortOrTourInfo = () => {
        open(<VipComfortOrTourInformationDialog
            open
            close={confirm} />)
    }

    const onJuniorInfo = () => {
        open(<JuniorInformationDialog
            open
            close={confirm} />);
    }

    return (
        <Form className="v100 h100" autoComplete="off">
            <Page step={3} onGotoStep={onGotoStep} item={request}>
                <div className='df-row-ac' style={{ height: 48 }}>
                    <TypographyX fontSize={24} bold toUpper>geef lengte en gewicht</TypographyX>
                    <InfoIconButtonWithoutPadding onClick={onWeightInfo} />
                </div>
                <FormGridContainer>
                    <FormGridItem>
                        <FormNumberFieldOutlined<IMaterialModel>
                            label="LENGTE (CM)"
                            name="lengthInCm"
                            suffix="cm"
                            required />
                    </FormGridItem>
                    <FormGridItem>
                        <FormNumberFieldOutlined<IMaterialModel>
                            label="GEWICHT (KG)"
                            name="weightInKg"
                            suffix="kg"
                            required />
                    </FormGridItem>
                </FormGridContainer>
                <div className="df-row" style={{ marginTop: 30 }}>
                    <div className="df-row-ac">
                        <div className="df-col" style={{ marginRight: 40 }}>
                            <div className='df-row-ac' style={{ marginBottom: 8 }}>
                                <TypographyX fontSize={24} bold toUpper>ski of snowboard</TypographyX>
                                {request.isJunior === true && <InfoIconButtonWithoutPadding onClick={onJuniorInfo} />}
                            </div>
                            <FormBooleanOptionsField<IMaterialModel>
                                name="isSki"
                                trueText="ski"
                                falseText="snowboard"
                                label="ski of snowboard" />
                        </div>
                        {props.values.isSki === true && request.isJunior === false &&
                            <div className="df-col">
                                <div className="df-row-ac">
                                    <TypographyX fontSize={24} bold toUpper style={{ marginBottom: 8 }}>comfort, vip of toer?</TypographyX>
                                    <InfoIconButtonWithoutPadding onClick={onVipComfortOrTourInfo} />
                                </div>
                                <FormOptionsField<IMaterialModel, InventoryType>
                                    options={['SkiVip', 'SkiComfort', 'SkiTour']}
                                    label='Vip, comfort of toer'
                                    name="skiInventoryType"
                                    record={InventoryTypeRecord}
                                    renderValue={option => `${InventoryTypeRecord[option]} (${formatCurrency(skiInventoryTypeToPrice(option, prices))})`}
                                    hideLabel />
                            </div>
                        }
                        {props.values.isSki === false &&
                            <div className="df-col">
                                <TypographyX fontSize={24} bold toUpper style={{ marginBottom: 8 }}>sta je links of rechts voor?</TypographyX>
                                <FormBooleanOptionsField<IMaterialModel>
                                    name="isSnowboardRightFootInFront"
                                    trueText="RECHTS"
                                    falseText="LINKS"
                                    label="links of rechts" />
                            </div>
                        }
                    </div>
                </div>
                <TypographyX fontSize={24} bold toUpper style={{ marginTop: 30, marginBottom: 8 }}>heb je zelf botten?</TypographyX>
                <FormBooleanOptionsField<IMaterialModel>
                    name="hasOwnBoots"
                    trueText="ja"
                    falseText="nee"
                    label="eigen botten?" />
                <div className="df-row-ac" style={{ marginTop: 30, marginBottom: 8 }}>
                    <TypographyX fontSize={24} bold toUpper>selecteer je niveau?</TypographyX>
                    <InfoIconButtonWithoutPadding onClick={onLevelInfo} />
                </div>
                <FormOptionsField<IMaterialModel, LevelType>
                    name="level"
                    options={LevelTypes}
                    record={LevelTypeRecord}
                    style={{ marginBottom: '30px' }}
                    label="Level"
                    hideLabel />
                <div className='fg1'></div>
                <div className="df-row-ac jc-sb" style={{ marginTop: 30 }}>
                    <BackButton onGoBack={onPrevious} text="Terug" />
                    <PrimaryContainedButton isLoading={isSubmitting} onClick={() => props.submitForm()}>Volgende stap</PrimaryContainedButton>
                </div>
            </Page>
        </Form>
    );

}