import { makeStyles } from '@material-ui/core';
import { PickersDay } from '@material-ui/pickers';
import { DayProps } from '@material-ui/pickers/views/Calendar/Day';
import clsx from 'clsx';
import { endOfMonth, isAfter, isBefore, startOfMonth } from 'date-fns';
import { isSameDay } from 'date-fns/esm';
import { isArray } from 'lodash';
import React from 'react';
import { nlBELocaleEndOfWeek } from '../../utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from '../../utils/date/nlBELocaleStartOfWeek';
import './DateFilterItem.scss';

const useStyles = makeStyles((theme) => ({
    highlightButton: {
        backgroundColor: 'transparent',
        color: theme.palette.common.white,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    highlight: {
        backgroundColor: 'rgba(71, 145, 219, 0.6)',
    },
    roundLeft: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    roundRight: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
}));

const isStartOfMonth = (date: Date) => isSameDay(date, startOfMonth(date));
const isStartOfWeek = (date: Date) => isSameDay(date, nlBELocaleStartOfWeek(date));
const isEndOfWeek = (date: Date) => isSameDay(date, nlBELocaleEndOfWeek(date));
const isEndOfMonth = (date: Date) => isSameDay(date, endOfMonth(date));
const isBeforeOrSameDay = (date: Date, dateToCompare: Date) => isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
const isAfterOrSameDay = (date: Date, dateToCompare: Date) => isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);

interface IProps {
    date: any;
    selectedDates: any;
    props: DayProps<Date>;
    mode: 'all-before' | 'all-after';
}

export const PickersDayAllAfterOrBefore = ({ date, selectedDates, props, mode }: IProps) => {
    const hasSelected = isArray(selectedDates) && selectedDates.length > 0;
    const roundLeft = isStartOfWeek(date as any) || isStartOfMonth(date as any);
    const roundRight = isEndOfWeek(date as any) || isEndOfMonth(date as any);
    const mustHighlight = hasSelected && props.inCurrentMonth && (mode === 'all-before' ? isBeforeOrSameDay(date as any, (selectedDates as any)[0]) : isAfterOrSameDay(date as any, (selectedDates as any)[0]));
    const isSelected = isSameDay(date as any, (selectedDates as any)[0]);
    const classes = useStyles();

    return (
        <div className={clsx({
            [classes.highlight]: mustHighlight,
            [classes.roundLeft]: roundLeft || (isSelected && mode === 'all-after'),
            [classes.roundRight]: roundRight || (isSelected && mode === 'all-before')
        })} style={{ border: '2px solid transparent' }}>
            <PickersDay
                {...props}
                disableMargin
                className={clsx({
                    [classes.highlightButton]: mustHighlight
                })}
                style={{ transform: 'scale(1.1)', width: '36px', height: '36px' }} />
        </div>
    );
}