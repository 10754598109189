import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LinkOffOutlinedIcon from '@material-ui/icons/LinkOffOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { IReservation, IReservationLine, reservationsCommand_deleteLine, reservationsCommand_unlinkInventoryItem } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import * as routes from '../../../../routes';
import { LinkInventoryItemForm } from '../forms/link/LinkInventoryItemForm';
import { OwnBootActionsButton } from './OwnBootActionsButton';

interface IProps {
    reservation: IReservation;
    line: IReservationLine;
    reload: () => void;
}


export const ReservationLineActions = ({ reservation, line, reload }: IProps) => {
    const history = useHistory();
    const { open, confirm, cancel } = useDialogsContext(reload);

    const onLink = () => {
        open(<LinkInventoryItemForm
            open
            reservation={reservation}
            line={line}
            confirm={confirm}
            cancel={cancel}
            onShowDetailsInventoryItem={() => { }} />);
    }

    const onDeleteLink = () => {
        open(<AlertDialog
            open
            acceptText="Ja, verwijder link!"
            rejectText="Annuleer"
            title="Bevestiging"
            content="Wil je de link met dit item verwijderen?"
            reject={cancel}
            acceptF={() => reservationsCommand_unlinkInventoryItem(reservation.id, line.id)}
            accept={confirm} />)
    }

    const onDeleteLine = () => {
        open(<AlertDialog
            open
            acceptText="Ja, verwijder!"
            rejectText="Annuleer"
            title="Bevestiging"
            content="Wil je deze lijn verwijderen?"
            reject={cancel}
            acceptF={() => reservationsCommand_deleteLine(reservation.id, line.id)}
            accept={confirm} />);
    }


    return (
        <>
            {line.isOwnBoot === false &&
                <div className="df-row-ac jc-e">
                    {reservation.canAddLine &&
                        <>
                            {line.hasInventoryItem ?
                                <PrimaryContainedButton
                                    size="small"
                                    style={{ width: '100px' }}
                                    onClick={() => history.push(routes.calcInventoryItemDetailRoute(line.inventoryItemId!))}>
                                    {`Bekijk`}
                                </PrimaryContainedButton>
                                :
                                <PrimaryContainedButton
                                    size="small"
                                    style={{ width: '100px' }}
                                    onClick={onLink}>
                                    {`Selecteer`}
                                </PrimaryContainedButton>
                            }
                            {line.hasInventoryItem === true &&
                                <ColorOnHoverIconButton onClick={onDeleteLink} style={{ marginLeft: 4, marginRight: -6 }} color="secondary">
                                    <LinkOffOutlinedIcon />
                                </ColorOnHoverIconButton>
                            }
                            {line.hasInventoryItem === false &&
                                <ColorOnHoverIconButton onClick={onDeleteLine} style={{ marginLeft: 4, marginRight: -6 }} color="secondary">
                                    <DeleteOutlineOutlinedIcon />
                                </ColorOnHoverIconButton>
                            }
                        </>
                    }
                </div>}
            {line.isOwnBoot === true &&
                <div className="df-row-ac jc-e">
                    <OwnBootActionsButton
                        reservation={reservation}
                        line={line}
                        reload={reload} />
                    {reservation.canAddLine &&
                        <ColorOnHoverIconButton onClick={onDeleteLine} style={{ marginLeft: 4, marginRight: -6 }} color="secondary">
                            <DeleteOutlineOutlinedIcon />
                        </ColorOnHoverIconButton>
                    }
                </div>
            }
        </>
    );
}