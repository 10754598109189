import React from 'react';
import { IIconProps } from './IIconProps'
import { OvalStyle } from './OvalStyle';

const ovalSelectedStyle: React.CSSProperties = {
    ...OvalStyle,
    backgroundColor: 'var(--color-white)',
    boxShadow: '1px 2px 4px 0 var(--color-grey-medium)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
}

const ovalSelectedInnerStyle: React.CSSProperties = {
    height: '10px',
    width: '10px',
    borderRadius: '100px',
    backgroundColor: 'var(--color-green-bright)',
    marginLeft: 'auto',
    marginRight: 'auto',
}

export const OvalSelectedIcon = ({ className, style }: IIconProps) => {
    return (
        <div className={className} style={{...ovalSelectedStyle, ...style}}>
            <div style={ovalSelectedInnerStyle}></div>
        </div>
    );
}