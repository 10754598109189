import { useParamsFromQueryContext } from 'framework/hooks/useParamsFromQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ReservationStatus } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { QuickFilter } from '../ReservationsList';
import { ReservationsQueryContext } from './ReservationsQueryContext';
import { ReservationsViewContext } from './ReservationsViewContext';

const QfToStatusRecord: Record<QuickFilter, ReservationStatus | undefined> = {
    all: undefined,
    canBePreparedForRental: 'CanBePrepared',
    inWaitingLine: 'InWaitingLine'
}

export const ReservationsViewProvider = ({ children }: any) => {
    const [qf, setQf] = useState<QuickFilter>('all');
    const { setParams } = useParamsFromQueryContext(ReservationsQueryContext);

    useEffect(() => {
        setParams(params => onResetToPage1({
            ...params,
            statuses: qf !== 'all' ? [] : params.statuses,
            keyFilterStatus: QfToStatusRecord[qf],
        }));
        // eslint-disable-next-line
    }, [qf]);
    
    return (
        <ReservationsViewContext.Provider
            value={{
                quickFilter: qf,
                setQuickFilter: setQf,
            }}>
            {children}
        </ReservationsViewContext.Provider>
    );
}

