import { useContext, useEffect, useState } from "react";
import { useLazyEffect } from "framework/hooks/useLazyEffect";
import { DialogsContext } from "./DialogsContext";

export function useDialogsContext(reload?: () => void): { open: (dialog: JSX.Element) => void, confirm: () => void, cancel: () => void, isStacked: boolean} {
    const { open, confirm, cancel, stackCount } = useContext(DialogsContext);
    const [thisPos, setThisPos] = useState<number>(stackCount);
    const [isStacked, setIsStacked] = useState<boolean>(false);

    useEffect(() => {
        setThisPos(stackCount);
    // eslint-disable-next-line
    }, []);

    const onOpen = (dialog: JSX.Element) => {
        open(dialog, reload);
    }

    useLazyEffect(() => {
        setIsStacked(stackCount > thisPos);
    }, [stackCount, thisPos])

    return { open: onOpen, confirm, cancel, isStacked };
}
