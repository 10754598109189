import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import React from 'react';
import { TypographyX } from 'shared/TypographyX';

interface IProps extends DialogProps {
    close: () => void;
}

export const LevelInformationDialog = ({ close, ...rest }: IProps) => {
    return (
        <Dialog
            {...rest}
            onClose={close}>
            <CloseButtonOnAbsolutePosition onClick={close} />
            <DialogTitle>{`Meer info over de niveau's`}</DialogTitle>
            <DialogContent
                dividers>
                <div className="df-col">
                    <TypographyX fontSize={20}>
                        *hieronder kan je meer info vinden over de verschillende niveaus.
                        Afhankelijk van jouw keuze houden we hier rekening mee bij het kiezen van de juiste ski.
                        Bij twijfel, spreek gerust een medewerker aan of omschrijf je gewenste ski
                        in het opmerkingenveld onder “Persoonsgegevens”
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>beginner</TypographyX>
                    <TypographyX fontSize={20}>
                        Je gaat voor de eerste keer skiën of hebt nog maar slechts enkele keren op latten gestaan. 
                        We kiezen voor jou lichte, wendbare en korte ski’s die je voldoende vertrouwen geven om bij te leren.
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>gemiddeld</TypographyX>
                    <TypographyX fontSize={20}>
                        Je hebt al enige ervaring en komt vlot de pistes af. 
                        We selecteren ski’s die je stabiliteit en zekerheid bieden, 
                        waar je als gemiddelde tot gevorderde skiër veel plezier mee kan beleven.
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>expert</TypographyX>
                    <TypographyX fontSize={20}>
                        Je komt vlot, zelfverzekerd en met zekere snelheid van de berg. 
                        We kiezen veelal een iets zwaardere, stijvere en stabielere ski die sportief getailleerd is.
                    </TypographyX>
                </div>
            </DialogContent>
        </Dialog>
    );
}