import { Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
    children: any;
    style?: React.CSSProperties;
    withPadding?: boolean;
    textColor?: 'primary' | 'secondary';
    variant?: 'body2' | 'caption';
    onClick?: VoidFunction;
    withPaddingTop?: boolean;
}

export const NoRecordsFound = ({ children, withPadding = false, style, textColor = 'primary', variant = 'body2', onClick, withPaddingTop }: IProps) => {
    const theme = useTheme();
    
    return (
        <Typography
            variant={variant}
            onClick={onClick}
            style={{ padding: withPadding ? 12 : 'none', paddingTop: withPadding ? 12 : withPaddingTop ? 6 : 'none', color: textColor === 'secondary' ? theme.palette.text.secondary : theme.palette.text.primary, ...style }}>
            <em>{children}</em>
        </Typography>
    );
}