import { Worker } from "@react-pdf-viewer/core";
import React, { useState } from 'react';
import { PdfViewerDialog } from '../PdfViewerDialog';
import { base64ToBlobUrl } from '../utils/base64ToBlobUrl';
import { PdfViewerDialogContext } from './PdfViewerDialogContext';

export const PdfViewerDialogProvider = ({ children }: any) => {
    const [title, setTitle] = useState<string>();
    const [url, setUrl] = useState<string>();


    // const loadHistoryF = useApiCall(documentsQuery_historyContent);
    // const loadF = useApiCall(documentsQuery_content);
    // const [url, setUrl] = useState<string>();

    // const [isOpen, setIsOpen] = useState<boolean>(false);

    // const openWithDocument = async (title: string, documentId: string, historyId?: string) => {
    //     setTitle(title);
    //     if (historyId) {
    //         handleRContent(await loadHistoryF(documentId, historyId, 'Pdf' as DocumentExportFormat));
    //     } else {
    //         handleRContent(await loadF(documentId, 'Pdf' as DocumentExportFormat));
    //     }
    // }


    // const handleRContent = (rContent: Try<IDocumentContent>) => {
    //     if (rContent.isSuccess) {
    //         setUrl(base64ToBlobUrl(rContent.result.content));
    //         setIsOpen(true);
    //     }
    // }

    const openWithContent = (title: string, content: string) => {
        setTitle(title);
        setUrl(base64ToBlobUrl(content));
    }

    const openWithUrl = (title: string, url: string) => {
        setTitle(title);
        setUrl(url);
    }

    return (
        <PdfViewerDialogContext.Provider
            value={{
                openWithContent: openWithContent,
                openWithUrl: openWithUrl,
            }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <PdfViewerDialog
                    url={url}
                    open={Boolean(url)}
                    close={() => setUrl(undefined)}
                    title={title} />
                {children}
            </Worker>
        </PdfViewerDialogContext.Provider>
    );
};
