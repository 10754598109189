import { Card, Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatDate } from 'framework/utils/date/formatDate';
import { holidayPeriodsCommand_delete, IHolidayPeriod } from 'gen/ApiClient';
import React from 'react';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageWithSearch } from '../PageWithSearch';
import { QueryContext } from './context/QueryContext';
import { Filter } from './Filter';
import { HolidayForm } from './forms/HolidayForm';

export const HolidayPeriodsList = () => {
    const { params, setParams, queryResult, reload } = useQueryContext(QueryContext);
    const { open, confirm, cancel } = useDialogsContext(reload);

    const headers = [
        createSortableHeader<IHolidayPeriod>('Naam', 'name', t => t.name),
        createSortableHeader<IHolidayPeriod>('Van', 'fromDate', t => formatDate(t.fromDate)),
        createSortableHeader<IHolidayPeriod>('Tot', 'toDate', t => formatDate(t.toDate)),
        createEmptyHeader<IHolidayPeriod>('actions', t =>
            <div className="df-row-ac jc-e" style={{ marginRight: 6 }}>
                <ColorOnHoverIconButton color="primary" onClick={() => onEdit(t)} edge="end">
                    <EditOutlinedIcon />
                </ColorOnHoverIconButton>
                <ColorOnHoverIconButton color="primary" onClick={() => onDelete(t.id)} edge="end">
                    <DeleteOutlineOutlinedIcon />
                </ColorOnHoverIconButton>
            </div>)
    ]

    const onDelete = (id: string) => {
        open(<AlertDialog
            open
            acceptText="Ja, verwijder!"
            rejectText="Annuleer"
            title="Bevestig"
            content="Wil je deze periode verwijderen?"
            reject={cancel}
            acceptF={() => holidayPeriodsCommand_delete(id)}
            accept={confirm} />)
    }

    const onNew = () => {
        open(<HolidayForm
            open
            cancel={cancel}
            confirm={confirm} />)
    }

    const onEdit = (item: IHolidayPeriod) => {
        open(<HolidayForm
            open
            item={item}
            cancel={cancel}
            confirm={confirm} />)
    }

    return (
        <PageWithSearch
            onRefresh={reload}
            placeholder={`Zoek op naam`}
            onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
            searchString={params.searchString}>
            <div className="df-col">
                <PrimaryContainedButton size='small' className='w-fc' onClick={onNew}>+ Nieuwe periode</PrimaryContainedButton>
                {queryResult &&
                    <Card style={{ marginTop: 16 }}>
                        <Filter
                            params={params}
                            onFilter={pars => setParams({ ...pars, pageParams: { ...params.pageParams, index: 1 } })} />
                        <Divider />
                        <PageableTable<IHolidayPeriod>
                            queryResult={queryResult}
                            headers={headers}
                            pageParams={params.pageParams}
                            sortParams={params.sortParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
                            getKey={t => t.id!} />
                    </Card>}
            </div>
        </PageWithSearch>
    );
}