import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';
import React from 'react';

interface IProps extends DialogProps {
    title: string;
    content: string | JSX.Element;
    closeText?: string;
    close: VoidFunction;
}

// const defaultFunction = () => Promise.resolve(new Try({ error: '', hasError: false }));

export const NotificationDialog = ({ title, content, closeText, close, ...rest }: IProps) => {
    return (
        <Dialog
            {...rest}
            onClose={close}>
            <CloseButtonOnAbsolutePosition onClick={close} />
            <DialogTitle>{title}</DialogTitle>
            <DialogContent
                dividers
                style={{ fontSize: 20, whiteSpace: 'pre-line' }}>
                {content}
            </DialogContent>
        </Dialog>
    );
}