import { IReservationLineWithInventoryItem } from 'gen/ApiClient';
import React from 'react';

interface IProps {
    item: IReservationLineWithInventoryItem;
}

export const BrandModelOrOwnBootComponent = ({ item }: IProps) => {
    if (item.inventoryItem) {
        return <div>{`${item.inventoryItem.brand}, ${item.inventoryItem.model}`}</div>;
    } else if (item.reservationLine.isOwnBoot) {
        return (
            <div className='df-col'>
                <div className='df-row'>
                    <b>Ref. eigen bot:</b>
                    <span style={{ marginLeft: 4 }}>{item.reservationLine.ownBootReference}</span>
                </div>
                <div style={{ fontStyle: 'italic'}}>{item.reservationLine.isOwnBootHandedOver ? '(overhandigd aan de Zwerver)' : '(nog niet overhandigd aan de Zwerver)'}</div>
            </div>
        );
    } else {
        return <div></div>
    }
}