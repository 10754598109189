import React from 'react';
import { TypographyX } from 'shared/TypographyX';

const metricStyle: React.CSSProperties = {
    background: 'var(--color-white)',
    border: '1px solid var(--color-grey-medium)',
    height: '200px',
    width: '300px',
    margin: '10px',
};


interface IProps {
    description: string;
    value: string | number;
    onClick?: () => void;
}

export const DashboardStat = ({ description, value, onClick }: IProps) => {
    return (
        <div style={{...metricStyle, cursor: onClick !== undefined ? 'pointer' : 'unset' }} className="df-col-ac jc-c" onClick={onClick}>
            <TypographyX fontSize={18} color="color-grey-dark" toUpper style={{ marginBottom: '10px' }}>{description}</TypographyX>
            <TypographyX fontSize={50} bold>{value}</TypographyX>
        </div>
    );
}