import { Card, CardHeader, Divider } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createType } from 'framework/utils/createType';
import { formatDate } from 'framework/utils/date/formatDate';
import { IReservationDto, IReservationsQueryParams } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { formatCreatedDate } from 'shared/utils/formatCreatedDate';
import { formatReservationPeriod } from 'shared/utils/formatReservationPeriod';
import * as routes from '../../../routes';
import { PageWithSearch } from '../PageWithSearch';
import { ReservationsQueryContext } from './context/ReservationsQueryContext';
import { ReservationsViewContext } from './context/ReservationsViewContext';
import { Filter } from './Filter';
import { MaterialLines } from './MaterialLines';
import { ReservationStatusChip } from './ReservationStatusChip';
import { inventoryItemGroupTypesToDisplay } from './utils/inventoryItemGroupTypesToDisplay';

export const QuickFilters = createType('all', 'inWaitingLine', 'canBePreparedForRental');
export type QuickFilter = typeof QuickFilters[number];

const QuickFilterRecord: Record<QuickFilter, string> = {
    all: 'Alles',
    inWaitingLine: 'In wachtrij',
    canBePreparedForRental: 'Klaar te zetten'
}

export const ReservationsList = () => {
    const { params, setParams, queryResult, reload } = useQueryContext(ReservationsQueryContext);
    const { push } = useHistory();
    const { quickFilter, setQuickFilter } = useContext(ReservationsViewContext);

    const onNavigateToDetail = (id: string) => {
        push(routes.calcReservationDetailRoute(id));
      };

    const headers = [
        createSortableHeader<IReservationDto>('Datum aanmaak', 'created', t => formatCreatedDate(t.created)),
        createSortableHeader<IReservationDto>('Afhalen vanaf', 'collectionDate', t => formatDate(t.collectionDate)),
        createHeader<IReservationDto>('Status', t => <ReservationStatusChip item={t.item} />),
        createSortableHeader<IReservationDto>('Res.Nummer', 'number', t => t.number),
        createSortableHeader<IReservationDto>('Naam', 'firstNameLastName', t => t.firstNameLastName),
        createHeader<IReservationDto>('Periode', t => formatReservationPeriod(t.item.fromDate, t.item.toDate)),
        createHeader<IReservationDto>('Materiaal', t => <MaterialLines lines={t.lines ?? []} />),
        createHeader<IReservationDto>('Type', t => inventoryItemGroupTypesToDisplay(t.item)),
        // createEmptyHeader<IReservationDto>('actions', t => <ReservationActions item={t.item} reload={reload} />),
        createEmptyHeader<IReservationDto>('actions', t =>
            <PrimaryContainedButton size="small" onClick={() => onNavigateToDetail(t.id)}>
                {`Details`}
            </PrimaryContainedButton>, 'right'
        ),
    ]

    const onFilter = (pars: IReservationsQueryParams) => {
        setParams(onResetToPage1(pars));
    }

    return (
        <PageWithSearch
            onRefresh={reload}
            placeholder={`Zoek op naam of reservatienummer...`}
            searchString={params.searchString}
            onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}>
            <PrimaryContainedButton size='small' onClick={() => push(routes.WelcomeRoute)} className='w-fc'>+ Nieuwe reservatie</PrimaryContainedButton>
            {queryResult &&
                <Card style={{ marginTop: 16 }}>
                    <CardHeader title="Reservaties" />
                    <Divider />
                    <div className='df-row-ac' style={{ padding: 8 }}>
                        <ButtonGroupWithSelection<QuickFilter>
                            options={QuickFilters}
                            selected={quickFilter}
                            setSelected={setQuickFilter}
                            renderOption={t => QuickFilterRecord[t]} />
                    </div>
                    <Divider />
                    <div className='df-row-ac jc-sb'>
                        <Filter
                            params={params}
                            onFilter={onFilter} />
                        <FormControlLabelSwitch
                            isChecked={params.isAlsoShowCancelled}
                            toggle={() => onFilter({ ...params, isAlsoShowCancelled: !params.isAlsoShowCancelled })}
                            label={`Bekijk ook geannuleerde`} />
                    </div>
                    <Divider />
                    <PageableTable<IReservationDto>
                        queryResult={queryResult}
                        headers={headers}
                        getKey={t => t.id!}
                        pageParams={params.pageParams}
                        sortParams={params.sortParams}
                        setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })} />
                </Card>
            }
        </PageWithSearch>
    );
}