import { Button } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormPasswordFieldOutlined } from 'framework/forms/FormPasswordFieldOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_login, ILoginModel } from 'gen/ApiClient';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import * as yup from 'yup';
import * as routes from '../routes';
import { AuthContext } from './auth/AuthContext';
import { Logo } from './logo/Logo';

const schema = yup.object<ILoginModel>({
    name: yup.string().required(),
    password: yup.string().required(),
});

const EmptyValues: ILoginModel = {
    name: '',
    password: '',
}


export const Login = () => {
    const authContext = useContext(AuthContext);
    const { push } = useHistory();
    const [login, isSubmitting] = useFormSubmit(auth_login);

    useEffect(() => {
        authContext.logout();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (values: ILoginModel, helpers: FormikHelpers<ILoginModel>) => {
        const r = await login(values);
        if (r.isSuccess) {
            if (r.result.hasError) {
                helpers.setFieldError('password', r.error ? r.error : 'combinatie gebruiker/wachtwoord incorrect');
            } else {
                authContext.login(r.result.user);
                push(routes.DashboardRoute);
            }
        }
    }

    return (
        <div className="stretch-ver apply-background df-col" style={{ padding: 20 }}>
            <div className="df-row-ac jc-e">
                <div></div>
                <Button variant="outlined" size="small" onClick={() => push(routes.WelcomeRoute)}>Klanten</Button>
            </div>
            <div className="df-col-ac stretch-ver jc-c" style={{ paddingBottom: '100px' }}>
                <div className="df-col-ac">
                    <Logo width={240} style={{ marginBottom: 30 }} />
                    <Formik<ILoginModel>
                        validateOnMount
                        initialValues={EmptyValues}
                        validationSchema={schema}
                        onSubmit={handleSubmit}>
                        {(props) => (
                            <Form 
                                className="stretch-ver df-col" style={{ minWidth: '400px' }}>
                                <div className="df-col" style={{ marginBottom: '30px' }}>
                                    <FormTextFieldOutlined<ILoginModel> label="Gebruiker" name="name" />
                                    <FormPasswordFieldOutlined<ILoginModel> label="Wachtwoord" name="password" />
                                </div>
                                <PrimaryContainedButton isLoading={isSubmitting} type="submit" size="large">Login</PrimaryContainedButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

