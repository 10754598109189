import { useField } from 'formik';
import { FormErrorHelper } from 'framework/forms/common/FormErrorHelper';
import { IFormProps } from 'framework/forms/common/IFormProps';
import { nameOf } from 'framework/utils/nameOf';
import React from 'react';
import { SelectOption } from './SelectOption';

interface IProps<TModel> extends IFormProps<TModel> {
    trueText: string;
    falseText: string;
    orientation?: 'horizontal' | 'vertical';
}

export const FormBooleanOptionsField = <TModel extends unknown>({ name, trueText, falseText, orientation = 'horizontal', style, ...props }: IProps<TModel>) => {
    const [field, meta, helpers] = useField<boolean>(nameOf(name));

    return (
        <div className="df-col" style={style}>
            <div className={orientation === 'horizontal' ? 'df-row-ac' : 'df-col-ac'}>
                <SelectOption
                    text={trueText}
                    isSelected={field.value === true}
                    onSelect={() => {
                        helpers.setTouched(true);
                        helpers.setValue(true);
                    }}
                    style={{ marginRight: 40 }} />
                <SelectOption
                    text={falseText}
                    isSelected={field.value === false}
                    onSelect={() => {
                        helpers.setTouched(true);
                        helpers.setValue(false);
                    }} />
            </div>
            <FormErrorHelper meta={meta} />
        </div>
    );
};