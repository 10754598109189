import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePickerOutlined } from 'framework/forms/FormDatePickerOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { holidayPeriodsCommand_new, holidayPeriodsCommand_update, IHolidayPeriod, IHolidayPeriodModel } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import * as yup from 'yup';

const schema = yup.object<IHolidayPeriodModel>({
    name: yup.string().required(),
    fromDate: yup.date()
        .required('Selecteer VAN datum'),
    toDate: yup.date()
        .when('from', (from: any, schema: any) => from !== undefined ? yup.date().min(from, 'TOT datum kan niet vóór VAN datum') : undefined)
        .required('Selecteer TOT datum'),
});

const EmptyValues: IHolidayPeriodModel = {
    fromDate: undefined as any,
    toDate: undefined as any,
    name: '',
}

const toModel = (inst: IHolidayPeriod): IHolidayPeriodModel => {
    return ({
        name: inst.name,
        fromDate: inst.fromDate,
        toDate: inst.toDate,
    })
}

interface IProps extends DialogProps {
    item?: IHolidayPeriod;
    cancel: () => void;
    confirm: () => void;
    style?: React.CSSProperties;
}

export const HolidayForm = ({ item, confirm, cancel, ...rest }: IProps) => {
    const [add, isAdding] = useFormSubmit(holidayPeriodsCommand_new);
    const [update, isUpdating] = useFormSubmit(holidayPeriodsCommand_update);
    const isSubmitting = useMemo(() => isAdding || isUpdating, [isAdding, isUpdating]);

    const handleSubmit = async (values: IHolidayPeriodModel, helpers: FormikHelpers<IHolidayPeriodModel>) => {
        const r = item ? await update(item.id, values) : await add(values);
        if (handleFormResponse(r, helpers)){
            confirm();
        }
    }

    return (
        <Formik<IHolidayPeriodModel>
            validateOnMount
            validationSchema={schema}
            initialValues={item ? toModel(item) : EmptyValues}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    {...rest}>
                    <DialogTitle>Vakantieperiode</DialogTitle>
                    <DialogContent
                        dividers
                        className="df-col"
                        style={{ minWidth: 500 }}>
                        <FormTextFieldOutlined<IHolidayPeriodModel>
                            label="Naam"
                            name="name"
                            required />
                        <FormDatePickerOutlined<IHolidayPeriodModel>
                            label="VAN"
                            name="fromDate"
                            required />
                        <FormDatePickerOutlined<IHolidayPeriodModel>
                            label="TOT"
                            name="toDate"
                            required />
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        cancel={cancel}
                        isSubmitting={isSubmitting}
                        submitText={item ? 'Wijzig' : 'Voeg toe'} />
                </Dialog>
            </Form>
        </Formik>


    );
}