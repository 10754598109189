import { CacheContext } from "app/admin/dashboard/context/CacheContext";
import { DashboardStat } from "app/admin/dashboard/DashboardStat";
import { PageWithoutSearch } from "app/admin/PageWithoutSearch";
import { useCacheContext } from "framework/hooks/useCacheContext";
import { formatCurrency } from "framework/utils/formatCurrency";
import React from "react";

export const ReserVationAnalysis = () => {
  const [data, reload] = useCacheContext(CacheContext);

  if (data === undefined) {
    return <div></div>;
  }

  return (
    <PageWithoutSearch onRefresh={reload}>
      <div className="df-col">
        {/* <DividerWithCaption
          caption="Totaal"
          captionStyle={{ fontWeight: "bold", fontSize: 30 }}
        /> */}
        <div className="df-row-ac">
          <DashboardStat
            description="Aantal Reservaties dit jaar "
            value={data.countReservationsThisYear}
          />
          <DashboardStat
            description="Inkomsten Verhuur dit jaar"
            value={formatCurrency(data.revenuesThisYear)}
          />
        </div>
        {/* <DividerWithCaption
          caption="Ski"
          captionStyle={{ fontWeight: "bold", fontSize: 30 }}
        />
        <div className="df-row-ac"></div>
        <DividerWithCaption
          caption="Snowboard"
          captionStyle={{ fontWeight: "bold", fontSize: 30 }}
        /> */}
        <div className="df-row-ac"></div>
        {/* <div className="df-row-ac">
                <DashboardMetricTile description="aantal reservaties dit jaar" value={data.countReservationsThisYear} />
                <DashboardMetricTile description="omzet dit jaar" value={`€ ${data.revenuesThisYear}`} />
            </div> */}
      </div>
    </PageWithoutSearch>
  );
};
