import { CacheProvider as FrameworkCacheProvider } from "framework/context/CacheProvider";
import { IInventoryItemTaxonomies, inventoryItemsQuery_concurrencyToken, inventoryItemsQuery_taxonomies } from "gen/ApiClient";
import React from "react";
import { TaxonomiesCacheContext } from "./TaxonomiesCacheContext";

export const CacheProvider = ({ children }: any) => {
    return (
        <FrameworkCacheProvider<IInventoryItemTaxonomies>
            concurrencyF={inventoryItemsQuery_concurrencyToken}
            loadF={inventoryItemsQuery_taxonomies}
            context={TaxonomiesCacheContext}>
            {children}
        </FrameworkCacheProvider>
    );
}
