import { CacheProvider as FrameworkCacheProvider } from "framework/context/CacheProvider";
import { holidayPeriodsQuery_all, holidayPeriodsQuery_concurrencyToken, IHolidayPeriod } from "gen/ApiClient";
import React from "react";
import { HolidayPeriodsCacheContext } from "./HolidayPeriodsCacheContext";

export const HolidayPeriodsCacheProvider = ({ children }: any) => {
    return (
        <FrameworkCacheProvider<IHolidayPeriod[]>
            concurrencyF={holidayPeriodsQuery_concurrencyToken}
            loadF={holidayPeriodsQuery_all}
            context={HolidayPeriodsCacheContext}>
            {children}
        </FrameworkCacheProvider>
    );
}
