import { createMuiTheme, Theme } from "@material-ui/core";

const paletteTheme = createMuiTheme({
    typography: {
        fontFamily: "TradeGothicLTStd",
    },
    palette: {
        primary: {
            main: '#51e3c2',
            contrastText: '#323942',
        }
    }
})

export const theme: Theme = createMuiTheme(paletteTheme, {
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ["TradeGothicLTStd"],
            }
        },
        MuiButton: {
            root: {
                textTransform: 'unset',
                fontFamily: 'TradeGothicLTStd-BdCn20',
                height: '80px',
                paddingLeft: '25px',
                paddingRight: '25px',
                fontSize: '24px',
            },
            outlined: {
                backgroundColor: 'white',
                border: '2px solid black',
                borderRadius: 0,
            },
            contained: {
                borderRadius: 0,
                boxShadow: 'none',
            },
            sizeLarge: {
                fontSize: '34px',
                minWidth: '380px',
                height: '100px',
            },
            sizeSmall: {
                fontSize: '16px',
                height: '40px',
                paddingLeft: '15px',
                paddingRight: '15px',
            }
        },
        MuiOutlinedInput: {
            root: {
                minHeight: 80,
                backgroundColor: 'white',
            },
            input: {
                paddingTop: 14.5,
                paddingBottom: 14.5,
                paddingLeft: 16,
                paddingRight: 8.5,
                fontSize: 20,

            }
        },
        MuiCard: {
            root: {
                boxShadow: 'none',
                border: '1px solid rgba(0, 0, 0, 0.12)',
            }
        },
        MuiTableCell: {
            root: {
                fontSize: 16
            }
        },
        MuiCardHeader: {
            title: {
                fontFamily: 'TradeGothicLTStd-BdCn20',
                fontSize: 20,
            }
        },
        MuiTypography: {
            h6: {
                fontFamily: 'TradeGothicLTStd-BdCn20',
                fontSize: 30
            }
            // h1: {
            //     fontWeight: 400,
            //     fontSize: '24px',
            // },
            // h2: {
            //     color: blueGrey[600],
            //     textTransform: 'uppercase',
            //     fontSize: '11px',
            //     fontWeight: 500,
            // },
            // body2: {
            //     fontSize: '12px',
            //     letterSpacing: '-0.04px'
            // },
            // gutterBottom: {
            //     marginBottom: 8,
            // },
            // colorTextSecondary: {
            //     // color: blueGrey[600],
            // }
        },
        // MuiPaper: {
        //     elevation1: {
        //         boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        //     }
        // },
        // MuiCardHeader: {
        //     title: {
        //         fontSize: '16px',
        //         fontWeight: 500,
        //     }
        // },
        // MuiInputBase: {
        //     input: {
        //         fontSize: '14px',
        //         lineHeight: '16px',
        //     },
        // },
        // MuiFormLabel: {
        //     root: {
        //         fontSize: '14px'
        //     }
        // }
    }
});