import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { IQueryResult, IReservationDto, IReservationsQueryParams, reservationsQuery_concurrencyToken, reservationsQuery_query } from "gen/ApiClient";
import { reservationsQueryParamsLsKey } from "localStorageKeys";
import React from "react";
import { ReservationsQueryContext } from "./ReservationsQueryContext";

export const DefaultReservationsQueryParams: IReservationsQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
    statuses: [],
    createdAfter: undefined,
    createdBefore: undefined,
    fromDateAfter: undefined,
    fromDateBefore: undefined,
    collectionDateBefore: undefined,
    collectionDateAfter: undefined,
    isAlsoShowCancelled: false,
    keyFilterStatus: undefined,
    inventoryItemGroupTypes: []
}

export const ReservationsQueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<IReservationDto>, IReservationsQueryParams>
            defaultParams={DefaultReservationsQueryParams}
            queryF={reservationsQuery_query}
            concurrencyF={reservationsQuery_concurrencyToken}
            localStorageKey={reservationsQueryParamsLsKey}
            context={ReservationsQueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
