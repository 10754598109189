import {
  IReservation,
  reservationsQuery_downloadReservationTicket
} from "gen/ApiClient";
import { useDownloadOpenPdfInNewTab } from "gen/useDownloadOpenPdfInNewTab";
import React from "react";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";

interface IProps {
  reservation: IReservation;
}

export const PrintReservationTicketButton = ({ reservation }: IProps) => {
  const [downloadPdf] = useDownloadOpenPdfInNewTab(
    reservationsQuery_downloadReservationTicket
  );

  const onPrint = async () => {
    await downloadPdf(reservation.id);
  };

  return (
    <>
      <PrimaryContainedButton
        variant="outlined"
        size="small"
        onClick={onPrint}
        style={{ marginRight: 10 }}
      >
        PRINT BON
      </PrimaryContainedButton>
    </>
  );
};
