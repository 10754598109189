import { AxiosResponse } from "axios";

export const openPdfInNewTabFileResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
    const file = new Blob([response.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
     const pdfWindow = window.open();
     pdfWindow!.location.href = fileURL;
    return response;
}