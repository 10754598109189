import { Theme, useTheme } from '@material-ui/core';
import { InventoryType, IReservationLineWithInventoryItem } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { InventoryTypeIcon } from 'shared/icons/InventoryTypeIcon';
import { SkiBootIcon } from 'shared/icons/SkiBootIcon';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';

const toColor = (line: IReservationLineWithInventoryItem, theme: Theme) => {
    if (line.reservationLine.isOwnBoot) {
        return line.reservationLine.isOwnBootHandedOver ? theme.palette.success.main : theme.palette.error.main;
    } else if (line.reservationLine.hasInventoryItem === false) {
        return theme.palette.text.secondary;
    } else {
        return line.inventoryItem.isPresentOrPreparedForRental ? theme.palette.success.main : theme.palette.error.main;
    }
}

interface IProps {
    line: IReservationLineWithInventoryItem;
    style?: React.CSSProperties;
    withoutDescription?: boolean;
}

export const ReservationLineWithInventoryItemMaterialIconWithColor = ({ line, style, withoutDescription }: IProps) => {
    const theme = useTheme();
    const color = useMemo(() => toColor(line, theme), [line, theme]);
    const iiType = useMemo(() => line.reservationLine.type as InventoryType, [line]);

    return (
        <div className='df-row-ac' style={style}>
            {line.reservationLine.isOwnBoot === false &&
                <InventoryTypeIcon
                    type={iiType}
                    style={{ color: color }} />
            }
            {line.reservationLine.isOwnBoot === true && <SkiBootIcon style={{ color: color }} />}
            {withoutDescription !== true && <div style={{ marginLeft: 8 }}>{line.reservationLine.isOwnBoot ? 'Eigen botten' : InventoryTypeRecord[iiType]}</div>}
        </div>
    );
}