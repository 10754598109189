import { useTheme } from '@material-ui/core';
import { IInventoryItem, InventoryType } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { InventoryTypeIcon } from 'shared/icons/InventoryTypeIcon';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';

interface IProps {
    item: IInventoryItem;
    withDescription?: boolean;
    style?: React.CSSProperties;
}

export const InventoryIcon = ({ item, withDescription, style }: IProps) => {
    const iiType = useMemo(() => item.type as InventoryType, [item]);
    const theme = useTheme();

    return (
        <div className='df-row-ac' style={style}>
            <InventoryTypeIcon
                type={iiType}
                style={{ color: (item.isPresentOrPreparedForRental && item.canSelect) ? theme.palette.success.main : theme.palette.error.main }} />
            {withDescription === true && <div style={{ marginLeft: 8 }}>{InventoryTypeRecord[iiType]}</div>}
        </div>
    );
}