import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FilterBar } from 'framework/filter/FilterBar';
import { NumberListFilterItemWithMinMax } from 'framework/filter/NumberListFilterItemWithMinMax';
import { RecordListFilterItem } from 'framework/filter/RecordListFilterItem';
import { StringListFilterItem } from 'framework/filter/StringListFilterItem';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IInventoryItemsQueryParams, InventoryItemReservationStatus, InventoryItemReservationStatuses, InventoryTypes } from 'gen/ApiClient';
import React from 'react';
import { InventoryItemReservationStatusRecord } from 'shared/records/InventoryItemReservationStatusRecord';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import { TaxonomiesCacheContext } from './context/TaxonomiesCacheContext';
import { isSingleOfType } from './utils/isSingleOfType';

const exclusions: (keyof IInventoryItemsQueryParams)[] = [];

interface IProps {
    params: IInventoryItemsQueryParams;
    onFilter: (params: IInventoryItemsQueryParams) => void;
    style?: React.CSSProperties;
    reloadTrigger: Trigger;
}

export const Filter = ({ params, onFilter, style, reloadTrigger }: IProps) => {
    const [data, reload] = useCacheContext(TaxonomiesCacheContext);

    useLazyEffect(() => {
        reload();
    }, [reloadTrigger]);

    if (data === undefined) {
        return <div></div>
    }

    return (
        <FilterBar
            onClear={() => onFilter(clearParams(params, exclusions))}
            isFiltered={isFiltered(params, exclusions)}
            style={style}>
            <div className="df-row-ac df-col-if-mobile">
                <RecordListFilterItem
                    icon={<HelpOutlineIcon />}
                    label="Type"
                    options={InventoryTypes}
                    selected={params.types as any}
                    record={InventoryTypeRecord}
                    setSelected={val => onFilter({ ...params, types: val })} />
                <StringListFilterItem
                    icon={<HelpOutlineIcon />}
                    label="Merk"
                    options={data.brands}
                    selected={params.brands}
                    setSelected={(val) => onFilter({ ...params, brands: val })} />
                <StringListFilterItem
                    icon={<HelpOutlineIcon />}
                    label="Model"
                    options={data.models}
                    selected={params.models}
                    setSelected={(val) => onFilter({ ...params, models: val })} />
                <RecordListFilterItem<InventoryItemReservationStatus>
                    icon={<HelpOutlineIcon />}
                    label="Reservatie status"
                    record={InventoryItemReservationStatusRecord}
                    options={InventoryItemReservationStatuses}
                    selected={params.reservationStatuses as any}
                    setSelected={(val) => onFilter({ ...params, reservationStatuses: val })} />
                {isSingleOfType(params, 'SkiBoot') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Maat"
                        options={data.skiBootSizes}
                        selected={params.sizes}
                        setSelected={(val) => onFilter({ ...params, sizes: val })} />}
                {isSingleOfType(params, 'SnowboardBoot') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Maat snowboard-bot"
                        options={data.snowboardBootSizes}
                        selected={params.sizes}
                        setSelected={(val) => onFilter({ ...params, sizes: val })} />}
                {isSingleOfType(params, 'SkiComfort') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiComfortLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {isSingleOfType(params, 'SkiVip') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiVipLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {isSingleOfType(params, 'SkiJunior') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte ski"
                        options={data.skiJuniorLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {isSingleOfType(params, 'Snowboard') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte snowboard"
                        options={data.snowboardLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
                {isSingleOfType(params, 'SnowboardJunior') &&
                    <NumberListFilterItemWithMinMax
                        icon={<HelpOutlineIcon />}
                        label="Lengte snowboard"
                        options={data.snowboardLengths}
                        selected={params.lengths}
                        setSelected={(val) => onFilter({ ...params, lengths: val })} />}
            </div>
        </FilterBar>
    );
}