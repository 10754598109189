import { Chip, useTheme } from '@material-ui/core';
import { Color } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { TooltipWithSpan } from '../TooltipWithSpan';
import { RectangularChipSize } from './RectangularChipSize';
import { toColor } from './toColor';

const commonStyle: React.CSSProperties = {
    borderRadius: 0,
    textTransform: 'uppercase',
    fontSize: '10px',
    height: '22px'
}

export interface IStatusChipProps {
    color?: Color;
    label: string;
    style?: React.CSSProperties;
    fill?: boolean;
    tooltip?: string;
    strikeThrough?: boolean;
    onClick?: VoidFunction;
    size?: RectangularChipSize;
}

export const RectangularChip = ({ tooltip, ...props }: IStatusChipProps) => {
    if (tooltip) {
        return (
            <TooltipWithSpan title={tooltip}>
                <Inner {...props} />
            </TooltipWithSpan>
        );
    } else {
        return <Inner {...props} />
    }
}

const Inner = ({ label, color = 'info', style, fill = false, strikeThrough = false, onClick, size = 'small' }: IStatusChipProps) => {
    const theme = useTheme();
    const selColor = useMemo(() => toColor(color, theme), [color, theme]);

    return (
        <Chip
            variant="outlined"
            size="small"
            label={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{label}</div>}
            className="remove-inner-span-padding"
            onClick={onClick}
            style={{
                ...commonStyle,
                paddingLeft: size === 'small' ? theme.spacing(1) : theme.spacing(0.5),
                paddingRight: size === 'small' ? theme.spacing(1) : theme.spacing(0.5),
                height: size === 'small' ? '22px' : '20px',
                color: fill ? theme.palette.primary.contrastText : selColor,
                borderColor: selColor,
                backgroundColor: fill ? selColor : 'inherit',
                textDecoration: strikeThrough ? 'line-through' : 'inherit',
                cursor: onClick ? 'pointer' : 'inherit',
                width: 'fit-content',
                ...style,
            }} />
    );
}