import React from 'react';
import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { TypographyX } from 'shared/TypographyX';
import { CloseButtonOnAbsolutePosition } from 'framework/components/CloseButtonOnAbsolutePosition';

interface IProps extends DialogProps {
    close: () => void;
}

export const VipComfortOrTourInformationDialog = ({ close, ...rest }: IProps) => {
    return (
        <Dialog
            {...rest}
            onClose={close}>
            <CloseButtonOnAbsolutePosition onClick={close} />
            <DialogTitle>{`Meer info VIP/Comfort/Toer`}</DialogTitle>
            <DialogContent
                dividers>
                <div className="df-col">
                    <TypographyX fontSize={24} bold toUpper>Comfort</TypographyX>
                    <TypographyX fontSize={20}>
                        Onze meest gekozen formule. Standaard is dit een comfortabele 
                        allround ski, die we selecteren op basis van je lengte, gewicht en niveau. 
                        Heb je specifieke voorkeuren, gelieve dit dan in het 
                        opmerkingenveld onder “Persoonsgegevens” te vermelden, 
                        of aan een van onze medewerkers.
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>Vip</TypographyX>
                    <TypographyX fontSize={20}>
                        Ben je een gevorderde skiër en graag met het 
                        nieuwste materiaal op pad? Kies dan voor onze VIP-formule. 
                        We kiezen zorgvuldig enkel het beste materiaal van de laatste nieuwe collecties!
                    </TypographyX>
                    <TypographyX fontSize={24} bold toUpper style={{ marginTop: '20px' }}>Toer</TypographyX>
                    <TypographyX fontSize={20}>
                        Onze toerski’s zijn steeds voorzien van pin-bindingen 
                        en vereisen dus de gepaste boots. 
                        Het pakket “SKI-TOER” is steeds inclusief toerski, 
                        vellen, crampons en telescopische stokken.
                    </TypographyX>
                </div>
            </DialogContent>
        </Dialog>
    );
}