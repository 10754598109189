import { Button, DialogActions, PropTypes } from '@material-ui/core';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { LoaderButton } from '../components/buttons/LoaderButton';

interface IProps {
    cancel: VoidFunction;
    submitText: string;
    isSubmitting: boolean;
    onSubmit: VoidFunction;
    cancelText?: string;
    colorSubmitButton?: PropTypes.Color;
    submitButtonType?: 'submit' | 'reset' | 'button';
    isValid?: boolean;
    variantSubmitButton?: 'text' | 'outlined' | 'contained';
    previous?: VoidFunction;
    hideSubmitButton?: boolean;
    forceEnableSubmit?: boolean;
    leftContent?: JSX.Element;
}

export const CancelSubmitDialogActions = ({ previous, cancel, cancelText, submitText, isSubmitting, onSubmit, colorSubmitButton = 'primary', submitButtonType = 'button', isValid = true, variantSubmitButton = 'outlined', hideSubmitButton = false, forceEnableSubmit = false, leftContent }: IProps) => {
    const strings = useLocalization();
    
    return (
        <DialogActions>
            {leftContent &&
                <>
                    {leftContent}
                    <div className="fg1"></div>
                </>
            }
            <Button onClick={cancel} color="primary" size='small'>{cancelText ?? strings.cancel}</Button>
            {previous && <Button onClick={previous} color="primary">{strings.previous}</Button>}
            {hideSubmitButton === false && <LoaderButton size='small' type={submitButtonType} disabled={isSubmitting || (isValid === false && forceEnableSubmit === false)} isLoading={isSubmitting} onClick={onSubmit} color={colorSubmitButton} variant={variantSubmitButton}>{submitText}</LoaderButton>}
        </DialogActions>
    );
}

