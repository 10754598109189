import React from 'react';
import { CustomersProviders } from './customers/context/CustomersProviders';
import { DashboardProviders } from './dashboard/context/DashboardProviders';
import { HolidayPeriodsProviders } from './holidays/context/HolidayPeriodsProviders';
import { InventoryItemsProviders } from './inventory/context/InventoryItemsProviders';
import { ReservationsProviders } from './reservations/context/ReservationsProviders';
import { UsersProviders } from './users/context/UsersProviders';

export const AdminProviders = ({ children }: any) => {
    return (
        <CustomersProviders>
            <DashboardProviders>
                <HolidayPeriodsProviders>
                    <InventoryItemsProviders>
                        <ReservationsProviders>
                            <UsersProviders>
                                {children}
                            </UsersProviders>
                        </ReservationsProviders>
                    </InventoryItemsProviders>
                </HolidayPeriodsProviders>
            </DashboardProviders>
        </CustomersProviders>
    );
}