import { FormControl, InputLabel } from '@material-ui/core';
import React from 'react';
import { FormErrorHelper, IMeta } from './FormErrorHelper';
import { IFormProps } from './IFormProps';

interface IProps<T> extends IFormProps<T> {
    children: any;
    meta: IMeta;
    enter?: VoidFunction;
    leave?: VoidFunction;
}

export const FormFilledControl = <T extends unknown>({ label, children, meta, enter, leave, helperText, ...rest }: IProps<T>) => {
    return (
        <FormControl
            variant="filled"
            onMouseEnter={enter}
            onMouseLeave={leave}
            {...rest}
            error={meta.error && meta.touched ? true : false}>
            <InputLabel>{label}</InputLabel>
            {children}
            <FormErrorHelper meta={meta} helperText={helperText} />
        </FormControl>
    );
}