import { Try } from "framework/Try";
import { useState } from "react";
import { useApiEffect } from "./useApiEffect";
import { useLazyEffect } from "./useLazyEffect";
import { Trigger } from "./useTrigger";

export function useApiEffectWithParams<TParams, TResult>(f: (params: TParams) => Promise<Try<TResult>>, defaultParams: TParams, reloadTrigger?: Trigger): [TParams, React.Dispatch<React.SetStateAction<TParams>>, TResult | undefined, VoidFunction] {
    const [params, setParams] = useState<TParams>(defaultParams);
    const [queryResult, reload] = useApiEffect(f, params);

    useLazyEffect(() => {
        if (reloadTrigger) {
            reload();
        }
    }, [reloadTrigger])

    return [params, setParams, queryResult, reload];
}