import { ListItem } from '@material-ui/core';
import React from 'react';
import { theme } from '../../../theme';
import { QuickFilterType } from './QuickFilterType';

interface IProps {
    qf: QuickFilterType;
    label: string;
    selQf: QuickFilterType | undefined;
    setQf: (val: QuickFilterType) => void;
}

export const quickFilterListItemStyle: React.CSSProperties = {
    color: theme.palette.primary.main,
    background: 'none'
}

export const QuickFilterListItem = ({ qf, label, selQf, setQf }: IProps) => {
    return (
        <ListItem style={qf === selQf ? { ...quickFilterListItemStyle } : {}} button onClick={() => setQf(qf)} selected={qf === selQf}>{label}</ListItem>
    );
}