import { IReservationLineWithInventoryItem } from 'gen/ApiClient';
import React from 'react';
import { ReservationLineWithInventoryItemMaterialIconWithColor } from './ReservationLineWithInventoryItemMaterialIconWithColor';

interface IProps {
    lines: IReservationLineWithInventoryItem[];
}

export const MaterialLines = ({ lines }: IProps) => {
    if (lines === undefined || lines.length === 0) {
        return <div></div>
    }

    return (
        <div className='df-row-ac'>
            {lines.map(t =>
                <ReservationLineWithInventoryItemMaterialIconWithColor
                    key={t.reservationLine.id}
                    line={t}
                    withoutDescription
                    style={{ marginRight: 4 }} />
            )}
        </div>
    );
}