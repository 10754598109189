import React from 'react';

const lineStyle: React.CSSProperties = {
    border: '1px solid',
    height: '1px',
    width: 'calc(100%/3 - 50px)',
    marginLeft: '25px',
    marginRight: '25px',
}

interface IProps {
    isDone: boolean,
}

export const ProgressLine = ({ isDone }: IProps) => {
    const setStyle: React.CSSProperties = {
        ...lineStyle,
        borderColor: isDone ? 'var(--color-green-bright)' : 'var(--color-grey-medium)',
    }
    return (
        <div style={setStyle}></div>
    );
}
