import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePickerOutlined } from 'framework/forms/FormDatePickerOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Gender, Genders, IPersonalInformationModel, IReservation, reservationsCommand_updatePersonalInformation } from 'gen/ApiClient';
import React from 'react';
import { FormGridContainer } from 'shared/forms/FormGridContainer';
import { FormGridItem } from 'shared/forms/FormGridItem';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { GenderRecord } from 'shared/records/GenderRecord';
import * as yup from 'yup';

const schema = yup.object<IPersonalInformationModel>({
    gender: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().required(),
    address: yup.string().required(),
    dateOfBirth: yup.date().required(),
    comments: yup.string().notRequired(),
});



const toModel = (item: IReservation): IPersonalInformationModel => {
    return {
        gender: item.gender,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phoneNumber: item.phoneNumber,
        address: item.address,
        dateOfBirth: item.dateOfBirth,
        comments: item.comments,
    }
}

interface IProps extends DialogProps {
    item: IReservation;
    cancel: () => void;
    confirm: () => void;
}

export const ReservationPersonalInformationForm = ({ item, confirm, cancel, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(reservationsCommand_updatePersonalInformation);

    const handleSubmit = async (values: IPersonalInformationModel, helpers: FormikHelpers<IPersonalInformationModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            confirm();
        }
    }

    return (
        <Formik<IPersonalInformationModel>
            initialValues={toModel(item)}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    {...rest}>
                    <DialogTitle>Vul persoonlijke gegevens in</DialogTitle>
                    <DialogContent
                        dividers>
                        <FormGridContainer withoutTopBottomMargin>
                            <FormGridItem fullWidth>
                                <FormOptionsField<IPersonalInformationModel, Gender>
                                    label="GESLACHT"
                                    name="gender"
                                    options={Genders}
                                    record={GenderRecord} />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel> label="VOORNAAM" name="firstName" />
                            </FormGridItem>
                            <FormGridItem>
                            <FormTextFieldOutlined<IPersonalInformationModel> label="ACHTERNAAM" name="lastName" />
                            </FormGridItem>
                            <FormGridItem>
                            <FormTextFieldOutlined<IPersonalInformationModel> label="E-MAILADRES" name="email" />
                            </FormGridItem>
                            <FormGridItem>
                            <FormTextFieldOutlined<IPersonalInformationModel> label="TELEFOONNUMMER" name="phoneNumber" />
                            </FormGridItem>
                            <FormGridItem fullWidth>
                            <FormTextFieldOutlined<IPersonalInformationModel> label="ADRES" name="address" placeholder="Geef straat, huisnummer en gemeente in" />
                            </FormGridItem>
                            <FormGridItem>
                            <FormDatePickerOutlined<IPersonalInformationModel> label="GEBOORTEDATUM" name="dateOfBirth" />
                            </FormGridItem>
                        </FormGridContainer>
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        cancel={cancel}
                        submitText="Pas aan"
                        isSubmitting={isSubmitting} />
                </Dialog>
            </Form>
        </Formik>
    );
}