import { ReservationStatus } from "gen/ApiClient";

// export type ReservationStatusType = 'In wachtrij' | 'Wachten op andere reservatie(s)' | 'Kan worden klaargezet' | 'Wachten op ophaling' | 'Verhuurd' | 'Teruggebracht';
// export const WaitingLineStatusType = 'In wachtrij';
// export const RentedStatusType = 'Verhuurd';
// export const ReturnedStatusType = 'Teruggebracht';
// export const ReservationStatuses: ReservationStatusType[] = ['In wachtrij', 'Wachten op andere reservatie(s)', 'Kan worden klaargezet', 'Wachten op ophaling', 'Verhuurd', 'Teruggebracht'];

export const ReservationStatusRecord: Record<ReservationStatus, string> = {
  CanBePrepared: "Kan worden klaargezet",
  Cancelled: "Geannuleerd",
  InWaitingLine: "In wachtrij",
  Rented: "Verhuurd",
  Returned: "Teruggebracht",
  WaitingCollection: "Wachten op ophaling",
  WaitingOtherReservation: "Wachten op andere reservatie(s)",
  InProgress: "In Verwerking",
};
