import { Badge, Button } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import React, { useState } from 'react';
import { theme } from '../../theme';
import { BadgeVariant } from './BadgeVariant';
import './Styles.scss';

const commonDivStyle: React.CSSProperties = {
    borderRadius: '5px',
    marginRight: '7px'
}

const filteredDivStyle: React.CSSProperties = {
    ...commonDivStyle,
    backgroundColor: green[600],
}

const regularDivStyle: React.CSSProperties = {
    ...commonDivStyle,
    backgroundColor: grey[400],
}

interface IProps {
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
    badgeContent: number;
    label: string;
    icon: React.ReactNode;
    style?: React.CSSProperties;
    badgeVariant?: BadgeVariant;
    disabled?: boolean;
}

export const FilterButton = ({ handleOpen, badgeContent, label, icon, style, badgeVariant = 'standard', disabled = false }: IProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <Button
            onClick={handleOpen}
            style={{
                marginRight: theme.spacing(1),
                maxWidth: '100%',
                ...style
            }}
            size="small"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}>
            <Badge badgeContent={badgeContent} variant={badgeVariant} max={9} color="error" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                <div className="df-col jc-c filter-button-icon-div" style={badgeContent > 0 ? filteredDivStyle : regularDivStyle}>
                    {icon}
                </div>
            </Badge>
            <div
                style={{
                    color: isHovered || badgeContent !== 0 ? 'inherit' : grey[500],
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '14px',
                    whiteSpace: 'pre',
                    fontStyle: disabled ? 'italic' : 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                {label}
            </div>
        </Button>
    );
}