import { nameOf } from "../../utils/nameOf";
import { makeANumber } from "../../utils/makeANumber";

export const findFieldErrorMinStep = <TModel extends unknown>(errorProperties: string[], record: Record<number, (keyof TModel)[]>): number => {
    const keys = Object.keys(record);
    console.log(keys);
    let minStep = -1;
    for (const key in keys) {
        console.log(key);
        const props = record[key];
        console.log(props);
        const any = props.length > 0 && props.map(t => errorProperties.indexOf(nameOf(t)) > -1).reduce((acc, current) => acc === true ? acc : current);
        if (any && (minStep > makeANumber(key) || minStep === -1)) {
            minStep = makeANumber(key);
        }
    }
    return minStep;
}