import React from 'react';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    text?: string;
    showBackIcon?: boolean;
}

export const TextLinkButton = ({ children, ...rest }: IProps) => {
    return (
        <button type="button" {...rest} className={`text-link-button ${rest.className}`}>{children}</button>
    );
}