import { InventoryType } from "gen/ApiClient";

export const InventoryTypeRecord: Record<InventoryType, string> = {
    SkiBoot: "Ski-bot",
    SkiComfort: 'Ski-Comfort',
    SkiJunior: 'Ski-Junior',
    SkiVip: 'Ski-Vip',
    Snowboard: 'Snowboard',
    SnowboardBoot: 'Snowboard-bot',
    SnowboardJunior: 'Snowboard-Junior',
    SkiBootTour: 'Ski-bot (Toer)',
    SkiTour: 'Ski-Toer',
}