import { Card, CardHeader, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IUserDto, usersCommand_delete } from 'gen/ApiClient';
import React from 'react';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PageWithSearch } from '../PageWithSearch';
import { QueryContext } from './context/QueryContext';
import { UserForm } from './forms/UserForm';

export const UsersList = () => {
    const { params, setParams, queryResult, reload } = useQueryContext(QueryContext);
    const { open, confirm, cancel } = useDialogsContext(reload);

    const onDelete = (item: IUserDto) => {
        open(<AlertDialog
            open
            acceptText="Ja, verwijder!"
            rejectText="Annuleer"
            title="Ben je zeker?"
            content="Wil je deze gebruiker verwijderen?"
            reject={cancel}
            accept={confirm}
            acceptF={() => usersCommand_delete(item.id)} />);
    }


    const headers = [
        createSortableHeader<IUserDto>('Naam', 'name', t => t.name),
        createEmptyHeader<IUserDto>('actions', t =>
            <div className="df-row-ac jc-e" style={{ marginRight: 6 }}>
                <ColorOnHoverIconButton onClick={() => onDelete(t)} color="secondary" edge="end">
                    <DeleteOutlineIcon />
                </ColorOnHoverIconButton>
            </div>
        )
    ]

    const onNew = () => {
        open(<UserForm
            open
            cancel={cancel}
            confirm={confirm} />);
    }

    return (
        <PageWithSearch
            onRefresh={reload}
            searchString={params.searchString}
            onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
            placeholder={`Zoek op gebruikersnaam...`}>
            <div className="df-col">
                <PrimaryContainedButton size='small' className='w-fc' onClick={onNew} style={{ marginBottom: 20 }}>+ Nieuwe gebruiker</PrimaryContainedButton>
                {queryResult &&
                    <Card>
                        <CardHeader title="Gebruikers" />
                        <Divider />
                        <PageableTable<IUserDto>
                            queryResult={queryResult}
                            headers={headers}
                            getKey={t => t.id}
                            pageParams={params.pageParams}
                            sortParams={params.sortParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })} />
                    </Card>}
            </div>
        </PageWithSearch>
    );
}