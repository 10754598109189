import React from 'react';
import { ColorX } from './ColorX';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    fontSize: number;
    bold?: boolean;
    center?: boolean;
    toUpper?: boolean;
    color?: ColorX;
    multiLine?: boolean;
    lineHeight?: number;
}

const mapToLineHeight = (fontSize: number): number => {
    if (fontSize >= 80) {
        return 96;
    } else if (fontSize >= 34) {
        return 41;
    } else if (fontSize >= 28) {
        return 34;
    } else if (fontSize >= 24) {
        return 29;
    } else if (fontSize >= 20) {
        return 24;
    } else if (fontSize >= 18) {
        return 22;
    } else {
        return 0;
    }
}

export const TypographyX = ({ children, fontSize, bold = false, center = false, toUpper = false, style, color = 'color-black', multiLine = false, lineHeight, ...rest }: IProps) => {
    const setStyle: React.CSSProperties = {
        fontSize: fontSize + 'px',
        lineHeight: lineHeight ? `${lineHeight}px` : mapToLineHeight(fontSize) !== 0 ? (mapToLineHeight(fontSize) + 'px') : 'inherit',
        textAlign: center ? 'center' : 'inherit',
        textTransform: toUpper ? 'uppercase' : 'none',
        color: `var(--${color})`,
        whiteSpace: multiLine ? 'pre-line' : 'inherit',
        ...style,
    };
    return (
        <div style={{ fontFamily: bold ? 'var(--bold-font-family)' : 'var(--font-family)', ...setStyle }} {...rest}>{children}</div>
    );
}