import { Card, CardHeader, Divider } from '@material-ui/core';
import { FormControlLabelSwitch } from 'framework/components/FormControlLabelSwitch';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { createHeader } from 'framework/table/createHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatDate } from 'framework/utils/date/formatDate';
import { customersQuery_single, IReservationDto, IReservationsQueryParams, reservationsQuery_queryForCustomer } from 'gen/ApiClient';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { formatReservationPeriod } from 'shared/utils/formatReservationPeriod';
import * as routes from '../../../../routes';
import { PageWithoutSearch } from '../../PageWithoutSearch';
import { DefaultReservationsQueryParams } from '../../reservations/context/ReservationsQueryProvider';
import { Filter } from '../../reservations/Filter';
import { MaterialLines } from '../../reservations/MaterialLines';
import { ReservationStatusChip } from '../../reservations/ReservationStatusChip';
import { CustomerDetailHeader } from './CustomerDetailHeader';

export const CustomerDetail = () => {
    const { id } = useParams<IRouteParamsWithId>();
    const [customer, reloadDetail] = useApiEffect(customersQuery_single, id);
    const [trigger, hitTrigger] = useTrigger();
    const [params, setParams, queryResult] = useApiEffectWithParams(pars => reservationsQuery_queryForCustomer(id, pars), DefaultReservationsQueryParams, trigger);
    const { push } = useHistory();

    useLazyEffect(() => {
        reloadDetail();
    }, [trigger]);

    const headers = [
        createSortableHeader<IReservationDto>('Datum aanmaak', 'created', t => formatDate(t.created)),
        createHeader<IReservationDto>('Status', t => <ReservationStatusChip item={t.item} />),
        createSortableHeader<IReservationDto>('Reservatienummer', 'number', t => t.number),
        createHeader<IReservationDto>('Periode', t => formatReservationPeriod(t.item.fromDate, t.item.toDate)),
        createHeader<IReservationDto>('Materiaal', t => <MaterialLines lines={t.lines ?? []} />),
        createHeader<IReservationDto>('Adres', t => t.item.address),
        createHeader<IReservationDto>('Tel.nr', t => t.item.phoneNumber),
        createEmptyHeader<IReservationDto>('actions', t =>
            <div className="df-row-ac jc-e">
                <PrimaryContainedButton size="small" onClick={() => push(routes.calcReservationDetailRoute(t.id))}>Details</PrimaryContainedButton>
            </div>
        )
    ]

    if (customer === undefined || queryResult === undefined) {
        return <div></div>
    }

    const onFilter = (pars: IReservationsQueryParams) => {
        setParams(onResetToPage1(pars));
    }

    return (
        <PageWithoutSearch onGoBack={() => push(routes.CustomersRoute)} backText='Terug naar personen' onRefresh={hitTrigger}>
            <div className="df-col stretch-ver">
                <CustomerDetailHeader
                    customer={customer}
                    style={{ marginBottom: 30 }} />
                <Card style={{ marginBottom: 30 }}>
                    <CardHeader title="Reservaties" />
                    <Divider />
                    <div className='df-row-ac jc-sb'>
                        <Filter
                            params={params}
                            onFilter={onFilter} />
                        <FormControlLabelSwitch
                            isChecked={params.isAlsoShowCancelled}
                            toggle={() => onFilter({ ...params, isAlsoShowCancelled: !params.isAlsoShowCancelled })}
                            label={`Bekijk ook geannuleerde`} />
                    </div>
                    <Divider />
                    {queryResult.unfilteredCount !== 0 &&
                        <PageableTable<IReservationDto>
                            queryResult={queryResult}
                            headers={headers}
                            getKey={t => t.id!}
                            pageParams={params.pageParams}
                            sortParams={params.sortParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })} />
                    }
                    {queryResult.unfilteredCount === 0 &&
                        <NoRecordsFound withPadding style={{ color: 'red' }}>Geen reservaties gevonden</NoRecordsFound>
                    }
                </Card>
            </div>
        </PageWithoutSearch>
    );
}

