import { Divider, Menu, MenuItem } from "@material-ui/core";
import { AlertDialog } from "framework/dialogs/AlertDialog";
import { useAnchorElement } from "framework/hooks/useAnchorElement";
import {
  IReservation,
  IReservationLine,
  reservationsCommand_handoverOwnBoot,
  reservationsCommand_undoHandoverOwnBoot,
  reservationsQuery_downloadBootLabel,
} from "gen/ApiClient";
import { useDownloadOpenPdfInNewTab } from "gen/useDownloadOpenPdfInNewTab";
import React from "react";
import { PrimaryContainedButton } from "shared/buttons/PrimaryContainedButton";
import { useDialogsContext } from "shared/dialogs/useDialogsContext";

interface IProps {
  reservation: IReservation;
  line: IReservationLine;
  reload: VoidFunction;
}

export const OwnBootActionsButton = ({ reservation, line, reload }: IProps) => {
  const [anchor, openAnchor, closeAnchor] = useAnchorElement();
  const { open, confirm, cancel } = useDialogsContext(reload);
  const [downloadPdf, isExporting] = useDownloadOpenPdfInNewTab(
    reservationsQuery_downloadBootLabel
  );

  const onRegisterHandover = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, registreer aflevering en druk bon af!"
        rejectText="Annuleer"
        title="Bevestiging"
        content="Heeft de klant de botten afgeleverd aan de Zwerver?"
        reject={cancel}
        acceptF={async () => {
          await downloadPdf(reservation.id, line.id);
          return reservationsCommand_handoverOwnBoot(reservation.id, line.id);
        }}
        accept={confirm}
      />
    );
  };

  const onUndoHandover = () => {
    open(
      <AlertDialog
        open
        acceptText="Ja, ongedaan maken!"
        rejectText="Annuleer"
        title="Bevestiging"
        content={`Aflevering botten ongedaan maken?\n(de klant heeft de botten nog niet overhandigd)`}
        reject={cancel}
        acceptF={() =>
          reservationsCommand_undoHandoverOwnBoot(reservation.id, line.id)
        }
        accept={confirm}
      />
    );
  };

  const onPrint = async () => {
    await downloadPdf(reservation.id, line.id);
  };

  return (
    <>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={closeAnchor}
        keepMounted
      >
        <div className="df-col">
          {line.isOwnBootHandedOver === false && reservation.canAddLine && (
            <MenuItem
              dense
              onClick={() => {
                closeAnchor();
                onRegisterHandover();
              }}
            >{`Aflevering Botten registreren`}</MenuItem>
          )}
          {line.isOwnBootHandedOver === true && reservation.canAddLine && (
            <MenuItem
              dense
              onClick={() => {
                closeAnchor();
                onUndoHandover();
              }}
            >{`Aflevering Botten ongedaan maken`}</MenuItem>
          )}
          <Divider variant="middle" />
          <MenuItem
            dense
            onClick={() => {
              closeAnchor();
              onPrint();
            }}
          >{`Label afprinten`}</MenuItem>
        </div>
      </Menu>
      <PrimaryContainedButton
        size="small"
        style={{ width: 100 }}
        onClick={openAnchor}
        isLoading={isExporting}
      >
        {`Acties...`}
      </PrimaryContainedButton>
    </>
  );
};
