import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormNumberFieldOutlined } from 'framework/forms/FormNumberFieldOutlined';
import { handleValidateResponse } from 'framework/forms/utils/handleValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IReservation, reservationsCommand_setPrice } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

interface IModel {
    value: number;
}

const schema = yup.object<IModel>({
    value: yup.number(),
});

interface IProps extends DialogProps {
    item: IReservation;
    cancel: () => void;
    confirm: () => void;
}

export const PriceForm = ({ item, confirm, cancel, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(reservationsCommand_setPrice);

    const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
        const r = await submit(item.id, values.value);
        if (handleValidateResponse<IModel>(r, helpers, 'value')) {
            confirm();
        }
    }

    return (
        <Formik<IModel>
            validateOnMount
            initialValues={{ value: item.overridePrice ?? 0 }}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form>
                    <Dialog
                        {...rest}>
                        <DialogTitle>Vul prijs in (vul 0 in voor korting weg te laten)</DialogTitle>
                        <DialogContent
                            dividers>
                            <FormNumberFieldOutlined<IModel>
                                label="PRIJS NA KORTING"
                                name="value"
                                suffix=" EURO"
                                required />
                        </DialogContent>
                        <CancelSubmitFormDialogActions
                            cancel={cancel}
                            submitText="Wijzig"
                            isSubmitting={isSubmitting} />
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
}