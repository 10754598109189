import { Dialog, DialogContent, DialogProps, DialogTitle, Divider } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { InventoryType, IReservation, reservationsCommand_addReservationLine } from 'gen/ApiClient';
import React from 'react';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { InventoryTypeRecord } from 'shared/records/InventoryTypeRecord';
import * as yup from 'yup';

interface IModel {
    type: string;
}

const schema = yup.object<IModel>({
    type: yup.string().required(),
});

const initValues: IModel = {
    type: undefined as any,
}

interface IProps extends DialogProps {
    reservation: IReservation;
    cancel: () => void;
    confirm: () => void;
}

export const AddReservationLineForm = ({ reservation, cancel, confirm, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(reservationsCommand_addReservationLine);
    const notify = useSnackbarNotify();

    const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
        const r = await submit(reservation.id, values.type);
        handleValidateResponseBase(r, e => notify(e, 'error'), () => confirm());
    }

    return (
        <Formik<IModel>
            validateOnMount
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form className="stretch-ver df-col jc-sb" autoComplete="off">
                    <Dialog
                        {...rest}
                        maxWidth="xl">
                        <DialogTitle>Voeg materiaal toe</DialogTitle>
                        <DialogContent
                            dividers
                            className='df-col'>
                            <FormOptionsField<IModel, InventoryType>
                                name="type"
                                label="Type"
                                options={['SkiJunior', 'SnowboardJunior']}
                                record={InventoryTypeRecord}
                                hideLabel />
                            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                            <FormOptionsField<IModel, InventoryType>
                                name="type"
                                label="Type"
                                options={['SkiComfort', 'SkiVip', 'SkiTour', 'Snowboard']}
                                record={InventoryTypeRecord}
                                hideLabel />
                            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                            <FormOptionsField<IModel, InventoryType>
                                name="type"
                                label="Type"
                                options={['SkiBoot', 'SkiBootTour', 'SnowboardBoot']}
                                record={InventoryTypeRecord}
                                hideLabel />
                        </DialogContent>
                        <CancelSubmitFormDialogActions
                            cancel={cancel}
                            submitText="Voeg toe"
                            isSubmitting={isSubmitting} />
                    </Dialog>

                </Form>
            )}
        </Formik>
    );
}