import React from 'react';
import { TypographyX } from '../TypographyX';

const baseStyle: React.CSSProperties = {
    height: '80px',
    width: '200px',
    border: '2px solid var(--color-green-bright)',
    borderRadius: '2px',
    cursor: 'pointer',
}

interface ISelectProps {
    text: string;
    isSelected: boolean;
    style?: React.CSSProperties;
    onSelect: () => void;
}

export const SelectOption = ({ style, text, isSelected, onSelect }: ISelectProps) => {
    return (
        <div
            className="df-col-ac jc-c"
            style={{ ...baseStyle, ...style, backgroundColor: isSelected ? 'var(--color-green-bright)' : 'inherit' }}
            onClick={onSelect}>
            <TypographyX fontSize={24} bold center toUpper>{text}</TypographyX>
        </div>
    );
}