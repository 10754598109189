import { Button } from '@material-ui/core';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { requestsCommand_new } from 'gen/ApiClient';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { TypographyX } from 'shared/TypographyX';
import * as routes from '../routes';
import { AuthContext } from './auth/AuthContext';
import { Logo } from './logo/Logo';

export const Welcome = () => {
    const { push } = useHistory();
    const { logout } = useContext(AuthContext);
    const [create, isSubmitting] = useFormSubmit(requestsCommand_new);

    useEffect(() => {
        logout();
        // eslint-disable-next-line
    }, []);

    const onNewRequest = async () => {
        const r = await create();
        if (r.isSuccess) {
            push(routes.calcRequestRoute(r.result));
        }
    }

    return (
        <div className="stretch-ver apply-background df-col">
            <div className="df-row-ac jc-e">
                <div></div>
                <Button variant="outlined" size="small" onClick={() => push(routes.LoginRoute)} style={{ margin: 20 }}>inloggen</Button>
            </div>
            <div className="df-col-ac stretch-ver jc-c" style={{ paddingBottom: '100px' }}>
                <Logo width={240} />
                <TypographyX fontSize={80} bold className="title" center style={{ marginTop: '60px', marginBottom: '20px' }} toUpper>Huur hier je ski- of snowboardmateriaal!</TypographyX>               
                <PrimaryContainedButton isLoading={isSubmitting} onClick={onNewRequest} size='large'>GA VAN START</PrimaryContainedButton>
            </div>
        </div>
    );
}

