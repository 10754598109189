import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { IInventoryItem, IInventoryItemsQueryParams, inventoryItemsQuery_concurrencyToken, inventoryItemsQuery_query, IQueryResult } from "gen/ApiClient";
import { inventoryItemsQueryParamsLsKey } from "localStorageKeys";
import React from "react";
import { QueryContext } from "./QueryContext";

const DefaultParams: IInventoryItemsQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
    stockLocations: [],
    brands: [],
    models: [],
    types: [],
    sizes: [],
    lengths: [],
    reservationStatuses: [],
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<IInventoryItem>, IInventoryItemsQueryParams>
            defaultParams={DefaultParams}
            queryF={inventoryItemsQuery_query}
            concurrencyF={inventoryItemsQuery_concurrencyToken}
            localStorageKey={inventoryItemsQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
