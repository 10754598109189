import { DialogTitle, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { FormStepper } from "../forms/FormStepper";

interface IProps {
    title: string;
    step: number;
    labels: string[];
    children?: any;
}

export const DialogTitleWithFormStepper = ({ title, step, labels, children }: IProps) => {
    const theme = useTheme();

    return (
        <DialogTitle style={{ marginBottom: 0, paddingBottom: 0 }} disableTypography>
            <Typography variant="h6">{title}</Typography>
            <FormStepper activeStep={step} labels={labels} style={{ flexGrow: 1, paddingTop: theme.spacing(2), marginLeft: '-8px', marginRight: '-8px' }} />
            {children && children}
        </DialogTitle>
    )
}




