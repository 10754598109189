import { IRequest } from "gen/ApiClient";
import React from "react";
import { TypographyX } from "shared/TypographyX";
import { Page } from "../Page";
import "../Styles.scss";

interface IProps {
  request: IRequest;
}

export const Confirmation = ({ request }: IProps) => {
  return (
    <Page
      step={5}
      onGotoStep={() => {}}
      item={request}
      hideProgress
      hideSidebar
    >
      <div className="df-col-ac jc-c fg1">
        {/* eslint-disable-next-line */}
        <span className="hooray" role="img">
          🙌
        </span>
        <TypographyX fontSize={80} bold toUpper>
          We helpen je zo snel mogelijk verder!
        </TypographyX>
        <TypographyX
          fontSize={40}
          multiLine
          style={{ marginTop: "50px" }}
          center
        >
          Je reservatienummer is <strong>{request.confirmationNumber}</strong>
          <br />
          Alle gegevens zijn correct ingevuld!
        </TypographyX>
        <TypographyX fontSize={24} style={{ marginTop: "60px" }}>
          Check je mailbox voor een bevestigingsmail{" "}
        </TypographyX>
        <TypographyX fontSize={20} style={{ marginTop: "60px" }}>
          De zwerver is niet verantwoordelijk als deze gegevens foutief zijn
          ingevuld.
        </TypographyX>
      </div>
    </Page>
  );
};
