import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

export type BackButtonTextType = 'Terug' | 'Annuleer' | 'terug' | 'annuleer';

interface IProps {
    style?: React.CSSProperties;
    onGoBack: () => void;
    text: BackButtonTextType;
    hideArrow?: boolean;
}

export const BackButton = ({ style, onGoBack, text, hideArrow = false }: IProps) => {
    return (
        <Button
            variant="outlined"
            style={{ minWidth: 170, ...style }}
            onClick={onGoBack}
            startIcon={hideArrow ? undefined : <ArrowBackIcon />}>
            {text}
        </Button>
    );
}