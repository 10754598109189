import { formatDate } from 'framework/utils/date/formatDate';
import { ICustomer } from 'gen/ApiClient';
import React from 'react';
import { HeaderItem } from '../../../../shared/HeaderItem';

interface IProps {
    customer: ICustomer;
    style?: React.CSSProperties;
}

export const CustomerDetailHeader = ({ customer, style }: IProps) => {
    return (
        <div className="df-row jc-sb" style={style}>
            <HeaderItem label="E-mail" value={customer.email} />
            <HeaderItem label="Naam" value={`${customer.lastName}, ${customer.firstName}`} />
            <HeaderItem label="Adres" value={customer.address} />
            <HeaderItem label="Tel." value={customer.phoneNumber} />
            <HeaderItem label="Geboortedatum" value={formatDate(customer.dateOfBirth)} />
        </div>
    );
}