import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormNumberFieldOutlined } from 'framework/forms/FormNumberFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ILengthWeightLevelModel, IReservation, LevelType, LevelTypes, reservationsCommand_updateWeightAndLength } from 'gen/ApiClient';
import React from 'react';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { LevelTypeRecord } from 'shared/records/LevelRecord';
import * as yup from 'yup';

const schema = yup.object<ILengthWeightLevelModel>({
    lengthInCm: yup.number().required(),
    weightInKg: yup.number().required(),
    level: yup.string().required(),
});

interface IProps extends DialogProps {
    item: IReservation;
    cancel: () => void;
    confirm: () => void;
}

export const LengthWeightLevelForm = ({ item, confirm, cancel, ...rest }: IProps) => {
    const [submit, isSubmitting] = useFormSubmit(reservationsCommand_updateWeightAndLength);

    const handleSubmit = async (values: ILengthWeightLevelModel, helpers: FormikHelpers<ILengthWeightLevelModel>) => {
        const r = await submit(item.id, values);
        if (handleFormResponse(r, helpers)) {
            confirm();
        }
    }

    return (
        <Formik<ILengthWeightLevelModel>
            initialValues={{ weightInKg: item.weightInKg, lengthInCm: item.lengthInCm, level: item.level }}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form>
                    <Dialog
                        {...rest}>
                        <DialogTitle>Wijzig lengte/gewicht/niveau</DialogTitle>
                        <DialogContent
                            dividers>
                            <div className="df-col">
                                <FormOptionsField<ILengthWeightLevelModel, LevelType>
                                    name="level"
                                    options={LevelTypes}
                                    record={LevelTypeRecord}
                                    label="NIVEAU" />
                                <FormNumberFieldOutlined<ILengthWeightLevelModel>
                                    label="LENGTE (CM)"
                                    name="lengthInCm"
                                    suffix="cm"
                                    required />
                                <FormNumberFieldOutlined<ILengthWeightLevelModel>
                                    label="GEWICHT (KG)"
                                    name="weightInKg"
                                    suffix="kg"
                                    required />
                            </div>
                        </DialogContent>
                        <CancelSubmitFormDialogActions
                            cancel={cancel}
                            submitText="Pas aan"
                            isSubmitting={isSubmitting} />
                    </Dialog>

                </Form>
            )}
        </Formik>
    );
}