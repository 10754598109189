import { IRequest } from 'gen/ApiClient';
import { Header } from './Header';
import { SideBar } from './SideBar';
import React from 'react';
import { ProgressBar } from './progress/ProgressBar';

interface IProps {
    item: IRequest;
    step: number;
    children: any;
    hideProgress?: boolean;
    hideSidebar?: boolean;
    onGotoStep: (step: number) => void;
}

export const Page = ({ item, step, children, hideProgress, hideSidebar, onGotoStep }: IProps) => {
    return (
        <div className="df-col h100">
            <Header step={step} />
            <div className="df-row h100" style={{ overflow: 'hidden' }}>
                <div className='df-col fg1' style={{ padding: 30, overflowY: 'auto' }}>
                    {hideProgress !== true && <ProgressBar step={step} />}
                    {children}
                </div>
                {hideSidebar !== true && <SideBar request={item} onGotoStep={onGotoStep} />}
            </div>
        </div>
    );
}