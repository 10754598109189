import { useState } from "react";

// export function useAnchorElement(): [];


// export function useAnchorElement(): [null | HTMLElement, (event: React.MouseEvent<HTMLElement>) => void, VoidFunction] {
//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

//     const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//     return [anchorEl, handleClick, handleClose];
// }

export function useAnchorElement(): [null | HTMLElement, (event: React.MouseEvent<HTMLElement>) => void, VoidFunction, undefined];
export function useAnchorElement<S>(initialValue: S): [null | HTMLElement, (event: React.MouseEvent<HTMLElement>, value: S) => void, VoidFunction, S];

export function useAnchorElement<S>(initialValue?: S): [null | HTMLElement, (event: React.MouseEvent<HTMLElement>, value: S) => void, VoidFunction, S | undefined] {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [state, setState] = useState<S | undefined>(initialValue);

    const handleClick = (event: React.MouseEvent<HTMLElement>, value: S) => {
        setState(value);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setState(undefined);
    };
    return [anchorEl, handleClick, handleClose, state];
}