import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { IQueryResult, IUserDto, IUsersQueryParams, usersQuery_concurrencyToken, usersQuery_query } from "gen/ApiClient";
import { usersQueryParamsLsKey } from "localStorageKeys";
import React from "react";
import { QueryContext } from "./QueryContext";

export const DefaultParams: IUsersQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<IUserDto>, IUsersQueryParams>
            defaultParams={DefaultParams}
            queryF={usersQuery_query}
            concurrencyF={usersQuery_concurrencyToken}
            localStorageKey={usersQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
