import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import React from 'react';
import { TypographyX } from 'shared/TypographyX';

interface IProps {
    label: string;
    value: string | number | undefined;
    onEdit?: () => void;
    children?: JSX.Element;
}
export const HeaderItem = ({ label, value, onEdit, children }: IProps) => {
    return (
        <div className="df-col header-item">
            <TypographyX fontSize={18} color="color-grey-dark" bold toUpper style={{ marginBottom: '5px' }}>{label}</TypographyX>
            <div className="df-row-ac">
                <TypographyX fontSize={30} bold toUpper>{value}</TypographyX>
                {onEdit &&
                    <ColorOnHoverIconButton color="primary" style={{ marginLeft: 8 }} onClick={onEdit} size="small">
                        <EditOutlinedIcon />
                    </ColorOnHoverIconButton>}
            </div>
            {children}
        </div>
    );
}