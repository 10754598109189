import { Form, Formik, FormikHelpers } from 'formik';
import { FormDatePickerOutlined } from 'framework/forms/FormDatePickerOutlined';
import { FormTextFieldOutlined } from 'framework/forms/FormTextFieldOutlined';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { Gender, Genders, IPersonalInformationModel, IRequest, requestsCommand_updatePersonalInformation } from 'gen/ApiClient';
import React from 'react';
import { PrimaryContainedButton } from 'shared/buttons/PrimaryContainedButton';
import { FormGridContainer } from 'shared/forms/FormGridContainer';
import { FormGridItem } from 'shared/forms/FormGridItem';
import { FormOptionsField } from 'shared/forms/FormOptionsField';
import { GenderRecord } from 'shared/records/GenderRecord';
import { TypographyX } from 'shared/TypographyX';
import * as yup from 'yup';
import { BackButton } from '../BackButton';
import { Page } from '../Page';

const schema = yup.object<IPersonalInformationModel>({
    gender: yup.string().required(`Selecteer een geslacht`),
    firstName: yup.string().required(`Vul voornaam in`),
    lastName: yup.string().required(`Vul achternaam in`),
    email: yup.string().email().required(`Vul e-mail adres in`),
    phoneNumber: yup.string().required(`Vul tel. nummer in`),
    address: yup.string().required(`Vul adres in`),
    dateOfBirth: yup.date().required(`Vul geboortedatum in`),
    comments: yup.string().notRequired(),
});

const EmptyValues: IPersonalInformationModel = {
    gender: undefined as any,
    firstName: undefined as any,
    lastName: undefined as any,
    email: undefined as any,
    phoneNumber: undefined as any,
    address: undefined as any,
    dateOfBirth: undefined as any,
    comments: '',
}

interface IProps {
    request: IRequest;
    onNext: VoidFunction;
    onPrevious: VoidFunction;
    onGotoStep: (step: number) => void;
}

export const PersonalInformation = ({ request, onNext, onPrevious, onGotoStep }: IProps) => {
    const [update, isSubmitting] = useFormSubmit(requestsCommand_updatePersonalInformation);

    const handleSubmit = async (values: IPersonalInformationModel, helpers: FormikHelpers<IPersonalInformationModel>) => {
        const r = await update(request.id, values);
        if (handleFormResponse(r, helpers)) {
            onNext();
        }
    }

    return (
        <Formik<IPersonalInformationModel>
            validateOnMount
            initialValues={request.personalInformation ?? EmptyValues}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(props) => (
                <Form className="v100 h100" autoComplete="off">
                    <Page step={2} onGotoStep={onGotoStep} item={request}>
                        <TypographyX fontSize={28} bold toUpper style={{ marginBottom: '30px' }}>vul je persoonlijke gegevens in</TypographyX>
                        <FormGridContainer>
                            <FormGridItem fullWidth>
                                <FormOptionsField<IPersonalInformationModel, Gender>
                                    label="Geslacht"
                                    name="gender"
                                    record={GenderRecord}
                                    options={Genders} />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    name="firstName"
                                    label="Voornaam"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    name="lastName"
                                    label="Achternaam"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    name="email"
                                    label="E-mailadres"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    name="phoneNumber"
                                    label="Telefoonnummer"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    label="Adres (Straat nr. 25, 2000 Herentals)"
                                    name="address"
                                    placeholder="Geef straat, huisnummer en gemeente in"
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormDatePickerOutlined<IPersonalInformationModel>
                                    name="dateOfBirth"
                                    label="Geboortedatum"
                                    openTo='year'
                                    required />
                            </FormGridItem>
                            <FormGridItem>
                                <FormTextFieldOutlined<IPersonalInformationModel>
                                    label="Opmerking"
                                    name="comments"
                                    multiline
                                    rows={2}
                                    placeholder="Geef hier bv. de groepsnaam in als je bij een groep hoort" />
                            </FormGridItem>
                        </FormGridContainer>
                        <div className='fg1'></div>
                        <div className="df-row-ac jc-sb" style={{ marginTop: 30 }}>
                            <BackButton onGoBack={onPrevious} text="Terug" />
                            <PrimaryContainedButton isLoading={isSubmitting} onClick={() => props.submitForm()}>Volgende stap</PrimaryContainedButton>
                        </div>
                    </Page>
                </Form>
            )}
        </Formik>
    );
}
