import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

interface IProps {
    onClick: VoidFunction;
    style?: React.CSSProperties;
}

export const InfoIconButtonWithoutPadding = ({ onClick, style }: IProps) => {
    return (
        <IconButton onClick={onClick} style={{ ...style, padding: 0, marginLeft: 8 }}>
            <InfoIcon />
        </IconButton>
    );
}