import { customersQuery_concurrencyToken, customersQuery_query, ICustomer, ICustomersQueryParams, IQueryResult } from "gen/ApiClient";
import React from "react";
import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { QueryContext } from "./QueryContext";
import { customersQueryParamsLsKey } from "localStorageKeys";

export const DefaultParams: ICustomersQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'id' },
    pageParams: { index: 1, size: 10 },
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<ICustomer>, ICustomersQueryParams>
            defaultParams={DefaultParams}
            queryF={customersQuery_query}
            concurrencyF={customersQuery_concurrencyToken}
            localStorageKey={customersQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
