import React from 'react';
import { IPageParams } from '../types/IPageParams';
import { IQueryResult } from '../types/IQueryResult';
import { ISortParams } from '../types/ISortParams';
import { IHeader } from './IHeader';
import { TablePaging } from './TablePaging';
import { TableWithHeadersAndValues } from './TableWithHeadersAndValues';

interface IProps<T> {
    queryResult: IQueryResult<T>;
    headers: IHeader<T>[];
    getKey: (inst: T) => string;
    sortParams: ISortParams<T>;
    pageParams: IPageParams;
    setParams: (sortParams: ISortParams<T>, pageParams: IPageParams) => void;
    padding?: number;
    expandableContent?: (value: T) => JSX.Element;
    expandMultiple?: boolean;
}

export const PageableTable = <T extends unknown>({ queryResult, sortParams, pageParams, setParams, ...rest }: IProps<T>) => {
    return (
        <>
            <TableWithHeadersAndValues
                values={queryResult.values ?? []}
                sortParams={sortParams}
                setSortParams={sp => setParams(sp, { index: 1, size: pageParams.size })}
                {...rest} />
            <TablePaging
                countResults={queryResult.filteredCount}
                pageSize={queryResult.pageSize}
                page={queryResult.zeroBasedPageIndex}
                pageParams={pageParams}
                setPageParams={(pageParams) => setParams(sortParams, pageParams)} />
        </>
    );
}

