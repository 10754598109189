import { Checkbox, Divider, IconButton, InputBase, ListItemText, ListSubheader, MenuItem, Typography } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useLocalization } from 'localization/useLocalization';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { IOption } from '../IOption';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';

interface IProps<T extends string | number | symbol> {
    options: IOption<T>[];
    selected: T[];
    setSelected: (selected: T[]) => void;
    label: string;
    icon: React.ReactNode;
    title?: string;
    orderRecord?: Record<T, number>;
    skipSort?: boolean;
}

export const ListFilterItem = <T extends string | number | symbol>({ options, selected, setSelected, label, icon, title, orderRecord, skipSort }: IProps<T>) => {
    const orderedList = useMemo(() => skipSort === true ? options : _.orderBy(options, orderRecord ? t => orderRecord[t.id] : t => t.identifier), [options, orderRecord, skipSort]);
    const strings = useLocalization();
    const [values, setValues] = useState<T[]>(selected); //selection
    const [filtered, setFiltered] = useState<IOption<T>[]>(orderedList);
    const [anchor, open, close] = useAnchorElement();
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        setValues(selected);
        setSearch('');
    }, [selected]);

    useEffect(() => {
        if (search !== undefined && search !== ''){
            const x = orderedList.filter(t => (t.identifier ?? '').toLowerCase().includes(search.toLowerCase()));
            setFiltered(x);
        } else {
            setFiltered(orderedList);
        }
        // TODO Validate this exclusion
        // eslint-disable-next-line
    }, [search, orderedList]);

    const handleClose = () => {
        if (values !== selected) {
            setSelected(values);
        }
        close();
    };

    const handleClickItem = (val: T) => {
        values.indexOf(val) > -1 ? setValues(values.filter(t => t !== val)) : setValues([...values, val]);
    }

    return (
        <>
            <FilterButton
                handleOpen={open}
                badgeContent={selected.length}
                label={label}
                icon={icon} />
            <FilterMenu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={handleClose}>
                <ListSubheader style={{ background: common.white }}>
                    {title && <>
                        <Typography align="center" style={{ padding: '8px 0px' }}>{title}</Typography>
                        <Divider />
                    </>}
                    <div className="df-row-ac" style={{ padding: '6px 6px 6px 9px' }}>
                        <SearchIcon />
                        <InputBase
                            placeholder={`${strings.searchVerb}...`}
                            style={{ width: '100%', paddingLeft: '9px' }}
                            value={search}
                            onKeyDown={e => e.stopPropagation()}
                            onChange={e => setSearch(e.target.value)} />
                        {search &&
                            <IconButton onClick={() => setSearch('')} size="small">
                                <ClearIcon />
                            </IconButton>}
                    </div>
                    <Divider />
                    <Checkbox
                        indeterminate={values.length > 0 && values.length < options.length}
                        checked={values.length === options.length}
                        disabled={filtered.length === 0}
                        onChange={() => {
                            values.length > 0 ? setValues([]) : setValues(filtered.map(t => t.id))
                        }}
                    />
                    {search ? strings.selectAllBelow : strings.selectAll}
                    <Divider />
                </ListSubheader>
                {filtered.map(option =>
                    <MenuItem key={option.identifier} value={(option.id as any).toString()} onClick={() => handleClickItem(option.id)}>
                        <Checkbox checked={values.indexOf(option.id) > -1} />
                        <ListItemText primary={option.identifier} />
                    </MenuItem>
                )}
            </FilterMenu>
        </>
    );
}

