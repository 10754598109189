
import { Grid, GridProps } from '@material-ui/core';
import React from 'react';

interface IProps extends GridProps {
    fullWidth?: boolean;
}

export const FormGridItem = ({ fullWidth, ...props}: IProps) => {
    return (
        <Grid item xs={12} sm={fullWidth ? 12 : 6} {...props} style={{ paddingTop: 0, paddingBottom: 0 }} />
    );
}