import { AppBar, Divider, Toolbar } from '@material-ui/core';
import { Searchbar } from 'framework/components/Searchbar';
import { LoaderContext } from 'framework/context/LoaderContext';
import React, { useContext } from 'react';
import { DialogsContext } from 'shared/dialogs/DialogsContext';
import { UserButtonAndMenu } from './UserButtonAndMenu';
import RefreshIcon from '@material-ui/icons/Refresh';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';

interface IProps {
    children: any;
    onSearch: (val: string) => void;
    searchString: string;
    placeholder: string;
    onRefresh?: VoidFunction;
}

export const PageWithSearch = ({ children, onSearch, searchString, placeholder, onRefresh }: IProps) => {
    const { stackCount } = useContext(DialogsContext);
    const { count: loadCount } = useContext(LoaderContext);

    return (
        <div className="fg1 df-col stretch-ver">
            <AppBar position="sticky" style={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid lightGray' }}>
                <Toolbar style={{ paddingLeft: 8 }}>
                    <Searchbar
                        variant="embedded"
                        onSearch={onSearch}
                        value={searchString}
                        placeholder={placeholder}
                        style={{ width: '100%' }} />
                    {process.env.NODE_ENV === `development` ? <div>Stack count: {stackCount}</div> : <div></div>}
                    {process.env.NODE_ENV === `development` ? <div>Load count: {loadCount}</div> : <div></div>}
                    <div style={{ height: 75 }} />
                    {onRefresh &&
                        <>
                            <Divider orientation='vertical' />
                            <TooltipWithSpan title="Herlaad">
                                <ColorOnHoverIconButton onClick={onRefresh} style={{ marginLeft: 20, marginRight: 20 }}>
                                    <RefreshIcon />
                                </ColorOnHoverIconButton>
                            </TooltipWithSpan>
                        </>
                    }
                    <Divider orientation='vertical' />
                    <UserButtonAndMenu style={{ paddingLeft: 50, paddingRight: 50 }} />
                </Toolbar>
            </AppBar>
            <div className='fg1' style={{ padding: 20 }}>
                {children}
            </div>
        </div>
    );
}