import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { ITypeDashboardStat, ReservationStatus } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReservationsRoute } from 'routes';
import { PageWithoutSearch } from '../PageWithoutSearch';
import { ReservationsQueryContext } from '../reservations/context/ReservationsQueryContext';
import { ReservationsViewContext } from '../reservations/context/ReservationsViewContext';
import { CacheContext } from './context/CacheContext';
import { DashboardStat } from './DashboardStat';

const FixedReservationStatuses: ReservationStatus[] = ['CanBePrepared', 'InWaitingLine', 'WaitingOtherReservation', 'InProgress'];

export const Dashboard = () => {
	const [data, reload] = useCacheContext(CacheContext);
	const { setParams } = useContext(ReservationsQueryContext);
	const { setQuickFilter } = useContext(ReservationsViewContext);
	const { push } = useHistory();

	if (data === undefined) {
		return <div></div>;
	}

	const onClickStat = (stat: ITypeDashboardStat) => {
		console.log('STATS', stat);
		setParams(pars => ({
			...pars,
			inventoryItemGroupTypes: [stat.type!],
			collectionDateAfter: stat.collectionDateAfter!,
			collectionDateBefore: stat.collectionDateBefore!,
			statuses: FixedReservationStatuses,
		}));
		setQuickFilter('all');
		push(ReservationsRoute);
	};

	return (
		<PageWithoutSearch onRefresh={reload}>
			<div className='df-col'>
				<div className='df-row-ac'>
					<DashboardStat
						description='wachtrij'
						value={data.countReservationsInWait}
					/>
					<DashboardStat
						description='kan worden klaargezet'
						value={data.countReservationsCanBePrepared}
					/>
				</div>
				<DividerWithCaption
					caption='Ski'
					captionStyle={{ fontWeight: 'bold', fontSize: 30 }}
				/>
				<div className='df-row-ac'>
					<DashboardStat
						description='In 3 dagen'
						value={data.statsSkis.in3Days.count}
						onClick={() => onClickStat(data.statsSkis.in3Days)}
					/>
					<DashboardStat
						description='In 7 dagen'
						value={data.statsSkis.in1Week.count}
						onClick={() => onClickStat(data.statsSkis.in1Week)}
					/>
					<DashboardStat
						description='In 2 weken'
						value={data.statsSkis.in2Weeks.count}
						onClick={() => onClickStat(data.statsSkis.in2Weeks)}
					/>
				</div>
				<DividerWithCaption
					caption='Snowboard'
					captionStyle={{ fontWeight: 'bold', fontSize: 30 }}
				/>
				<div className='df-row-ac'>
					<DashboardStat
						description='In 3 dagen'
						value={data.statsSnowboard.in3Days.count}
						onClick={() => onClickStat(data.statsSnowboard.in3Days)}
					/>
					<DashboardStat
						description='In 7 dagen'
						value={data.statsSnowboard.in1Week.count}
						onClick={() => onClickStat(data.statsSnowboard.in1Week)}
					/>
					<DashboardStat
						description='In 2 weken'
						value={data.statsSnowboard.in2Weeks.count}
						onClick={() => onClickStat(data.statsSnowboard.in2Weeks)}
					/>
				</div>
				{/* <div className="df-row-ac">
                <DashboardMetricTile description="aantal reservaties dit jaar" value={data.countReservationsThisYear} />
                <DashboardMetricTile description="omzet dit jaar" value={`€ ${data.revenuesThisYear}`} />
            </div> */}
			</div>
		</PageWithoutSearch>
	);
};
