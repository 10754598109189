import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { DateFilterItem } from 'framework/filter/date/DateFilterItem';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterItem } from 'framework/filter/RecordListFilterItem';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { IReservationsQueryParams, ReservationInventoryItemGroupType, ReservationInventoryItemGroupTypes, ReservationStatus, ReservationStatuses } from 'gen/ApiClient';
import React from 'react';
import { ReservationInventoryItemGroupTypesRecord } from 'shared/records/ReservationInventoryItemGroupTypesRecord';
import { ReservationStatusRecord } from 'shared/records/ReservationStatusRecord';

const exclusions: (keyof IReservationsQueryParams)[] = ['isAlsoShowCancelled', 'keyFilterStatus'];

interface IProps {
    params: IReservationsQueryParams;
    onFilter: (params: IReservationsQueryParams) => void;
    style?: React.CSSProperties;
}

export function Filter({ params, onFilter, style }: IProps) {
    return (
        <FilterBar
            onClear={() => onFilter(clearParams(params, exclusions))}
            isFiltered={isFiltered(params, exclusions)}
            style={style}>
            <div className="df-row-ac df-col-if-mobile">
                {!params.keyFilterStatus &&
                    <RecordListFilterItem<ReservationStatus>
                        icon={<HelpOutlineIcon />}
                        label="Status"
                        options={ReservationStatuses}
                        selected={params.statuses as any}
                        record={ReservationStatusRecord}
                        setSelected={val => onFilter({ ...params, statuses: val })} />
                }
                <DateFilterItem
                    label="Datum afhaling"
                    after={params.collectionDateAfter}
                    before={params.collectionDateBefore}
                    setSelected={(after, before) => onFilter({ ...params, collectionDateAfter: after, collectionDateBefore: before })} />
                    <RecordListFilterItem<ReservationInventoryItemGroupType>
                        icon={<HelpOutlineIcon />}
                        label="Type"
                        options={ReservationInventoryItemGroupTypes}
                        selected={params.inventoryItemGroupTypes as any}
                        record={ReservationInventoryItemGroupTypesRecord}
                        setSelected={val => onFilter({ ...params, inventoryItemGroupTypes: val })} />
            </div>
        </FilterBar>
    );
}